import XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import b from 'b_';
import React, { useState } from 'react';
// noinspection ES6CheckImport
import { Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import message from 'spotii-ui/Message';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../../spotii-ui/Form';
import FormRow from '../../spotii-ui/Form/FormRow';
import InputFile from '../../spotii-ui/Input/InputFile';
import TableView from '../../components/TableView';
import { EMPLOYEE_COLUMNS } from '../../utils/constants';
import DashboardHeader from '../../components/DashboardHeader';
import Paper from '../../components/Paper';
import Button from '../../spotii-ui/Button';
import formSerializer from '../../components/CreateEmployeeForm/form/serializer';
import { createMerchantEmployee } from '../../api';
import { fetchRoles } from '../../reducers/roles';
import { itRoles } from "../../components/EmployeeList/EmployeeList";

const handleFile = file => {
  return new Promise(resolve => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      resolve(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      // console.log(data);
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  });
};

const AddEmployees = ({ appState }) => {
  const { t } = useTranslation();
  const cls = b.with('add-bank-account');

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { merchantId } = appState.currentUser.merchant;
  const fetchParamsRoles = {
    merchantId,
  };

  React.useEffect(() => {
    dispatch(fetchRoles(fetchParamsRoles));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const roleList = useSelector(state => state.roles).list || [];
  const { permissions: currentUserPermissions = [], display_name } = appState['loggedEmployee']['role'] || {};
  // Filter to not include admin role if currentUser not admin
  // The primary merchant account has role null; hence allow
  const currentUserIsAdmin =
    !currentUserPermissions.length || currentUserPermissions.includes('admin_read');
  const currentUserIsItRole = itRoles.includes(display_name);
  const [toAdd, setToAdd] = useState([]);
  if (success) {
    return <Redirect to="/admin/employees" push />;
  }
  const prepareData = data => {
    if (!data || !data.length) {
      return message.error(t('uploadErrorBulkEmployees'));
    }
    const firstCol = data[0] && data[0][0];
    // Ignore header row
    if (firstCol.includes('name') || firstCol.includes('Name')) {
      data.shift();
    }
    if (data.some(row => row.length !== 6)) {
      return message.error(t('uploadErrorBulkEmployeesColCount'));
    }
    try {
      const matchRole = role =>
        roleList.find(({ displayName }) => displayName.toLowerCase() === role.toLowerCase());
      // eslint-disable-next-line camelcase
      const dataWithRoleId = data.map(([first_name, last_name, email, password, role, address]) => {
        const { roleId, displayName, permissions } = matchRole(role);
        if (!roleId) {
          throw new Error(t('unknownRole'));
        }
        if ((currentUserIsItRole || !currentUserIsAdmin) && permissions.includes('admin_read')) {
          throw new Error(t('adminCanAddAdmin'));
        }
        // eslint-disable-next-line camelcase
        email = email.toString().toLowerCase();
        return {
          first_name,
          last_name,
          email,
          role: displayName,
          password,
          role_id: roleId,
          address
        };
      });
      setToAdd(dataWithRoleId);
    } catch (e) {
      console.warn(e);
      message.error(e.message);
    }
  };

  const columns = EMPLOYEE_COLUMNS.slice().filter(({ key }) => key !== 'createdAt');
  columns.push({
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    render: value => <span>{value}</span>,
  });
  columns.push({
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    render: value => <span>{value}</span>,
  });
  const uploadData = async () => {
    setLoading(true);
    const checkoutData = toAdd.map(record => {
      // eslint-disable-next-line camelcase
      let { email, first_name, last_name, role_id, password, address } = formSerializer(record);
      email = email.toString().toLowerCase();
      return {
        user: {
          email,
          password,
          address,
          first_name,
          last_name,
          role_id,
        },
        merchant_id: merchantId,
      };
    });

    try {
      await createMerchantEmployee(checkoutData);
      message.success(t('employeeSuccessfullyAdded'));
      setLoading(false);
      setSuccess(true);
    } catch (e) {
      console.warn(e);
      message.error(
        <span>
          {`${t('formErrorTryAgainContact')} `}
          <a href="mailto:">hello@spendwisor.app</a>
        </span>,
      );
      setLoading(false);
    }
  };
  return (
    <>
      <DashboardHeader title="Add employees" appState={appState} />
      <Paper>
        <Row className={cls()}>
          <Col md="12">
            <Form>
              <FormRow>
                <InputFile
                  icon="credit-card"
                  label={t('uploadBulkEmployees')}
                  onSuccess={async file => {
                    const xlsData = await handleFile(file);
                    prepareData(xlsData);
                  }}
                  onError={() => {
                    message.error(t('uploadErrorBulkEmployees'));
                  }}
                />
              </FormRow>
            </Form>
          </Col>
        </Row>
        {toAdd.length > 0 && (
          <>
            <strong className={cls('section-label')}>{t('empToBeAdded')}</strong>
            <Row>
              <Col md={12}>
                <TableView columns={columns} data={toAdd} rowKey="email" />
              </Col>
            </Row>
            <Button
              htmlType="submit"
              disabled={loading}
              loading={loading}
              type="primary"
              onClick={() => uploadData(toAdd)}
            >
              {t('saveChanges')}
            </Button>
          </>
        )}
      </Paper>
    </>
  );
};
AddEmployees.propTypes = {
  appState: PropTypes.objectOf(PropTypes.object).isRequired,
};
export default AddEmployees;
