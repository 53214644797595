import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Paper from 'auth/Paper';
import BlockHead from 'auth/BlockHead';
import Heading from 'spotii-ui/Heading';
import Button from 'spotii-ui/Button';
import { LottieSuccess } from 'spotii-ui/Lottie';
import { useTranslation } from 'react-i18next';
import { clearRecovery } from 'reducers/recovery';

export default function RecoveryDone() {
  const dispatch = useDispatch();
  const [paused, setPaused] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    return () => dispatch(clearRecovery());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Start plaing animation after a while
  useEffect(() => {
    const timerAnimation = setTimeout(() => {
      setPaused(false);
    }, 300);
    return () => {
      clearTimeout(timerAnimation);
    };
  });

  return (
    <div className="page-transition">
      <Paper size="tiny">
        <Row>
          <Col>
            <LottieSuccess width={240} height={240} isStopped={paused} />
            <BlockHead type="under">
              <Heading h1>{t('allDone')}</Heading>
              <p>{t('passwordChanged')}</p>
              <p>
                <Button to="/signin" block size="large" type="primary">
                  {t('signIn')}
                </Button>
              </p>
            </BlockHead>
          </Col>
        </Row>
      </Paper>
    </div>
  );
}
