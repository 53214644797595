import React, { useState } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import axios from 'axios';
import authApi from 'api/auth';
import hash from 'utils/hash';
import Icon from '../Icon';
import message from '../Message';
import './InputFile.scss';

const IMAGES_TYPES = ['image/png', 'image/jpeg', 'image/gif'];

/**
 * Check if the file has acceptable file type
 * @param {file} file
 * @param {number} types
 */
const checkMimeType = (file, types) => {
  const typesList = types === 'image' ? IMAGES_TYPES : types;
  if (Array.isArray(typesList)) {
    return typesList.some(type => file.type === type);
  }
  // By default accepting all types
  return true;
};

/**
 * Check if the file doesn't exceed max acceptable size
 * @param {file} file
 * @param {number} size
 */
const checkFileSize = (file, size) => {
  if (size && file.size > size) {
    return false;
  }
  return true;
};

/**
 * Prepare data to be sent on destination URL
 * @param {file} file
 * @param {object} params
 */
const prepareFormData = (file, params) => {
  const formData = new FormData();

  if (params) {
    Object.keys(params).forEach(key => {
      formData.append(key, params[key]);
    });
  }
  formData.append('file', file, file.name);
  return formData;
};

/**
 * Build a request settings with Bearer Authorization token
 * @param {string} token
 */
const getSettingsWithBearer = token => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

//
// Component
//
const InputFile = ({
  name,
  icon,
  label,
  value,
  disabled,
  statusLine,
  fileTypes,
  fileMaxSize,
  destination,
  onSuccess,
  onError,
  params,
  styleType,
}) => {
  const cls = b.with('input-file');
  const idProp = `field_${name}_${hash()}`;
  const [status, setStatus] = useState(value ? 'done' : 'upload');
  const [dragOver, setDragOver] = useState(false);
  const isDisabled = disabled || status === 'loading';

  const upload = async file => {
    const formData = prepareFormData(file, params);

    setStatus(status === 'loading' ? 'done' : 'loading');

    try {
      const token = await authApi.getToken();
      await axios.post(destination, formData, getSettingsWithBearer(token));
      setStatus('done');
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      console.warn(e);
      setStatus('error');
      if (onError) {
        onError();
      }
    }
  };

  const onFileAdd = async selectedFile => {
    const decline = msg => {
      console.warn(msg);
      setStatus('error');
      if (onError) {
        onError(msg);
      }
    };

    if (!checkMimeType(selectedFile, fileTypes)) {
      message.error('Not supported file format.');
      decline(`${selectedFile.type} is not a supported format.`);
      return;
    }

    if (!checkFileSize(selectedFile, fileMaxSize)) {
      message.error('This file is too large.');
      decline(`${selectedFile.size} is too large.`);
      return;
    }
    if (destination) {
      upload(selectedFile);
    } else {
      onSuccess(selectedFile);
    }
  };

  const handleChange = async event => {
    const { target } = event;
    const selectedFile = target.files[0];
    if (selectedFile) {
      onFileAdd(selectedFile);
    }
  };

  const onDrop = event => {
    event.preventDefault();
    if (isDisabled) {
      return;
    }
    setDragOver(false);
    const { files } = event.dataTransfer;
    const selectedFile = files && files[0];
    if (selectedFile) {
      onFileAdd(selectedFile);
    }
  };

  return (
    <div
      className={cls({ status, type: styleType, disabled: isDisabled, dragging: dragOver })}
      onDrop={onDrop}
      onDragOver={e => {
        e.preventDefault();
        if (isDisabled) {
          return;
        }
        setDragOver(true);
      }}
      onDragLeave={() => setDragOver(false)}
    >
      <input className={cls('input')} id={idProp} name={name} type="file" onChange={handleChange} />
      <label htmlFor={idProp} className={cls('box')}>
        {icon ? (
          <div className={cls('icon')}>
            <Icon type={icon} />
          </div>
        ) : null}
        <div className={cls('label')}>
          {label}
          {statusLine ? <span className={cls('status-line')}>{statusLine}</span> : null}
        </div>
        <div className={cls('button', { status })}>
          <span className={cls('button-done')}>
            <Icon type="check" />
          </span>
          <span className={cls('button-loading')}>
            <Icon type="loading" />
          </span>
          <span className={cls('button-error')}>
            <Icon type="alert-triangle" />
          </span>
          <span className={cls('button-upload')}>Upload</span>
        </div>
      </label>
    </div>
  );
};

InputFile.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  statusLine: PropTypes.string,
  value: PropTypes.string,
  destination: PropTypes.string,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  params: PropTypes.shape({}),
  fileTypes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  fileMaxSize: PropTypes.number,
  styleType: PropTypes.string,
};

InputFile.defaultProps = {
  name: undefined,
  icon: undefined,
  value: undefined,
  statusLine: undefined,
  destination: undefined,
  disabled: undefined,
  onSuccess: undefined,
  onError: undefined,
  params: undefined,
  fileTypes: undefined,
  fileMaxSize: undefined,
  styleType: undefined,
};

export default InputFile;
