import React from 'react';
import { ReactComponent as Sprite } from './feather-sprite.svg';

const styles = {
  display: 'block',
  width: 0,
  height: 0,
  visibility: 'hidden',
};

const IconSprite = () => {
  return <Sprite style={styles} />;
};

export default IconSprite;
