/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getRole } from 'api';

const roleInitialState = {
  isLoading: false,
  isResolved: false,
  role: {},
};

const role = createSlice({
  name: 'role',
  initialState: roleInitialState,
  reducers: {
    fetchRoleStart(state) {
      state.isLoading = true;
    },
    fetchRoleSuccess(state, { payload }) {
      return {
        ...state,
        isLoading: false,
        isResolved: true,
        role: payload,
      };
    },
    fetchRoleFail(state) {
      state.isLoading = false;
      state.isResolved = true;
    },
    reset() {
      return roleInitialState;
    },
  },
});

export default role.reducer;

export const { fetchRoleStart, fetchRoleSuccess, fetchRoleFail, reset } = role.actions;

export const fetchRole = roleId => async (dispatch, state) => {
  if (state.isLoading) {
    return;
  }
  dispatch(fetchRoleStart());

  try {
    const response = await getRole(roleId);
    dispatch(
      fetchRoleSuccess({
        ...response.data,
      }),
    );
  } catch (e) {
    dispatch(fetchRoleFail(e));
  }
};
