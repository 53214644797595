import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Form, { FormRow, InputRow } from 'spotii-ui/Form';
import Button from 'spotii-ui/Button';
import authApi from 'api/auth';

const RecoveryNewPasswordForm = () => {
  const { t } = useTranslation();
  const processFormErrors = e => {
    const { errors } = e;
    const formErrors = {};

    if (typeof errors === 'object') {
      if (errors.new_password) {
        formErrors.password = errors.new_password[0];
        // Overwrite the known message
        if (formErrors.password === 'This value does not match the required pattern.') {
          formErrors.password = t('passwordShouldHave');
        }
      }
      if (errors.confirm_new_password) {
        formErrors.confirmPassword = t('passwordsShouldBeSame');
      }
      if (errors.non_field_errors && errors.non_field_errors.length) {
        errors.non_field_errors.forEach(err => {
          if (err === 'new_password not equal to confirm_new_password') {
            formErrors.confirmPassword = t('passwordsShouldBeSame');
          }
        });
      }
    } else {
      console.warn('Something went wrong during changing of the password.');
    }

    return formErrors;
  };

  return (
    <Formik
      initialValues={{ password: '', confirmPassword: '' }}
      onSubmit={async (values, actions) => {
        const { password, confirmPassword } = values;
        try {
          // Send a request for password recovery
          await authApi.recoveryNewPassword(password, confirmPassword);
          actions.setStatus('success');
        } catch (err) {
          actions.setErrors(processFormErrors(err));
          console.warn(err);
        }
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .min(8, t('passwordMinEight'))
          .required(t('passwordRequired')),
        confirmPassword: Yup.string()
          .min(8, t('passwordsShouldBeSame'))
          .required(t('passwordConfirmationRequired')),
      })}
    >
      {attrs => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          status,
          submitCount,
        } = attrs;

        if (status === 'success') {
          return <Redirect to="/password-recovery/done" />;
        }

        return (
          <Form onSubmit={handleSubmit}>
            <InputRow
              label={t('newPassword')}
              name="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={submitCount && errors.password && touched.password ? errors.password : null}
            />
            <InputRow
              label={t('confirmNewPassword')}
              name="confirmPassword"
              type="password"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                submitCount && errors.confirmPassword && touched.confirmPassword
                  ? errors.confirmPassword
                  : null
              }
            />
            <FormRow type="submit">
              <Button
                htmlType="submit"
                size="large"
                type="primary"
                block
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {t('setNewPassword')}
              </Button>
            </FormRow>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RecoveryNewPasswordForm;
