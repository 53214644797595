import snakeToCamel from 'utils/snakeToCamel';

function getNestedValue(obj, key) {
  return key.split('.').reduce((o, i) => (o ? o[i] : undefined), obj);
}

const fieldsTransformer = source => (res, field) => {
  let splittedField = field.split('.');
  if(splittedField.length > 1){
    return {
      ...res,
      [snakeToCamel(splittedField[splittedField.length - 1])]: getNestedValue(source,field)
    }
  }
  return {
  ...res,
    [snakeToCamel(field)]: source[field],
  }

};

export const orderSerialzier = data => {
  const fields = [
    'status',
    'reference',
    'order_number',
    'display_reference',
    'total',
    'currency',
    'tax_amount',
    'shipping_amount',
    'total_refund',
    'discount',
    'employee_name',
    'created_at',
    'plan_id',
    'user_fee',
    'order_id',
    'employee',
    'merchant_name'
  ];
  const result = fields.reduce(fieldsTransformer(data), {});

  const customerFields = ['first_name', 'last_name', 'email', 'phone'];
  result.customer =
    (data.customer && customerFields.reduce(fieldsTransformer(data.customer), {})) || {};
  result.id = data.order_id;

  const employeeFields = ['employee_id', 'address'];
  result.employee =
    (data.employee && employeeFields.reduce(fieldsTransformer(data.employee), {})) || {};
  const installmentFields = ['total', 'effective_at' , 'status', 'currency'];
  result.installments =
    (data.installments && data.installments.installments.map(installment => installmentFields.reduce(fieldsTransformer(installment), {})))  || [];


  const consumerInfoFields = ['first_name', 'last_name', 'email', 'phone'];
  result.consumerInfo =
    (data.consumer_info && consumerInfoFields.reduce(fieldsTransformer(data.consumer_info), {})) ||
    {};

  return result;
};

export const ordersListSerializer = data => {
  // const dataArray = Array.from(data);
  return data.map(orderSerialzier);
};

export default ordersListSerializer;
