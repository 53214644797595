import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import Icon from 'spotii-ui/Icon';
import ActionTile from 'spotii-ui/ActionTile';
import Currency from 'spotii-ui/Currency';
import Alert from 'spotii-ui/Alert';
import DashboardHeader from 'components/DashboardHeader';
import QuickStat from 'components/QuickStat';
import { AppStateType } from 'reducers/application';
import { fetchQuickStats } from 'reducers/statistics';

import './Home.scss';
import HeroStat from 'components/HeroStat/HeroStat';

const Home = ({ appState }) => {
  const { t } = useTranslation();
  const { currentUser } = appState;
  const dispatch = useDispatch();
  const { quickStats, isResolved } = useSelector(state => state.statistics);
  const { today, customers, sales } = quickStats;

  useEffect(() => {
    dispatch(fetchQuickStats());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="home__container">
      <DashboardHeader title={`${t('welcome')}, ${currentUser.firstName}!`} appState={appState} />
      {currentUser.merchant.isApproved ? null : (
        <Alert type="info" icon="alert-triangle">
          {t('pendingApprovalAlert')}
        </Alert>
      )}
      {isResolved ? (
        <>
          <Row className="home__grid">
            <Col xs={12} md={8}>
              <HeroStat
                className="min-height-100"
                title={t('yourSalesToday')}
                data={<Currency currency={today.currency} amount={today.total} />}
                description={`${t('from')} ${today.count} ${t('orders')}`}
              />
            </Col>
            <Col xs={12} md={4}>
              <Row className="home__quick-stats">
                <Col xs="12">
                  <QuickStat title={t('yourCustomers')} subtitle={t('payingThroughSpotii')}>
                    {customers.count}
                  </QuickStat>
                </Col>
                <Col xs="12">
                  <QuickStat title={t('totalSales')} subtitle={t('yourSalesWithSpotii')}>
                    <Currency currency={sales.currency || 'USD'} amount={sales.total} />
                  </QuickStat>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="home__grid">
            <Col xs={6} sm={6} md={3}>
              <ActionTile
                to="/orders"
                icon="shopping-bag"
                title={t('orders')}
                description={t('findCustomerOrderDesc')}
              />
            </Col>
            <Col xs={6} sm={6} md={3}>
              <ActionTile
                to="/reports/refunds"
                icon="rotate-ccw"
                title={t('refunds')}
                description={t('processRefundsDesc')}
              />
            </Col>
            <Col xs={6} sm={6} md={3}>
              <ActionTile
                to="/reports"
                icon="file-text"
                title={t('reports')}
                description={t('downloadReportsDesc')}
              />
            </Col>
            <Col xs={6} sm={6} md={3}>
              <ActionTile
                to="/settings"
                icon="settings"
                title={t('settings')}
                description={t('manageSettingsDesc')}
              />
            </Col>
          </Row>
        </>
      ) : (
        <div className="text_align_center">
          <Icon type="loading" />
        </div>
      )}
    </div>
  );
};

Home.propTypes = {
  appState: PropTypes.shape(AppStateType).isRequired,
};

export default Home;
