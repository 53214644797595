import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import Heading from 'spotii-ui/Heading';
import Paper from 'auth/Paper';
import BlockHead from 'auth/BlockHead';
import OtpForm from 'spotii-ui/OtpForm';
import { finalizeRegistration } from 'reducers/registration';
import { useTranslation, Trans } from 'react-i18next';
import authApi from 'api/auth';

const Verify = () => {
  const dispatch = useDispatch();
  const { email, isFinalized, isPopulated } = useSelector(state => state.registration);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const onSubmit = value => {
    setLoading(true);
    authApi.registrationVerification(value).then(
      registrationToken => {
        dispatch(finalizeRegistration(registrationToken))
          .then(async () => {
            setLoading(false);
          })
          .catch(err => {
            console.log(err);
            setLoading(false);
          });
      },
      err => {
        console.warn(err);
        setLoading(false);
        setError(true);
      },
    );
  };

  if (isFinalized) {
    return <Redirect to="/signup/welcome" />;
  }

  if (!isPopulated) {
    return <Redirect to="/signup" />;
  }

  return (
    <div className="page-transition">
      <Paper size="tiny" className={error ? 'shake' : null}>
        <BlockHead>
          <Heading h1>{t('weJustMailed')}</Heading>
          <p>
            <Trans i18nKey="otpSentSubTitle">
              {t('pleaseEnterVerificationCodeSentToMail')} <span className="nobr">{email}</span>
            </Trans>
          </p>
        </BlockHead>
        <OtpForm onSubmit={onSubmit} loading={loading} error={error} />
        <Link to="/signup">
          <div className="underline-link-text">{t('changeEmail')}</div>
        </Link>
      </Paper>
    </div>
  );
};

export default Verify;
