import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Heading from 'spotii-ui/Heading';
import Paper from 'auth/Paper';
import BlockHead from 'auth/BlockHead';
import { PersonalForm } from 'auth/Registration';

export default function Profile() {
  const { isPopulated } = useSelector(state => state.registration);

  if (!isPopulated) {
    return <Redirect to="/signup" />;
  }

  return (
    <div className="page-transition">
      <Paper size="small">
        <BlockHead>
          <Heading h1>Your personal detail</Heading>
          <p>Please ensure these details are accurate as we’ll use these to contact your</p>
        </BlockHead>
        <PersonalForm />
      </Paper>
    </div>
  );
}
