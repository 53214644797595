import snakeToCamel from 'utils/snakeToCamel';

const fieldsTransformer = source => (res, field) => ({
  ...res,
  [snakeToCamel(field)]: source[field],
});

export const checkoutSerializer = data => {
  const fields = [
    'reference',
    'display_reference',
    'description',
    'total',
    'currency',
    'order',
    'rejections',
    // 'tax_amount',
    // 'shipping_amount',
    // 'discount',
    'created_at',
    'status',
    'source',
    'checkout_url',
    'is_personal_loan',
  ];
  const result = fields.reduce(fieldsTransformer(data), {});
  // const customerFields = ['email'];
  // result.customer = customerFields.reduce(fieldsTransformer(data.customer), {});
  // console.log('Customer: ', result.customer);
  result.id = data.id;
  return result;
};

export const CheckoutsListSerializer = data => {
  // const dataArray = Array.from(data);
  return data.map(checkoutSerializer);
};

export default CheckoutsListSerializer;
