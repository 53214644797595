import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import AppLoading from 'spotii-ui/AppLoading';
import Table from 'spotii-ui/Table';

import './TableView.scss';

const TableView = ({ columns, data, loading, rowKey }) => {
  const cls = b.with('table-view');

  if (!data && loading) {
    return (
      <div className={cls()}>
        <AppLoading />
      </div>
    );
  }
  return (
    <div className={cls()}>
      <Table className={cls('table')} columns={columns} data={data} rowKey={rowKey} />
      {loading ? (
        <div className={cls('loading')}>
          <AppLoading />
        </div>
      ) : null}
    </div>
  );
};

TableView.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  rowKey: PropTypes.string,
};

TableView.defaultProps = {
  data: null,
  loading: false,
  rowKey: 'id',
};

export default TableView;
