import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Select from 'react-select';

import './itemPicker.scss';
import { AVAILABLE_ITEMS } from 'utils/constants';

const ItemPicker = ({ onItemChange }) => {
  const { t } = useTranslation();

  return (
    <span className="itemPickerContainer">
      <Select
        styles={{
          control: (_, state) => ({
            width: '100%',
            margin: '-2px 0 0',
            background: state.isFocused ? '#E3E6E6' : '#F0F2F2',
            borderRadius: '8px',
            display: 'flex',
            height: '55px',
          }),
          placeholder: () => ({
            padding: '0 0.5rem',
            color: '#9096A3',
          }),
          singleValue: () => ({
            padding: '0 0.5rem',
          }),
          indicatorContainer: () => ({
            color: '#9096A3',
          }),
          menu: () => ({
            marginTop: '0.5rem',
            borderRadius: '8px',
          }),
          menuList: baseStyles => ({
            ...baseStyles,
            backgroundColor: '#F0F2F2',
          }),
        }}
        options={AVAILABLE_ITEMS}
        placeholder={t('selectItem')}
        onChange={onItemChange}
        controlShouldRenderValue
        isSearchable
        maxMenuHeight={150}
      />
    </span>
  );
};

ItemPicker.propTypes = {
  onItemChange: PropTypes.func.isRequired,
};

ItemPicker.defaultProps = {};

export default ItemPicker;
