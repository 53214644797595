import snakeToCamel from 'utils/snakeToCamel';

export const applicationSerializer = payload => {
  const fields = [
    'allow_no_call_back_urls',
    'automatic_settlement_email',
    'can_restrict_checkout_type',
    'currency',
    'display_rejections',
    'has_usermanagement',
    'is_instore',
    'is_instore_custom_reference_enabled',
    'is_instore_comments_enabled',
    'is_static_qr_order_otp_verification_enabled',
    'logged_employee',
    'order_min_amount',
    'plan_dictionary',
    'plan_rates',
    'settlement_emails',
    'employee_linked_qr',
    'can_download_invoice',
    'requires_cust_details_on_checkout',
    'is_merchant_barcode',
    'allow_partial_capture',
    'display_name',
    'show_payment_link_qr',
    'is_personal_loan_enabled',
    'link_bank_account',
    'is_user_fee_for_merchant',
  ];
  return fields.reduce(
    (result, key) => ({
      ...result,
      [snakeToCamel(key)]: payload[key],
    }),
    {},
  );
};

export default applicationSerializer;
