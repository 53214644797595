import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import b from 'b_';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Heading from 'spotii-ui/Heading';
import Button from 'spotii-ui/Button';
import Alert from 'spotii-ui/Alert';
import Paper from 'components/Paper';
import Section from 'components/Section';
import IntegrationOptionsList from 'components/IntegrationOptionsList';
import copyTextToClipboard from 'utils/copyToClipboard';
// Type
import { AppStateType } from 'reducers/application';

const Integrations = ({ appState }) => {
  const { t } = useTranslation();
  const currLang = useSelector(state => state.language.language);
  const { currentUser } = appState;
  const { publicKey, privateKey, isApproved, merchantId } = currentUser.merchant;
  const cls = b.with('settings');

  if (isApproved) {
    return (
      <Paper>
        <Row className={cls('section')}>
          <Col md={9}>
            <p className={cls(`paragraph-${currLang}`)}>{t('publicPrivateKeyDesc')}</p>
            <p className={cls(`paragraph-${currLang}`)}>
              {`${t('headOverTo')} `}
              <a href="https://docs.spendwisor.app/" target="_blank" rel="noreferrer noopener">
                docs.spendwisor.app
              </a>
              {` ${t('forFurtherIntegrationInstruction')}`}
            </p>
          </Col>
        </Row>
        <Row className={cls('section')}>
          <Col md="9">
            <strong className={cls('section-label')}>{t('publicKey')}</strong>
            <p className={cls(`paragraph-${currLang}`)}>
              <span className={cls('api-key')}>{publicKey}</span>
              <Button
                className={cls(`copy-to-clip-${currLang}`)}
                size="small"
                icon="copy"
                shape="square"
                onClick={() => copyTextToClipboard('Public Key', publicKey)}
              />
            </p>

            <strong className={cls('section-label')}>{t('privateKey')}</strong>
            <p className={cls(`paragraph-${currLang}`)}>
              <span className={cls('api-key')}>{privateKey}</span>
              <Button
                className={cls(`copy-to-clip-${currLang}`)}
                size="small"
                icon="copy"
                shape="square"
                onClick={() => copyTextToClipboard('Private Key', privateKey)}
              />
            </p>

            <strong className={cls('section-label')}>{t('merchantID')}</strong>
            <p className={cls(`paragraph-${currLang}`)}>
              <span className={cls('api-key')}>{merchantId}</span>
              <Button
                className={cls(`copy-to-clip-${currLang}`)}
                size="small"
                icon="copy"
                shape="square"
                onClick={() => copyTextToClipboard('Merchant ID', merchantId)}
              />
            </p>

            <Section>
              <Heading h3>
                <p className={cls(`paragraph-${currLang}`)}>{t('connectWith')}</p>
              </Heading>
              <IntegrationOptionsList />
            </Section>
          </Col>
        </Row>
      </Paper>
    );
  }

  return (
    <Paper>
      <Row className={cls('section')}>
        <Col md={9}>
          <Alert type="info" icon="alert-triangle">
            {t('applicationPendingApproval')}
          </Alert>
          <strong className={cls('section-label')}>{t('merchantID')}</strong>
          <p className={cls(`paragraph-${currLang}`)}>
            <span className={cls('api-key')}>{merchantId}</span>
            <Button
              className={cls(`copy-to-clip-${currLang}`)}
              size="small"
              icon="copy"
              shape="square"
              onClick={() => copyTextToClipboard('Merchant ID', merchantId)}
            />
          </p>
          <p>{t('apiKeysAvailabilityDesc')}</p>
          <p>
            {`${t('meanWhileCheckDocAt')} `}
            <a href="https://docs.spendwisor.app/" target="_black" rel="noreferrer nofollow">
              docs.spendwisor.app
            </a>
            {` ${t('toLearnMoreApi')}`}
          </p>
        </Col>
      </Row>
    </Paper>
  );
};

Integrations.propTypes = {
  appState: PropTypes.shape(AppStateType).isRequired,
};

export default Integrations;
