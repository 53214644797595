import React from 'react';
import PropTypes from 'prop-types';
import './Heading.scss';

function getHeadingLevel(props) {
  const levelProps = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

  for (let i = 0; i < levelProps.length; i += 1) {
    const level = levelProps[i];
    if (props[level]) {
      return level;
    }
  }

  return levelProps[0];
}

const Heading = ({ className, children, ...rest }) => {
  const level = getHeadingLevel(rest);
  const cls = className ? `heading ${level} ${className}` : `heading ${level}`;

  return React.createElement(level, { className: cls }, children);
};

Heading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  h1: PropTypes.bool,
  h2: PropTypes.bool,
  h3: PropTypes.bool,
  h4: PropTypes.bool,
  h5: PropTypes.bool,
  h6: PropTypes.bool,
};

Heading.defaultProps = {
  className: undefined,
  children: undefined,
  h1: false,
  h2: false,
  h3: false,
  h4: false,
  h5: false,
  h6: false,
};

export default Heading;
