import React, { useEffect, useState } from 'react';
import Heading from 'spotii-ui/Heading';
import Button from 'spotii-ui/Button';
import { LottieSuccess } from 'spotii-ui/Lottie';
import Layout from 'auth/Layout';
import TopBar from 'auth/TopBar';
import Paper from 'auth/Paper';
import { useTranslation } from 'react-i18next';
import BlockHead from 'auth/BlockHead';
import { freshwork } from 'api';
import {
  FRESH_WORK_API_METHODS,
  FRESH_WORK_API_ENDPOINTS,
  FRESH_WORK_USER_ONBOARDING_STATUS,
  FRESH_WORK_USER_TYPES,
} from 'utils/constants';
import { useSelector } from 'react-redux';

export default function OrderApproved() {
  const [paused, setPaused] = useState(true);
  const { t } = useTranslation();
  // Start plaing animation after a while
  useEffect(() => {
    const timerAnimation = setTimeout(() => {
      setPaused(false);
    }, 300);
    return () => {
      clearTimeout(timerAnimation);
    };
  });

  const { currentUser, isFlagMerchantFetched } = useSelector(state => state.application);
  const { isFinalized } = useSelector(state => state.registration);

  // async function addMerchantToFreshworks() {
  //   const account = await freshwork({
  //     endpoint: `${FRESH_WORK_API_ENDPOINTS.SALES_ACCOUNTS}`,
  //     method: FRESH_WORK_API_METHODS.POST,
  //     sales_account: {
  //       external_id: currentUser?.merchant?.merchantId,
  //       name: currentUser?.fullName,
  //       phone: currentUser?.phoneNumber,
  //     },
  //   });
  //   if (account && account.sales_account) {
  //     await freshwork({
  //       endpoint: FRESH_WORK_API_ENDPOINTS.CONTACTS,
  //       method: FRESH_WORK_API_METHODS.POST,
  //       contact: {
  //         email: currentUser?.email,
  //         phone: currentUser?.phoneNumber,
  //         external_id: currentUser?.merchant?.merchantId,
  //         sales_account_id: account.sales_account.id,
  //         custom_field: {
  //           cf_onboarding_status: FRESH_WORK_USER_ONBOARDING_STATUS.ACTIVE,
  //           cf_user_type: FRESH_WORK_USER_TYPES.MERCHANT,
  //         },
  //       },
  //     });
  //   }
  // }

  // useEffect(() => {
  //   if (isFinalized && currentUser?.merchant?.merchantId && isFlagMerchantFetched) {
  //     setTimeout(() => {
  //       addMerchantToFreshworks();
  //     }, 1000);
  //   }
  // }, [isFinalized, currentUser?.merchant?.merchantId, isFlagMerchantFetched]);

  return (
    <Layout>
      <TopBar />
      <Layout.Container>
        <Paper size="tiny">
          <LottieSuccess width={240} height={240} isStopped={paused} />
          <BlockHead type="under">
            <Heading h1>{t('allDone')}</Heading>
            <p>{t('welcomeToSpotii')}</p>
            <p>
              <Button href="/" size="large" type="primary" block>
                {t('getStarted')}
              </Button>
            </p>
          </BlockHead>
        </Paper>
      </Layout.Container>
    </Layout>
  );
}
