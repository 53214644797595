import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import './index.scss';

const RadioGroup = ({ children, alignment }) => {
  return <div className={b('radio-group', { alignment })}>{children}</div>;
};

RadioGroup.propTypes = {
  children: PropTypes.node.isRequired,
  alignment: PropTypes.oneOf(['horizontal', 'vertical']),
};

RadioGroup.defaultProps = {
  alignment: 'vertical',
};

export default RadioGroup;
