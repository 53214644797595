import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LocationType } from '../../utils/types';
import './OtpControl.scss';

const OtpControl = ({ toChangePhone }) => {
  const { t } = useTranslation();
  return (
    <div className="otp-control">
      <p>{t('changeEmailDesc')}</p>
      <p className="otp-contol__actions">
        {/* <span className="link">Resend code</span> */}
        {/* <br /> */}
        {toChangePhone ? <Link to={toChangePhone}>{t('changeEmail')}</Link> : null}
      </p>
    </div>
  );
};

OtpControl.propTypes = {
  toChangePhone: LocationType.isRequired,
};

export default OtpControl;
