import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import queryString from 'utils/queryString';
import { AppStateType } from 'reducers/application';

const RouteUnauthenticated = ({ appState, component: C, ...rest }) => {
  const { isAuthenticated } = appState;
  const redirect = queryString('continue') || '/';

  if (isAuthenticated) {
    return <Redirect to={redirect} />;
  }

  return <Route {...rest} render={props => <C {...props} appState={appState} />} />;
};

RouteUnauthenticated.propTypes = {
  appState: PropTypes.shape(AppStateType).isRequired,
  component: PropTypes.elementType.isRequired,
};

export default RouteUnauthenticated;
