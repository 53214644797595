import React from 'react';
import PropTypes from 'prop-types';
import './Section.scss';

const Section = ({ children, className }) => (
  <div className={className ? `section ${className}` : 'section'}>{children}</div>
);

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Section.defaultProps = {
  children: null,
  className: null,
};

export default Section;
