import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import './Checkbox.scss';

const Checkbox = ({ name, children, checked, onChange, ...inputProps }) => {
  const cls = b.with('checkbox');
  return (
    <label htmlFor={name} className={cls({ 'with-label': !!children })}>
      <span className={cls('box')}>
        <input
          id={name}
          name={name}
          type="checkbox"
          value
          checked={checked}
          onChange={onChange}
          {...inputProps}
        />
        <span className={cls('box-inner')} />
      </span>
      {children ? <span className={cls('label')}>{children}</span> : null}
    </label>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
};

Checkbox.defaultProps = {
  children: null,
};

export default Checkbox;
