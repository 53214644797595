/* eslint-disable import/prefer-default-export */
export const isAndroidWebView = () => {
  // const { standalone } = window.navigator;
  const userAgent = window.navigator.userAgent.toLowerCase();
  // const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);

  if (ios) {
    return false;
  }

  if (userAgent.includes('wv')) {
    return true;
  }
  return false;
};
