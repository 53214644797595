export const getBrowserLang = () => {
  if (
    navigator.language === 'ar' ||
    navigator.language === 'arDz' ||
    navigator.language === 'ar-Dz'
  ) {
    return 'ar';
  }

  if (
    navigator.language === 'en' ||
    navigator.language === 'en-US' ||
    navigator.language === 'enUS' ||
    navigator.language === 'en-GB' ||
    navigator.language === 'enGB'
  ) {
    return 'en';
  }

  return 'not-supported';
};

export const flipCommas = str => {
  if (!str) {
    return str;
  }

  return str.split(',').join('،');
};
