import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './Form.scss';

const FormError = ({ children }) => {
  const currLang = useSelector(state => state.language.language);
  return <div className={`form__error-${currLang}`}>{children}</div>;
};

FormError.propTypes = {
  children: PropTypes.node,
};

FormError.defaultProps = {
  children: null,
};

export default FormError;
