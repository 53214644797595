import PropTypes from 'prop-types';

export const TranslatedStringType = PropTypes.oneOfType([PropTypes.element, PropTypes.string]);

export const LocationType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
    key: PropTypes.string,
    state: PropTypes.any,
  }),
]);

export const OrderType = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  displayReference: PropTypes.string,
  total: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  taxAmount: PropTypes.string,
  shippingAmount: PropTypes.string,
  discount: PropTypes.string,
  createdAt: PropTypes.string,
  customer: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
};

export const BankAccountType = {
  bankAccountId: PropTypes.string.isRequired,
  bank: PropTypes.string.isRequired,
  accountNumber: PropTypes.string.isRequired,
  iban: PropTypes.string.isRequired,
  swift: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  isDefault: PropTypes.bool,
  approvedAt: PropTypes.string,
};
