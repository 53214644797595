import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import Heading from 'spotii-ui/Heading';
import Form, { FormRow, InputRow } from 'spotii-ui/Form';
import Button from 'spotii-ui/Button';
import OtpForm from 'spotii-ui/OtpForm';
import message from 'spotii-ui/Message';
import authApi from 'api/auth';

const ChangePassword = ({ onSuccess }) => {
  const { t } = useTranslation();
  const currLang = useSelector(state => state.language.language);
  const { currentUser } = useSelector(state => state.application);
  const [showOTP, setShowOTP] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [confirmationUrl, setConfirmationUrl] = useState();

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const processFormErrors = e => {
    const { errors } = e;
    const formErrors = {};

    if (Array.isArray(errors)) {
      formErrors.password = errors[0];
    } else if (typeof errors === 'object') {
      if (errors.new_password) {
        formErrors.password = errors.new_password[0];
        // Overwrite the known message
        if (formErrors.password === 'This value does not match the required pattern.') {
          formErrors.password = t('passwordShouldHave');
        }
      }
      if (errors.confirm_new_password) {
        formErrors.confirmPassword = t('passwordsShouldBeSame');
      }
      if (errors.non_field_errors === 'new_password not equal to confirm_new_password') {
        formErrors.confirmPassword = t('passwordsShouldBeSame');
      }
    } else {
      console.warn('Something went wrong during changing of the password.');
    }

    return formErrors;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(8, t('passwordMinEight'))
        .required(t('passwordRequired')),
      confirmPassword: Yup.string()
        .min(8, t('passwordMinEight'))
        .required(t('passwordRequired')),
    }),
    onSubmit: async ({ password, confirmPassword }, actions) => {
      try {
        const result = await authApi.requestPasswordChange(password, confirmPassword);
        setConfirmationUrl(result.url);
        setShowOTP(true);
      } catch (e) {
        actions.setErrors(processFormErrors(e));
        console.warn(e);
      }
    },
  });

  const handleCloseOTP = () => setShowOTP(false);
  const handleOtpSubmit = async otp => {
    setOtpLoading(true);
    setOtpError(false);
    try {
      await authApi.confirmPasswordChange(confirmationUrl, otp);
      setOtpLoading(false);
      setShowOTP(false);
      message.success(t('passwordChangeSuccess'));
      formik.resetForm();
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      console.warn(e);
      setOtpLoading(false);
      setOtpError(true);
    }
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    isSubmitting,
    handleChange,
    handleSubmit,
    dirty,
  } = formik;

  if (formik.status === 'error') {
    // Show generic error message
  }

  const getError = key => touched[key] && errors[key];

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <InputRow
          name="password"
          label={t('newPassword')}
          type="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getError('password')}
        />
        <InputRow
          name="confirmPassword"
          label={t('confirmNewPassword')}
          type="password"
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getError('confirmPassword')}
        />

        <FormRow type="submit">
          <Button
            htmlType="submit"
            type="primary"
            loading={isSubmitting}
            disabled={!dirty || isSubmitting}
          >
            {t('saveChanges')}
          </Button>
        </FormRow>
      </Form>

      <Modal show={showOTP} onHide={handleCloseOTP} centered className={otpError ? 'shake' : ''}>
        <Modal.Body>
          <div className="text_align_center">
            <Heading h2>{t('weJustTextedYou')}</Heading>
            <p className="color-black40 mb-40">
              {`${t('pleaseEnterVerificationCodeSentTo')} `}
              <span className="nobr">{currentUser.email}</span>
            </p>
          </div>
          <OtpForm onSubmit={handleOtpSubmit} loading={otpLoading} error={otpError} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

ChangePassword.propTypes = {
  onSuccess: PropTypes.func,
};

ChangePassword.defaultProps = {
  onSuccess: null,
};

export default ChangePassword;
