import React from 'react';
import PropTypes from 'prop-types';
import Paginate from 'react-paginate';
import Icon from 'spotii-ui/Icon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import './Pagination.scss';

const Pagination = ({ current, count, onChange }) => {
  const { t } = useTranslation();
  const currLang = useSelector(state => state.language.language);

  return (
    <div className="pagination">
      <Paginate
        forcePage={current}
        pageCount={count}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={onChange}
        nextLabel={
          <span>
            <span className="pagination__nav-label">{t('next')} </span>
            <Icon type={currLang === 'ar' ? 'chevron-left' : 'chevron-right'} />
          </span>
        }
        previousLabel={
          <span>
            <Icon type={currLang === 'ar' ? 'chevron-right' : 'chevron-left'} />
            <span className="pagination__nav-label">
              {' '}
              {t('previous')}
            </span>
          </span>
        }
      />
    </div>
  );
};

Pagination.propTypes = {
  current: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  onChange: PropTypes.func,
};

Pagination.defaultProps = {
  onChange: null,
};

export default Pagination;
