const normProp = prop => prop && prop.trim();

const fields = ['bank', 'iban', 'swift', 'country'];

const serializer = values => ({
  ...fields.reduce(
    (res, key) => ({
      ...res,
      [key]: normProp(values[key]),
    }),
    {},
  ),
  iban: normProp(values.iban && values.iban.replace(/\s/g, '')),
  account_number: normProp(values.accountNumber),
  account_title: normProp(values.accountTitle),
});

export default serializer;
