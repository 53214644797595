/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import RouteBinded from 'components/RouteBinded';
import { AppStateType, closeSidebar } from 'reducers/application';
import AppLoading from 'spotii-ui/AppLoading';

import Admin from 'pages/Admin';
import Home from 'pages/Home';
import Orders from 'pages/Orders';
import Reports from 'pages/Reports';
import Settings from 'pages/Settings';
import Checkout from 'pages/Checkout';

import MobileHeader from 'components/MobileHeader';
import Sidebar from 'components/Sidebar';
import navigation from './navigation';
import './index.scss';

const Dashboard = ({ appState }) => {
  const dispatch = useDispatch();
  const currLang = useSelector(state => state.language.language);
  const cls = b.with('dashboard');

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
  }, []);

  let navItems = navigation;
  if (!appState.allowNoCallBackUrls) {
    navItems = navigation.filter(el => {
      return el.pathname !== '/checkout';
    });
  }
  if (appState.loggedEmployee && appState.loggedEmployee.role) {
    const userPermissions = appState.loggedEmployee.role.permissions;

    navItems = navItems.filter(item => {
      return userPermissions.some(r => item.permName.indexOf(r) >= 0);
    });
  }
  if (!appState.hasUsermanagement) {
    navItems = navItems.filter(item => item.pathname !== '/admin');
  }
  if (!appState.loggedEmployee) {
    return <AppLoading />;
  }
  return (
    <>
      {screenWidth < 1200 ? <MobileHeader appState={appState} /> : <></>}
      <div className={cls()}>
        {/* <div className="dashboard__wrap"> */}
        <div className={cls(`sidebar-${currLang}`, { active: appState.isSidebarOpened })}>
          <Sidebar className={cls(`sidebar-stick-${currLang}`)} navItems={navItems} />
        </div>
        <div
          className={cls('sidebar-fade', { active: appState.isSidebarOpened })}
          onClick={() => dispatch(closeSidebar())}
        />
        <div className={cls('inner')}>
          <Switch>
            <RouteBinded path="/" exact component={Home} appState={appState} />
            {appState.hasUsermanagement && (
              <RouteBinded path="/admin" component={Admin} appState={appState} />
            )}
            <RouteBinded path="/orders/:status/:page?" component={Orders} appState={appState} />
            <Route exact path="/orders" render={() => <Redirect to="/orders/all" />} />
            <RouteBinded path="/reports" component={Reports} appState={appState} />
            <RouteBinded path="/settings" component={Settings} appState={appState} />
            <RouteBinded path="/checkout" component={Checkout} appState={appState} />
          </Switch>
        </div>
      </div>
    </>
  );
};

Dashboard.propTypes = {
  appState: PropTypes.shape(AppStateType).isRequired,
};

export default Dashboard;
