import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './Badge.scss';

const Badge = ({ className, type, size, children }) => (
  <span className={b('badge', { type, size }) + (className ? ` ${className}` : '')}>
    {children}
  </span>
);

Badge.propTypes = {
  type: PropTypes.oneOf(['default', 'success', 'fail', 'highlight']),
  size: PropTypes.oneOf(['default', 'large']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Badge.defaultProps = {
  className: null,
  type: 'default',
  size: 'default',
};

export default Badge;
