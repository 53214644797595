import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import animationData from './6004-check-success.json';

const LottieSuccess = ({ width, height, ...rest }) => {
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const margin = `-${height * 0.07}px 0 -${width * 0.07}px ${height * 0.07}px`;

  return (
    <div style={{ margin }}>
      <Lottie options={defaultOptions} height={height} width={width} {...rest} />
    </div>
  );
};

LottieSuccess.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default LottieSuccess;
