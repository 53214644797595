/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getShopifyConfig } from 'api';
import { shopifyConfigSerializer } from './serializers';

const shopifyConfigInitialState = {
  isLoading: false,
  isResolved: false,
  settings: {},
};

const shopifyConfig = createSlice({
  name: 'shopifyConfig',
  initialState: shopifyConfigInitialState,
  reducers: {
    fetchShopifyConfigStart(state) {
      state.isLoading = true;
    },
    fetchShopifyConfigSuccess(state, { payload }) {
      state.isLoading = false;
      state.isResolved = true;
      state.settings = shopifyConfigSerializer(payload);
    },
    fetchShopifyConfigFail(state) {
      state.isLoading = false;
      state.isResolved = true;
    },
    unresolvedShopifyConfig(state) {
      state.isResolved = false;
    },
  },
});

export default shopifyConfig.reducer;

export const {
  fetchShopifyConfigStart,
  fetchShopifyConfigSuccess,
  fetchShopifyConfigFail,
  unresolvedShopifyConfig,
} = shopifyConfig.actions;

export const fetchShopifyConfig = () => async (dispatch, state) => {
  if (state.isLoading) {
    return;
  }
  dispatch(fetchShopifyConfigStart());
  try {
    const result = await getShopifyConfig();
    dispatch(fetchShopifyConfigSuccess(result));
  } catch (e) {
    dispatch(fetchShopifyConfigFail(e));
  }
};
