import React from 'react';
import b from 'b_';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { SpotiiZipLogo } from 'spotii-ui/Logo';
import Button from 'spotii-ui/Button';
import { openSidebar } from 'reducers/application';

import './MobileHeader.scss';

const MobileHeader = () => {
  const dispatch = useDispatch();
  const cls = b.with('mobile-header');

  return (
    <div className={cls()}>
      <div className={cls('inner')}>
        <div className={cls('menu-button')}>
          <Button
            type="transparent"
            shape="square"
            icon="menu"
            size="large"
            className={cls('button')}
            onClick={() => dispatch(openSidebar())}
          />
        </div>
        <div className={cls('logo')}>
          <Link to="/" className={cls('logo-inner')}>
            <SpotiiZipLogo />
          </Link>
        </div>
        <div className={cls('actions')}>
          <Button
            to="/settings"
            type="transparent"
            shape="square"
            icon="user"
            size="large"
            className={cls('button')}
          />
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
