import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import b from 'b_';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Paper from 'components/Paper';
import Icon from 'spotii-ui/Icon';
import DashboardHeader from 'components/DashboardHeader';
import CreateEmployeeForm from 'components/CreateEmployeeForm';
import { useTranslation } from 'react-i18next';

const AddEmployee = ({ appState }) => {
  const { t } = useTranslation();
  const cls = b.with('add-bank-account');
  const currLang = useSelector(state => state.language.language);
  const [success, setSuccess] = useState(false);

  if (success) {
    return <Redirect to="/admin/employees" push />;
  }

  return (
    <>
      <DashboardHeader title="Add an employee" appState={appState} />
      <Paper>
        <Row className={cls()}>
          <Col md="7">
            <p style={{ marginBottom: '35px' }}>
              <Link to="/admin/employees">
                {currLang === 'ar' ? t('back') : ''}
                <Icon type="chevron-left" />
                {currLang !== 'ar' ? t('back') : ''}
              </Link>
              <Link to="/admin/addEmployees" style={{ float: 'right' }}>
                Add multiple
              </Link>
            </p>
            <CreateEmployeeForm
              appState={appState}
              onSuccess={() => {
                setSuccess(true);
              }}
            />
          </Col>
        </Row>
      </Paper>
    </>
  );
};
AddEmployee.propTypes = {
  appState: PropTypes.objectOf(PropTypes.object).isRequired,
};
export default AddEmployee;
