import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Heading from 'spotii-ui/Heading';
import Paper from 'auth/Paper';
import BlockHead from 'auth/BlockHead';
import Registration from 'auth/Registration';
import { AppStateType } from 'reducers/application';

import './CreateAccount.scss';

export default function CreateAccount({ appState }) {
  const { t } = useTranslation();
  const { isAuthenticated } = appState;

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div className="page-transition">
      <Paper size="medium" picture>
        <Row>
          <Col>
            <BlockHead>
              <Heading h1>{t('signUpAsMerchant')}</Heading>
              <p>{t('signUpAsMerchantSub')}</p>
            </BlockHead>
            <Registration />
          </Col>
          <Col className="create-account__picture d-none d-sm-block" />
        </Row>
      </Paper>
    </div>
  );
}

CreateAccount.propTypes = {
  appState: PropTypes.shape(AppStateType).isRequired,
};
