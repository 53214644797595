/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getOrderDraftsList } from 'api';

const draftsInitialState = {
  isLoading: false,
  isResolved: false,
  fromDate: null,
  toDate: null,
  search: null,
  pageCount: 0,
  pages: {},
  currentPage: null,
  list: [],
};

const orders = createSlice({
  name: 'orders',
  initialState: draftsInitialState,
  reducers: {
    fetchDraftsStart(state) {
      state.isLoading = true;
    },
    fetchDraftsSuccess(state, { payload }) {
      const { data } = payload;
      return {
        ...state,
        isLoading: false,
        isResolved: true,
        ...payload,
        // list: ordersListSerializer(data),
        list: data,
      };
    },
    fetchDraftsFail(state) {
      state.isLoading = false;
      state.isResolved = true;
    },
    changeDates(state, { payload }) {
      const { fromDate, toDate } = payload;
      return {
        ...state,
        fromDate,
        toDate,
      };
    },
    changePage(state, { payload }) {
      state.currentPage = payload;
    },
    reset() {
      return draftsInitialState;
    },
  },
});

export default orders.reducer;

export const {
  fetchDraftsStart,
  fetchDraftsSuccess,
  fetchDraftsFail,
  changeDates,
  changePage,
  reset,
} = orders.actions;

// { fromDate, toDate, search, limit, page, status }
export const fetchDrafts = params => async (dispatch, state) => {
  if (state.isLoading) {
    return;
  }
  dispatch(fetchDraftsStart());
  try {
    const response = await getOrderDraftsList(params);
    const { fromDate, toDate, search } = params;
    dispatch(
      fetchDraftsSuccess({
        fromDate,
        toDate,
        search,
        ...response,
      }),
    );
  } catch (e) {
    dispatch(fetchDraftsFail(e));
  }
};
