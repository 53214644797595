import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import b from 'b_';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'spotii-ui/Button';
import Badge from 'spotii-ui/Badge';
import Dropdown from 'spotii-ui/Dropdown';
import message from 'spotii-ui/Message';
import { BankAccountType } from 'utils/types';
import { setAsDefaultBankAccount } from 'reducers/bankAccounts';

import './BankAccountsListItem.scss';

const BankAccountsListItem = ({
  bankAccountId,
  bank,
  accountNumber,
  accountTitle,
  iban,
  swift,
  isDefault,
  approvedAt,
  actions,
}) => {
  const { t } = useTranslation();
  const currLang = useSelector(state => state.language.language);
  const cls = b.with('bank-accounts-list-item');
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const handleUseDefault = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(setAsDefaultBankAccount(bankAccountId));
      setLoading(false);
      message.success(t('defaultBankAccountChangeSuccess'));
    } catch (err) {
      setLoading(false);
      message.error(t('somethingWentWrong'));
    }
  };

  const renderAside = () => {
    if (!approvedAt) {
      return (
        <div className={cls('badge')}>
          <Badge>{t('approving')}</Badge>
        </div>
      );
    }
    if (isDefault) {
      return (
        <div className={cls('badge')}>
          <Badge type="success">{t('default')}</Badge>
        </div>
      );
    }
    if (actions) {
      return (
        <Dropdown alignRight className={cls('btn')}>
          <Dropdown.Toggle as={Dropdown.CustomToggle}>
            <Button
              type="transparent"
              shape="square"
              size="small"
              icon={isLoading ? 'loading' : 'ellipsis'}
              disabled={isLoading}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="1" as="button" onClick={handleUseDefault}>
              {t('useAsDefault')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    }
    return null;
  };

  return (
    <div className={cls()}>
      <div className={cls('content')}>
        <div className={cls(`account-${currLang}`)}>
          {accountTitle ? (
            <>
              {`${t('accountTitle')}: ${accountTitle}`}
              <br />
            </>
          ) : (
            <></>
          )}
          {`${t('bank')}: ${bank}`}
          <br />
          {`${t('accountNumber')}: ${accountNumber}`}
          {iban && <br />}
          {iban && `${t('iban')}: ${iban}`}
          <br />
          {`${t('swift')}: ${swift}`}
        </div>
        {/* {renderAside()} */}
      </div>
    </div>
  );
};

BankAccountsListItem.propTypes = {
  ...BankAccountType,
  actions: PropTypes.bool,
};

BankAccountsListItem.defaultProps = {
  actions: true,
};

export default BankAccountsListItem;
