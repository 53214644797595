/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getBankAccounts, setDefaultBankAccount, deleteBankAccount } from 'api';
import { bankAccountsListSerializer } from './serializers';

const bankAccountsInitialState = {
  isLoading: false,
  isResolved: false,
  list: [],
};

const bankAccounts = createSlice({
  name: 'bankAccounts',
  initialState: bankAccountsInitialState,
  reducers: {
    fetchBankAccountsStart(state) {
      state.isLoading = true;
    },
    fetchBankAccountsSuccess(state, { payload }) {
      state.isLoading = false;
      state.isResolved = true;
      state.list = bankAccountsListSerializer(payload);
    },
    fetchBankAccountsFail(state) {
      state.isLoading = false;
      state.isResolved = true;
    },
    unresolvedBankAccounts(state) {
      state.isResolved = false;
    },
  },
});

export default bankAccounts.reducer;

export const {
  fetchBankAccountsStart,
  fetchBankAccountsSuccess,
  fetchBankAccountsFail,
  unresolvedBankAccounts,
} = bankAccounts.actions;

export const fetchBankAccounts = () => async (dispatch, state) => {
  if (state.isLoading) {
    return;
  }
  dispatch(fetchBankAccountsStart());
  try {
    const result = await getBankAccounts();
    dispatch(fetchBankAccountsSuccess(result));
  } catch (e) {
    dispatch(fetchBankAccountsFail(e));
  }
};

export const setAsDefaultBankAccount = id => async dispatch => {
  try {
    await setDefaultBankAccount(id);
    dispatch(fetchBankAccounts());
  } catch (e) {
    console.warn(e);
  }
};

export const removeBankAccount = id => async dispatch => {
  try {
    await deleteBankAccount(id);
    dispatch(fetchBankAccounts());
  } catch (e) {
    console.warn(e);
  }
};
