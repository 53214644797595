import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import Paper from 'components/Paper';
import CheckoutList from 'components/CheckoutList';

const Checkouts = ({ appState }) => {
  const { status: CheckoutsType } = useParams();
  return (
    <>
      <Paper mobileFull>
        <CheckoutList CheckoutsType={CheckoutsType} appState={appState} />
      </Paper>
    </>
  );
};

Checkouts.propTypes = {
  appState: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default Checkouts;
