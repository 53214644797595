import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Heading from 'spotii-ui/Heading';
import ShopifyAppForm from 'components/ShopifyAppForm';
import Icon from 'spotii-ui/Icon';
import { fetchShopifyConfig, unresolvedShopifyConfig } from 'reducers/shopifyConfig';

const ShopifyConfig = () => {
  const { t } = useTranslation();
  const currLang = useSelector(state => state.language.language);
  const dispatch = useDispatch();
  const { isResolved, settings } = useSelector(state => state.shopifyConfig);

  useEffect(() => {
    dispatch(fetchShopifyConfig());
    return () => dispatch(unresolvedShopifyConfig());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="shopify-config">
      <div className="mb-40">
        <Heading h3>
          <p style={{ textAlign: `${currLang === 'ar' ? 'right' : 'left'}` }}>
            {t('privateAppCredentials')}
          </p>
        </Heading>
        <p style={{ textAlign: `${currLang === 'ar' ? 'right' : 'left'}` }} className="mb-32">
          {t('privateAppCredentialsDesc')}
        </p>
        {isResolved ? <ShopifyAppForm initialValues={settings} /> : <Icon type="loading" />}
      </div>
    </div>
  );
};

export default ShopifyConfig;
