import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import './Paper.scss';

const cls = b.with('paper');
const Paper = ({ children, className, mobileFull }) => (
  <div
    className={
      className
        ? `${cls({ 'mobile-full': mobileFull })} ${className}`
        : cls({ 'mobile-full': mobileFull })
    }
  >
    {children}
  </div>
);

Paper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  mobileFull: PropTypes.bool,
};

Paper.defaultProps = {
  className: null,
  mobileFull: false,
};

export default Paper;
