import b from 'b_';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import { fetchRole, reset } from 'reducers/role';
import RolePermissions from 'components/RolePermissions';
import Button from 'spotii-ui/Button';
import Form, { FormRow } from 'spotii-ui/Form';
import message from 'spotii-ui/Message';
import Input from 'spotii-ui/Input';
import { RESOURCES } from 'utils/constants';

import { updateMechantRole } from 'api';

import './UpdateRoleForm.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

const cls = b.with('settings');

const UpdateRoleForm = ({ appState, onSuccess }) => {
  const { t } = useTranslation();
  const { roleId } = useParams();
  const dispatch = useDispatch();
  const { isLoading, isResolved, role } = useSelector(state => state.role);

  const [displayName, setDisplayName] = React.useState(role.displayName);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => () => dispatch(reset()), []);

  React.useEffect(() => {
    dispatch(fetchRole(roleId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDisplayNameChange = e => {
    setDisplayName(e.target.value);
  };
  const [permissions, setPermissions] = React.useState(null);
  const getPermissionsState = permissionsState => {
    setPermissions(permissionsState);
  };

  React.useEffect(() => {
    setDisplayName(role.display_name);
    if (role.permissions) {
      const granted = [];
      role.permissions.forEach(perm => {
        granted.push(Object.keys(perm)[0]);
      });
    }
  }, [role]);
  const [isSubmitting, setItSubmitting] = React.useState(false);
  async function handleSubmit(e) {
    setItSubmitting(true);
    e.preventDefault();
    const res = await updateMechantRole({
      permissions,
      display_name: displayName,
      role_id: roleId,
      merchant_id: appState.currentUser.merchant.merchantId,
    });
    message.success(t('roleUpdateSuccess'));
    setItSubmitting(false);
    onSuccess();
  }

  return (
    <div className={b('address-form')}>
      <Form onSubmit={handleSubmit}>
        <FormRow>
          <h4>{t('roleDetails')}</h4>
        </FormRow>
        <Row className={cls('section')} style={{ marginTop: '2em' }}>
          <Col md={12} sm={12} xs={12}>
            <Input
              name="display_name"
              label={`${t('name')} *`}
              type="text"
              value={displayName}
              onChange={handleDisplayNameChange}
            />
          </Col>
        </Row>
        {isResolved && (
          <Row className={cls('section')} style={{ marginTop: '3em' }}>
            <Col md={12}>
              <FormRow>
                <h4>{t('permissionDetails')}</h4>
              </FormRow>
              <Row style={{ marginTop: '2em' }}>
                <Col md={{ span: 11, offset: 1 }} className="permissionSetterContainer">
                  <RolePermissions
                    list={RESOURCES}
                    getPermissionsState={getPermissionsState}
                    grantedPermissions={role.permissions.map(permission => {
                      return permission;
                    })}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        <FormRow type="submit">
          <Button
            htmlType="submit"
            size="large"
            type="primary"
            block
            loading={isSubmitting}
            disabled={!displayName || !permissions}
          >
            {t('updateRole')}
          </Button>
        </FormRow>
      </Form>
    </div>
  );
};

UpdateRoleForm.propTypes = {
  appState: PropTypes.objectOf(PropTypes.object).isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default UpdateRoleForm;
