import snakeToCamel from 'utils/snakeToCamel';

const pickCamelCasedFields = (fields, data) =>
  fields.reduce((res, key) => {
    res[snakeToCamel(key)] = data[key];
    return res;
  }, {});

const refundsSerializer = payload => {
  const fields = ['transaction_id', 'type', 'status', 'currency', 'amount', 'created_at'];
  const orderFields = [
    'order_id',
    'reference',
    'order_number',
    'display_reference',
    'total',
    'currency',
    'tax_amount',
  ];
  const customerFields = ['first_name', 'last_name', 'email'];
  return Array.isArray(payload)
    ? payload.map(item => ({
        ...pickCamelCasedFields(fields, item),
        ...pickCamelCasedFields(orderFields, item.order),
        ...pickCamelCasedFields(customerFields, item.order.customer || {}),
      }))
    : [];
};

export default refundsSerializer;
