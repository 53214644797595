/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { refundsReport } from 'api';
import { refundsSerializer } from './serializers';

const reportsInitialState = {
  isLoading: false,
  isResolved: false,
  fromDate: null,
  toDate: null,
  pageCount: 0,
  pages: {},
  currentPage: null,
  list: [],
};

const orders = createSlice({
  name: 'reportRefunds',
  initialState: reportsInitialState,
  reducers: {
    fetchReportStart(state) {
      state.isLoading = true;
    },
    fetchReportSuccess(state, { payload }) {
      const { data, ...rest } = payload;
      return {
        ...state,
        isLoading: false,
        isResolved: true,
        list: refundsSerializer(data),
        ...rest,
      };
    },
    fetchReportFail(state) {
      state.isLoading = false;
      state.isResolved = true;
    },
    changeDates(state, { payload }) {
      const { fromDate, toDate } = payload;
      return {
        ...state,
        fromDate,
        toDate,
      };
    },
    changePage(state, { payload }) {
      state.currentPage = payload;
    },
    reset() {
      return reportsInitialState;
    },
  },
});

export default orders.reducer;

export const {
  fetchReportStart,
  fetchReportSuccess,
  fetchReportFail,
  changeDates,
  changePage,
  reset,
} = orders.actions;

// { fromDate, toDate, search, limit, page, status }
export const fetchReport = params => async (dispatch, state) => {
  if (state.isLoading) {
    return;
  }

  dispatch(fetchReportStart());
  try {
    const response = await refundsReport(params);
    const { fromDate, toDate, search } = params;
    dispatch(
      fetchReportSuccess({
        fromDate,
        toDate,
        search,
        ...response,
      }),
    );
  } catch (e) {
    dispatch(fetchReportFail(e));
  }
};
