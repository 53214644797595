import snakeToCamel from 'utils/snakeToCamel';

const settlementsSerializer = payload => {
  const data = payload || [];
  return data.map(item =>
    Object.keys(item).reduce((res, key) => {
      res[snakeToCamel(key)] = item[key];
      return res;
    }, {}),
  );
};

export default settlementsSerializer;
