import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Heading from 'spotii-ui/Heading';
import Button from 'spotii-ui/Button';
import OtpForm from 'spotii-ui/OtpForm';
import './OtpInput.scss';
import ResendButtonWithTimer from './ResendButton';

const EnterOTP = ({ show, setShowOTPModal, approveDraft, resendOTP }) => {
  const { t } = useTranslation();
  const currLang = useSelector(state => state.language.language);
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleClose = () => {
    setShowOTPModal(false);
  };

  const handleSuccess = otp => {
    setIsLoading(true)
    setError(false)
    return approveDraft(otp).then((data) => {
      setShowOTPModal(false);
      setIsLoading(false)
    }).catch((e) => {
      setError(true)
      setIsLoading(false)
    })
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body>
        <Heading h3>
          <p style={{ textAlign: `${currLang === 'ar' ? 'right' : 'left'}` }}>
            {t('enterOtp')}
          </p>
        </Heading>
        <OtpForm onSubmit={handleSuccess} loading={isLoading} error={error} maxLength={6} inputBaseCls={'otp-input-draft'} />
      </Modal.Body>
      <Modal.Footer>
        <ResendButtonWithTimer isLoading={isLoading} resendOTP={resendOTP} />
        <Button type="default" style={{ width: '45%' }} className={currLang === 'ar' ? "mr-auto" : "ml-auto"} onClick={handleClose} disabled={isLoading}>
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

EnterOTP.propTypes = {
  show: PropTypes.bool.isRequired,
  setShowOTPModal: PropTypes.func.isRequired,
  resendOTP: PropTypes.func,
  approveDraft: PropTypes.func,
};

EnterOTP.defaultProps = {
  approveDraft: null,
};

export default EnterOTP;
