import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import b from 'b_';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Heading from 'spotii-ui/Heading';
import Icon from 'spotii-ui/Icon';
import Paper from 'components/Paper';
import ShopifyConfig from 'components/ShopifyConfig';
import { AppStateType } from 'reducers/application';

const Shopify = ({ appState }) => {
  const { t } = useTranslation();
  const currLang = useSelector(state => state.language.language);
  const { currentUser } = appState;
  const { publicKey, uuid } = currentUser.merchant;
  const cls = b.with('settings');

  return (
    <Paper>
      <Row className={cls('section', { spacing: 'small' })}>
        <Col md={9}>
          <p className={cls(`paragraph-${currLang}`)}>
            <Link to="/settings/bank-accounts">
              {currLang === 'ar' ? t('back') : ''}
              <Icon type="chevron-left" />
              {currLang !== 'ar' ? t('back') : ''}
            </Link>
          </p>
          <Heading h2>
            <p className={cls(`paragraph-${currLang}`)}>{t('shopifyIntegration')}</p>
          </Heading>
          <p className={cls(`paragraph-${currLang}`)}>
            {`${t('shopifyIntegrationDesc')} `}
            <a href="https://docs.spendwisor.app/#shopify" target="_blank" rel="noreferrer noopener">
              {`${t('documentation')}.`}
            </a>
          </p>
        </Col>
      </Row>
      <Row className={cls('section', { spacing: 'small' })}>
        <Col md={10}>
          <Heading h3>
            <p className={cls(`paragraph-${currLang}`)}>{t('codeSnippet')}</p>
          </Heading>
          <p className={cls(`paragraph-${currLang}`)}>{t('codeSnippetDesc')}</p>
          <div className="code-snippet">
            {`{% if checkout.transactions[0].gateway == 'Spendwisor - Shop Now, Pay Later ◉◎◎◎' %}
<script id="sptii-scrpt">
(function(w,d,s,i,k,o) {
var sandbox = ${process.env.NODE_ENV === 'production' ? 'false' : 'true'};
var f=d.getElementsByTagName(s)[0];var a=d.createElement('script');a.async=true;a.src='https://widget.spotii.me/v1/javascript/shopify-checkout.min.js?uuid='+i;w.__sptii=[i,k,o,sandbox];f.parentNode.insertBefore(a,f);
}(window, document, 'script', '${uuid || ''}', '${publicKey}', '{{ checkout.order_id }}'));
</script>
{% endif %}`}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <ShopifyConfig />
        </Col>
      </Row>
    </Paper>
  );
};

Shopify.propTypes = {
  appState: PropTypes.shape(AppStateType).isRequired,
};

export default Shopify;
