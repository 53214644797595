import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import './Button.scss';

const Button = ({
  type,
  size,
  shape,
  ghost,
  paddings,
  icon,
  loading,
  block,
  className,
  onClick,
  children,
  ...rest
}) => {
  const iconType = loading ? 'loading' : icon;
  const iconNode = iconType ? <Icon type={iconType} /> : null;

  const cls = b('button', {
    type,
    size,
    shape,
    block,
    loading,
    ghost,
    disabled: rest.disabled,
    'no-paddings': paddings === false,
    'icon-only': !children && children !== 0 && iconType,
  });
  const classes = className ? `${cls} ${className}` : cls;

  const handleOnClick = e => {
    if (loading) {
      return;
    }
    if (onClick) {
      onClick(e);
    }
  };

  const { to, href, htmlType, ...otherProps } = rest;

  // Internal link refference
  if (to) {
    return (
      <Link {...otherProps} to={to} onClick={handleOnClick} className={classes}>
        <span>
          {iconNode}
          {children ? <span>{children}</span> : null}
        </span>
      </Link>
    );
  }

  // Regular link
  if (href) {
    return (
      <a {...otherProps} href={href} onClick={handleOnClick} className={classes}>
        <span>
          {iconNode}
          {children ? <span>{children}</span> : null}
        </span>
      </a>
    );
  }

  // Standard button
  return (
    // eslint-disable-next-line react/button-has-type
    <button {...otherProps} type={htmlType} onClick={handleOnClick} className={classes}>
      <span>
        {iconNode}
        {children ? <span>{children}</span> : null}
      </span>
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['default', 'primary', 'secondary', 'link', 'transparent']),
  size: PropTypes.oneOf(['small', 'default', 'large']),
  shape: PropTypes.oneOf(['normal', 'square', 'circle']),
  htmlType: PropTypes.oneOf(['submit', 'button', 'reset']),
  icon: PropTypes.string,
  loading: PropTypes.bool,
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  ghost: PropTypes.bool,
  paddings: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

Button.defaultProps = {
  type: 'default',
  size: 'default',
  shape: 'normal',
  htmlType: 'button',
  icon: undefined,
  loading: false,
  block: false,
  disabled: false,
  ghost: false,
  paddings: undefined,
  className: null,
  onClick: undefined,
  children: undefined,
};

export default Button;
