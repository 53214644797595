import b from 'b_';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import RolePermissions from 'components/RolePermissions';
import Button from 'spotii-ui/Button';
import Form, { FormRow } from 'spotii-ui/Form';
import message from 'spotii-ui/Message';
import Input from 'spotii-ui/Input';

import { createMerchantRole } from 'api';
import { RESOURCES } from 'utils/constants';

import './CreateRole.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

const cls = b.with('settings');

const CreateRoleForm = ({ appState, onSuccess }) => {
  const { t } = useTranslation();
  const currLang = useSelector(state => state.language.language);
  const [displayName, setDisplayName] = React.useState('');
  const handleDisplayNameChange = e => {
    setDisplayName(e.target.value);
  };
  const [permissions, setPermissions] = React.useState(null);
  const getPermissionsState = permissionsState => {
    setPermissions(permissionsState);
  };

  const [isSubmitting, setItSubmitting] = React.useState(false);
  async function handleSubmit(e) {
    setItSubmitting(true);
    e.preventDefault();
    const res = await createMerchantRole({
      permissions,
      display_name: displayName,
      merchant_id: appState.currentUser.merchant.merchantId,
    });
    message.success(t('roleCreationSuccess'));
    setItSubmitting(false);
    onSuccess();
  }

  return (
    <div className={b('address-form')}>
      <Form onSubmit={handleSubmit}>
        <FormRow>
          <h4>
            <p className={`paragraph-${currLang}`}>{t('roleDetails')}</p>
          </h4>
        </FormRow>
        <Row className={cls('section')} style={{ marginTop: '2em' }}>
          <Col md={12} sm={12} xs={12}>
            <Input
              name="display_name"
              label={t('nameStar')}
              type="text"
              value={displayName}
              onChange={handleDisplayNameChange}
            />
          </Col>
        </Row>
        <Row className={cls('section')} style={{ marginTop: '3em' }}>
          <Col md={12}>
            <FormRow>
              <h4>
                <p className={`paragraph-${currLang}`}>{t('permissionDetails')}</p>
              </h4>
            </FormRow>
            <Row style={{ marginTop: '2em' }}>
              <Col md={{ span: 11, offset: 1 }} className="permissionSetterContainer">
                <RolePermissions list={RESOURCES} getPermissionsState={getPermissionsState} />
              </Col>
            </Row>
          </Col>
        </Row>
        <FormRow type="submit">
          <Button
            htmlType="submit"
            size="large"
            type="primary"
            block
            loading={isSubmitting}
            disabled={!displayName || !permissions}
          >
            {t('createRole')}
          </Button>
        </FormRow>
      </Form>
    </div>
  );
};

CreateRoleForm.propTypes = {
  appState: PropTypes.objectOf(PropTypes.object).isRequired,
  onSuccess: PropTypes.func.isRequired,
};
export default CreateRoleForm;
