/* eslint-disable prefer-template */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import QRCode from 'qrcode.react';
import config from 'utils/config';
import Currency from 'spotii-ui/Currency';
import moment from 'moment';
import CheckoutStatus from 'components/CheckoutStatus';
import axios from 'axios';

const QRCodeModal = ({ show, setShowQRCodeModal, paymentLinkData }) => {
  const { t } = useTranslation();
  const { currentUser, loggedEmployee } = useSelector(state => state.application);

  const [qrCodeText, setQrCodeText] = useState('');

  const tag = 'spotii-payment-link-qr';
  const { merchant, fullName } = currentUser;
  const { api_employee_id } = loggedEmployee;

  const token = paymentLinkData.checkoutUrl.split('token=')[1];

  useEffect(() => {
    const ref = paymentLinkData.displayReference || paymentLinkData.reference;
    const { isPersonalLoan } = paymentLinkData || false;

    const firebaseLink =
      `${config('deep_linking_base_url')}/checkout?` +
      encodeURIComponent(
        `tag=${tag}&` +
          `id=${merchant.merchantId}&` +
          `userId=${api_employee_id}&` +
          `employeeName=${fullName}&` +
          `${isPersonalLoan ? 'loanAmount=' + paymentLinkData.total + '&' : ''}` +
          `${isPersonalLoan ? 'orderReference=' + ref + '&' : ''}` +
          `${isPersonalLoan ? 'merchantName=' + merchant.legalBusinessName + '&' : ''}` +
          `token=${token}`,
      );

    const fallbackUrl =
      `${config('deep_linking_base_url')}/checkout?` + encodeURIComponent(`token=${token}`);

    const longDynamicLink = `https://spotii.page.link/?link=${firebaseLink}&apn=com.spotiidmcc.spotii&afl=${fallbackUrl}&isi=1536574288&ibi=com.spotiidmcc.spotii&ifl=${fallbackUrl}`;

    axios
      .post(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${config(
          'firebase_web_api_key',
        )}`,
        {
          longDynamicLink,
        },
      )
      .then(response => {
        setQrCodeText(response.data.shortLink);
      });
  }, [tag, merchant, api_employee_id, fullName, token]);

  const handleClose = () => {
    setShowQRCodeModal(false);
  };

  return (
    <Modal
      style={{ backdropFilter: 'blur(5px)' }}
      size="xl"
      show={show}
      onHide={handleClose}
      centered
    >
      <Modal.Header style={{ border: 'none', height: 0 }} closeButton />
      <Modal.Body style={{ alignSelf: 'center', margin: '125px 0', padding: '0 100px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p style={{ fontWeight: 'bold', fontSize: '20px' }}>{t('paymentQrCode')}</p>
            <QRCode
              value={qrCodeText}
              renderAs="svg"
              className="pb-1"
              style={{
                width: '115%',
                height: 'fit-content',
                marginBottom: '1em',
              }}
            />
            <div className="pb-1">
              <span className="font-weight-bold pb-1">Date created: </span>
              {moment.parseZone(paymentLinkData.createdAt).format('DD MMMM YYYY')}
            </div>
            <div>
              <span className="font-weight-bold">Reference: </span>
              {paymentLinkData.displayReference || paymentLinkData.reference}
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginRight: '50px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  borderLeft: '1.5px solid',
                  height: '150px',
                  paddingLeft: '50px',
                  marginLeft: '75px',
                }}
              >
                <div style={{ display: 'flex', alignSelf: 'flex-end' }}>
                  <span className="pb-1">Status: </span>
                </div>

                <br />

                <div style={{ display: 'flex', alignSelf: 'flex-end' }}>
                  <span>Total Amt: </span>
                </div>
              </div>
            </div>

            <br />

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <div style={{ display: 'flex', alignSelf: 'flex-start' }}>
                <CheckoutStatus status={paymentLinkData.status} badge />
              </div>

              <br />

              <div style={{ display: 'flex', alignSelf: 'flex-start' }}>
                <Currency
                  clsName="font-weight-bold"
                  currency={paymentLinkData.currency}
                  amount={paymentLinkData.total}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

QRCodeModal.propTypes = {
  setShowQRCodeModal: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  paymentLinkData: PropTypes.shape({
    createdAt: PropTypes.string,
    status: PropTypes.string,
    reference: PropTypes.string,
    displayReference: PropTypes.string,
    total: PropTypes.string,
    currency: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    order: PropTypes.object,
    checkoutUrl: PropTypes.string,
    isPersonalLoan: PropTypes.bool,
  }).isRequired,
  // handleSendLink: PropTypes.func.isRequired,
  // copyTextToClipboard: PropTypes.func.isRequired,
};

export default QRCodeModal;
