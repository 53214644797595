/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getRolesList } from 'api';
import { RolesListSerializer } from './serializers';

const rolesInitialState = {
  isLoading: false,
  isResolved: false,
  pageCount: 0,
  pages: {},
  currentPage: null,
  list: [],
};

const roles = createSlice({
  name: 'roles',
  initialState: rolesInitialState,
  reducers: {
    fetchRolesStart(state) {
      state.isLoading = true;
    },
    fetchRolesSuccess(state, { payload }) {
      const dataArray = Object.keys(payload.data).map(i => payload.data[i]);
      return {
        ...state,
        isLoading: false,
        isResolved: true,
        ...payload,
        list: RolesListSerializer(dataArray),
      };
    },
    fetchRolesFail(state) {
      state.isLoading = false;
      state.isResolved = true;
    },
    changePage(state, { payload }) {
      state.currentPage = payload;
    },
    reset() {
      return rolesInitialState;
    },
  },
});

export default roles.reducer;

export const {
  fetchRolesStart,
  fetchRolesSuccess,
  fetchRolesFail,
  reset,
  changePage,
} = roles.actions;

export const fetchRoles = params => async (dispatch, state) => {
  if (state.isLoading) {
    return;
  }
  dispatch(fetchRolesStart());
  try {
    const response = await getRolesList(params);
    dispatch(
      fetchRolesSuccess({
        ...response,
      }),
    );
  } catch (e) {
    dispatch(fetchRolesFail(e));
  }
};
