import React from 'react';
import PropTypes from 'prop-types';
import { Switch, useRouteMatch, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Layout from 'auth/Layout';
import TopBar from 'auth/TopBar';
import RouteUnauthenticated from 'components/RouteUnauthenticated';
import { AppStateType } from 'reducers/application';

// Sub-routes
import PasswordRecovery from './PasswordRecovery';
import Verify from './Verify';
import NewPassword from './NewPassword';
import RecoveryDone from './RecoveryDone';

export default function PasswordRecoveryIndex({ appState }) {
  const { path } = useRouteMatch();
  const location = useLocation();

  return (
    <Layout>
      <TopBar authOption="login" />
      <Layout.Container>
        <Layout.ContainerInner>
          <TransitionGroup component={null}>
            <CSSTransition key={location.key} classNames="fade" timeout={300}>
              <Switch location={location}>
                <RouteUnauthenticated
                  path={path}
                  exact
                  component={PasswordRecovery}
                  appState={appState}
                />
                <RouteUnauthenticated
                  path={`${path}/verify`}
                  exact
                  component={Verify}
                  appState={appState}
                />
                <RouteUnauthenticated
                  path={`${path}/new-password`}
                  exact
                  component={NewPassword}
                  appState={appState}
                />
                <RouteUnauthenticated
                  path={`${path}/done`}
                  exact
                  component={RecoveryDone}
                  appState={appState}
                />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </Layout.ContainerInner>
      </Layout.Container>
    </Layout>
  );
}

PasswordRecoveryIndex.propTypes = {
  appState: PropTypes.shape(AppStateType).isRequired,
};
