import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  CHECKOUT_STATUS_OPEN,
  CHECKOUT_STATUS_DRAFT,
  CHECKOUT_STATUS_FAILED,
  CHECKOUT_STATUS_SUBMITTED,
  CHECKOUT_STATUS_NULL,
  CHECKOUT_STATUS_EXPIRED,
} from 'utils/constants';

import './statusPicker.scss';

const statusToText = {
  ALL: {
    text: 'all',
    type: 'default',
  },
  [CHECKOUT_STATUS_OPEN]: {
    text: 'active',
    type: 'default',
  },
  [CHECKOUT_STATUS_DRAFT]: {
    text: 'active',
    type: 'default',
  },
  [CHECKOUT_STATUS_NULL]: {
    text: 'active',
    type: 'default',
  },
  [CHECKOUT_STATUS_EXPIRED]: {
    text: 'expired',
    type: 'fail',
  },
  [CHECKOUT_STATUS_SUBMITTED]: {
    text: 'completed',
    type: 'success',
  },
  [CHECKOUT_STATUS_FAILED]: {
    text: 'expired',
    type: 'fail',
  },
};

const StatusPicker = ({ onStatusChange, selectedStatus }) => {
  const { t } = useTranslation();
  const selectOptions = [
    { value: 'ALL', label: t(statusToText.ALL.text) },
    { value: 'OPEN', label: t(statusToText.OPEN.text) },
    { value: 'SUBMITTED', label: t(statusToText.SUBMITTED.text) },
    { value: 'FAILED', label: t(statusToText.FAILED.text) },
  ];

  return (
    <span className="statusPickerContainer">
      <Select
        options={selectOptions}
        value={selectedStatus || 'ALL'}
        placeholder={
          selectedStatus ? t(statusToText[selectedStatus].text) : t(statusToText['ALL'].text)
        }
        onChange={onStatusChange}
        controlShouldRenderValue
      />
    </span>
  );
};
StatusPicker.propTypes = {
  onStatusChange: PropTypes.func.isRequired,
  selectedStatus: PropTypes.string,
};
StatusPicker.defaultProps = {
  selectedStatus: '',
};
export default StatusPicker;
