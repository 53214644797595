import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { BankAccountType } from 'utils/types';
import BankAccountsListItem from './BankAccountsListItem';

import './BankAccountsList.scss';

const BankAccountsList = ({ list }) => {
  const cls = b.with('bank-accounts-list');
  const isOnlyItem = list.length === 1;

  return (
    <div className={cls()}>
      <div className={cls('list')}>
        {list.map(item => (
          <BankAccountsListItem key={item.bankAccountId} {...item} actions={!isOnlyItem} />
        ))}
      </div>
    </div>
  );
};

BankAccountsList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape(BankAccountType)).isRequired,
};

export default BankAccountsList;
