/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import ar from 'moment/locale/ar';
import en from 'moment/locale/en-gb';
import moment from 'moment';
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './DatePicker.scss';
import Icon from 'spotii-ui/Icon';

const DatePicker = props => {
  const { currLang, t } = props;
  currLang === 'ar' ? moment.locale('ar', ar) : moment.locale('en-gb', en);
  return (
    <div className="date-picker">
      <SingleDatePicker placeholder={t('date')} {...props} noBorder />
    </div>
  );
};

// eslint-disable-next-line react/display-name
DatePicker.DateRangePicker = props => {
  // eslint-disable-next-line react/prop-types
  const { currLang, t } = props;
  currLang === 'ar' ? moment.locale('ar', ar) : moment.locale('en-gb', en);
  return (
    <div className="date-picker">
      <DateRangePicker
        startDatePlaceholderText={t('startDate')}
        endDatePlaceholderText={t('endDate')}
        {...props}
        noBorder
        customInputIcon={<Icon type="calendar" />}
      />
    </div>
  );
};
DatePicker.DayPickerRangeController = DayPickerRangeController;

DatePicker.propTypes = {
  currLang: PropTypes.string,
  t: PropTypes.func,
};

DatePicker.defaultProps = {
  currLang: 'en',
  t: () => {},
};

export default DatePicker;
