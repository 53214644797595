import React, { Component } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { connect } from 'react-redux';
import hash from '../../utils/hash';
import Flag from '../Flag';
import './InputPhone.scss';
import { prefixISDFromAlpha2 } from '../../utils/countries';

class InputPhone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      idProp: `field_${props.name}_${hash()}`,
    };
  }

  componentDidMount() {
    const { autofocus } = this.props;
    if (autofocus) {
      this.input.focus();
    }
  }

  onFocus = e => {
    this.setState({ focused: true });
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(e);
    }
  };

  onBlur = e => {
    this.setState({ focused: false });
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(e);
    }
  };

  render() {
    const {
      currLang,
      country,
      name,
      type,
      label,
      placeholder,
      value,
      error,
      onChange,
      disabled,
      autofocus,
      onFocus,
      onBlur,
      ...other
    } = this.props;

    const { focused, idProp } = this.state;

    const baseClass = 'input-phone';
    const cls = b(baseClass, {
      focused,
      filled: !!value,
      disabled,
      error: !!error,
      'no-label': !label,
    });

    // Uncontrolled Input
    let valueProps = {
      defaultValue: value,
    };

    if (onChange) {
      valueProps = {
        value,
        onChange,
      };
    }

    const inputProps = {
      ref: ref => {
        this.input = ref;
      },
      className: b(baseClass, 'input'),
      id: idProp,
      name,
      type,
      disabled,
      placeholder,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
    };

    return (
      <label className={cls} htmlFor={idProp}>
        {/* <span className={b(baseClass, 'select')}>
          <span className={b(baseClass, 'select-wrap')}>
            <Flag country={country} />
            {prefixISDFromAlpha2(country)}
          </span>
        </span> */}
        <span className={b(baseClass, 'wrap')}>
          <span className={b(baseClass, `label-${currLang}`)}>{label}</span>
          <input {...inputProps} {...valueProps} {...other} />
        </span>
      </label>
    );
  }
}

InputPhone.propTypes = {
  country: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'password', 'email', 'tel', 'url']),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  currLang: PropTypes.string,
};

InputPhone.defaultProps = {
  type: 'text',
  label: undefined,
  placeholder: undefined,
  value: '',
  error: undefined,
  disabled: false,
  autofocus: false,
  onChange: undefined,
  onFocus: undefined,
  onBlur: undefined,
  currLang: 'en',
};

InputPhone.validateRegExp = /^(0)?\d{7,11}$/gi;

function mapStateToProps(state) {
  const { language } = state.language;
  return { currLang: language };
}

export default connect(mapStateToProps)(InputPhone);
