import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import './AuthPaper.scss';

const Paper = ({ children, className, size, pad, columns, picture }) => {
  const cls = b('auth-paper', { size, pad, columns, picture });
  const classes = className ? `${cls} ${className}` : cls;
  return <div className={classes}>{children}</div>;
};

Paper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'wide', 'stretch']),
  pad: PropTypes.oneOf(['0', '40']),
  columns: PropTypes.bool,
  picture: PropTypes.bool,
};

Paper.defaultProps = {
  size: undefined,
  pad: undefined,
  columns: false,
  picture: false,
  className: '',
};

export default Paper;
