import config from './config';

const buildLogoutUrl = () => {
  const { origin } = window.location;
  const basePath = config('root_path');

  return `${origin}${basePath}/logout`;
};

export default buildLogoutUrl;
