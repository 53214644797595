import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DashboardHeader from 'components/DashboardHeader';
import Paper from 'components/Paper';
import UpdateRoleForm from 'components/UpdateRoleForm';
import Icon from 'spotii-ui/Icon';

export default function RoleDetail({ appState }) {
  const [success, setSuccess] = React.useState(false);
  const currLang = useSelector(state => state.language.language);
  const { t } = useTranslation();

  if (success) {
    return <Redirect to="/admin/roles" push />;
  }
  return (
    <>
      <DashboardHeader title="Update a role" appState={appState} />
      <Paper>
        <Row>
          <Col md="7">
            <p style={{ marginBottom: '35px' }}>
              <Link to="/admin/roles">
                {currLang === 'ar' ? t('back') : ''}
                <Icon type="chevron-left" />
                {currLang !== 'ar' ? t('back') : ''}
              </Link>
            </p>
            <UpdateRoleForm appState={appState} onSuccess={() => setSuccess(true)} />
          </Col>
        </Row>
      </Paper>
    </>
  );
}
RoleDetail.propTypes = {
  appState: PropTypes.objectOf(PropTypes.object).isRequired,
};
