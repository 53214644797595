/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import authApi from 'api/auth';

const registrationInitialState = {
  isLoading: false,
  isFinalized: false,
  isPopulated: false,
  country: null,
};

const registration = createSlice({
  name: 'registration',
  initialState: registrationInitialState,
  reducers: {
    setCountry(state, { payload }) {
      const { country } = payload;
      state.country = country;
    },
    startFinalization(state) {
      state.isLoading = true;
    },
    successFinalization() {
      return {
        ...registrationInitialState,
        isFinalized: true,
      };
    },
    failFinalization(state) {
      state.isLoading = false;
    },
    clearRegistration() {
      return {
        isLoading: false,
        isFinalized: false,
      };
    },
    setRegistrationFields(state, { payload }) {
      const data = {
        ...state,
        ...payload,
        isPopulated: true,
      };
      return data;
    },
  },
});

export default registration.reducer;

export const {
  setCountry,
  setRegistrationFields,
  startFinalization,
  successFinalization,
  failFinalization,
  clearRegistration,
} = registration.actions;

export const finalizeRegistration = registrationToken => async (dispatch, state) => {
  if (state.isLoading) {
    return;
  }
  dispatch(startFinalization());
  try {
    await authApi.loginWithToken(registrationToken);
    dispatch(successFinalization());
  } catch (e) {
    dispatch(failFinalization(e));
  }
};
