import snakeToCamel from 'utils/snakeToCamel';

/* {
    "api_key": "",
    "password": "",
    "hostname": "",
    "shared_secret": "",
} */
export const shopifyConfigSerializer = payload => {
  const fields = ['api_key', 'password', 'hostname', 'shared_secret'];
  return fields.reduce(
    (result, key) => ({
      ...result,
      [snakeToCamel(key)]: payload[key] || undefined,
    }),
    {},
  );
};

export default shopifyConfigSerializer;
