import snakeToCamel from 'utils/snakeToCamel';

const normalizeFields = source => (res, prop) => {
  res[snakeToCamel(prop)] = source[prop];
  return res;
};

const userSerializer = data => {
  if (!data) {
    return {};
  }

  const user = [
    'email',
    'user_id',
    'first_name',
    'last_name',
    'date_of_birth',
    'phone_number',
    'email_verifyed_at',
  ].reduce(normalizeFields(data.user), {});

  const currentUser = {
    ...user,
    ...['has_identities_uploaded', 'is_identities_valid'].reduce(normalizeFields(data), {}),
    ...['employee_id', 'employee_id'].reduce(normalizeFields(data), {}),
    ...['allow_no_call_back_urls', 'order_min_amount'].reduce(normalizeFields(data), {}),
    fullName: [user.firstName ? `${user.firstName} ` : '', user.lastName].join(''),
    merchant: Object.keys(data.merchant).reduce(normalizeFields(data.merchant), {}),
  };

  return currentUser;
};

export default userSerializer;
