export default function queryString(name, url = window.location.href) {
  const normalizedName = name.replace(/[[]]/g, '\\$&');
  const regex = new RegExp(`[?&]${normalizedName}(=([^&#]*)|&|#|$)`, 'i');
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
