import React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Form, { FormRow, InputRow } from 'spotii-ui/Form';
import Button from 'spotii-ui/Button';
import authApi from 'api/auth';
import { useTranslation } from 'react-i18next';
import './Login.scss';
import config from 'utils/config';

const Login = () => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={async (values, actions) => {
        try {
          const { email, password } = values;
          // On success will trigger onAuthStatusChange and reload the page
          await authApi.login(email, password, { tenantId: config('TENANT_ID') });
        } catch (e) {
          actions.setErrors({
            password: t('wrongPassAndEmail'),
          });
        }
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t('somethingWrongEmail'))
          .required(t('emailIsRequired')),
        password: Yup.string()
          .min(8, t('passwordMin8'))
          .required(t('passwordIsRequired')),
      })}
    >
      {attrs => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          submitCount,
        } = attrs;

        return (
          <Form onSubmit={handleSubmit}>
            <InputRow
              label={t('businessEmail')}
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={submitCount && errors.email && touched.email ? errors.email : null}
            />
            <InputRow
              label={t('password')}
              name="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={submitCount && errors.password && touched.password ? errors.password : null}
            />
            <FormRow type="submit">
              <Button
                htmlType="submit"
                size="large"
                type="primary"
                loading={isSubmitting}
                block
                disabled={isSubmitting}
              >
                {t('signIn')}
              </Button>
            </FormRow>
            <FormRow className="login__recover">
              {t('cantSignIn')} <Link to="password-recovery">{t('passwordRecovery')}</Link>
            </FormRow>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Login;
