import { isAndroidWebView } from 'utils/platformHelpers';

export default function config(name) {
  const normalizedName = name.toUpperCase();

  // Handle FAB POS Device routing
  if (isAndroidWebView()) {
    const authArr = [
      'VERIFICATION_BASE_URL',
      'PROFILE_BASE_URL',
      'REGISTRATION_BASE_URL',
      'RECOVERY_BASE_URL',
    ];
    const apiNName = 'PUBLIC_API_BASE_URL';
    const oauthNName = 'OAUTH_BASE_URL';

    const fabAuthEp = 'http://172.17.14.238:8080/api/v1.0';
    const fabApiEp = 'http://172.17.14.238:8081/api/v1.0';
    const fabOauthEp = 'http://172.17.14.238:8083';

    if (authArr.includes(normalizedName)) {
      return fabAuthEp;
    }

    if (apiNName === normalizedName) {
      return fabApiEp;
    }

    if (oauthNName === normalizedName) {
      return fabOauthEp;
    }

    return process.env[`REACT_APP_${normalizedName}`];
  }

  return process.env[`REACT_APP_${normalizedName}`];
}
