import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { AiOutlineStop, AiOutlineCheck } from 'react-icons/ai';

const OverlayIcon = ({ type, overlayMessage }) => {
  const icon =
    type === 'success' ? (
      <AiOutlineCheck style={{ color: '#0a6c6c', cursor: 'pointer' }} />
    ) : (
      <AiOutlineStop style={{ color: '#A8A8A8', cursor: 'pointer' }} />
    );
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip>{overlayMessage}</Tooltip>}
      >
        {icon}
      </OverlayTrigger>
    </div>
  );
};
OverlayIcon.propTypes = {
  type: PropTypes.oneOf(['success', 'failure']).isRequired,
  overlayMessage: PropTypes.string.isRequired,
};
export default OverlayIcon;
