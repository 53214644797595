import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import config from 'utils/config';
import lang from './i18n';
import App from './App';
import store from './store';
import * as serviceWorker from './serviceWorker';

import './styles/index.scss';

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={lang}>
      <Router basename={config('ROOT_PATH')}>
        <App />
      </Router>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
