import camelToSnake from 'utils/camelToSnake';

const normProp = prop => prop && prop.trim();

const serializer = values =>
  Object.keys(values).reduce(
    (res, key) => ({
      ...res,
      [camelToSnake(key)]: normProp(values[key]),
    }),
    {},
  );

export default serializer;
