import Admin from 'pages/Admin';
import Dashboard from 'pages/Dashboard';
import AddNewOrderForm from 'pages/Checkout/AddNewOrderForm';
import Checkouts from 'pages/Checkout/Checkouts';
import Orders from 'pages/Orders';
import Reports from 'pages/Reports';
import Settings from 'pages/Settings';

const PERMISSIONS = [
  {
    name: 'admin_read',
    paths: [
      '/admin',
      '/admin/employees',
      '/admin/addEmployee',
      '/admin/addEmployees',
      '/admin/roles',
      '/admin/role/:roleId',
      '/admin/addRole',
    ],
    component: Admin,
  },
  {
    name: 'dashboard_read',
    paths: ['/'],
    component: Dashboard,
  },
  {
    name: 'checkouts_read',
    paths: [
      '/checkout',
      '/checkout/checkouts',
      '/checkout/checkouts/:page?',
      '/checkout/orderRequests',
      '/checkout/orderRequests/:page?',
    ],
    component: Checkouts,
  },
  {
    name: 'foreign_checkouts_read',
    paths: ['/checkout', '/checkout/checkouts', '/checkout/checkouts/:page?'],
    component: Checkouts,
  },
  {
    name: 'checkouts_write',
    paths: ['/checkout', '/checkout/createOrder', '/checkout/orderRequests'],
    component: AddNewOrderForm,
  },
  {
    name: 'orders_read',
    paths: ['orders', '/orders/:status/:page?'],
    component: Orders,
  },
  {
    name: 'orders_write',
    component: Orders,
  },
  {
    name: 'reports_read',
    paths: [
      '/reports',
      '/reports/orders',
      '/reports/orders/:page?',
      '/reports/refunds',
      '/reports/refunds/:page?',
      '/reports/settlements',
      '/reports/settlements/:page?',
    ],
    component: Reports,
  },
  {
    name: 'settings_read',
    paths: ['/settings', '/settings/company', '/settings/bank-accounts', '/settings/integrations', '/settings/integrations/shopify'],
    component: Settings,
  },
];
export default PERMISSIONS;
