import message from 'spotii-ui/Message';

function fallbackCopyTextToClipboard(key, text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.position = 'fixed';
  textArea.style.visibility = 'hidden';
  textArea.style.top = '-100em';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    if (successful) {
      message.success(`${key} copied to clipboard`);
    }
    console.info(`Fallback: Copying text command was ${msg}`);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export default function copyTextToClipboard(key, text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(key, text);
    return Promise.resolve();
  }
  return navigator.clipboard.writeText(text).then(() => message.success(`${key} copied to clipboard`))
    .catch(err => err);
}
