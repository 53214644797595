import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import Paper from 'components/Paper';
import DraftsList from 'components/DraftsList';

const OrderRequests = ({ appState }) => {
  // const { status: CheckoutsType } = useParams();
  return (
    <>
      <Paper mobileFull>
        <DraftsList appState={appState} />
      </Paper>
    </>
  );
};

OrderRequests.propTypes = {
  appState: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default OrderRequests;
