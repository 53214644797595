import parseLinkheader from 'parse-link-header';

export const parseLink = parseLinkheader;

export const isLastPage = pageLinks => {
  return Object.keys(pageLinks).length === 2 && pageLinks.first && pageLinks.prev;
};

export const getPageCount = pageLinks => {
  if (!pageLinks) {
    return 1;
  }
  if (isLastPage(pageLinks)) {
    return parseInt(pageLinks.prev.page || 1, 10) + 1;
  }
  if (pageLinks.last) {
    return parseInt(pageLinks.last.page, 10);
  }
  return 0;
};
