import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import Icon from 'spotii-ui/Icon';
import hash from 'utils/hash';

const RadioGroup = ({ children, name, value, checked, onChange }) => {
  const cls = b.with('radio-group');
  const idProp = `field_${name}_${hash()}`;
  const inputProps = {
    id: idProp,
    name,
    value,
    checked,
    onChange,
  };

  return (
    <label htmlFor={idProp} className={cls('button', { checked })}>
      <span className={cls('button-label')}>{children}</span>
      <input className={cls('button-inp')} type="radio" {...inputProps} />
      <div className={cls('button-check')}>
        <Icon type="check" />
      </div>
    </label>
  );
};

RadioGroup.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

RadioGroup.defaultProps = {
  name: '',
  value: '',
  checked: false,
  onChange: null,
};

export default RadioGroup;
