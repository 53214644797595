import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import Icon from 'spotii-ui/Icon';
import './Avatar.scss';

const Avatar = ({ size, src, srcSet, icon, className, alt, children, ...rest }) => {
  const cls = b('avatar', {
    size: size === 'defalut' ? false : size,
    img: !!src,
    icon: !!icon,
  });
  const classes = className ? `${cls} ${className}` : cls;
  let child = children;

  if (src) {
    child = <img src={src} srcSet={srcSet} alt={alt} />;
  } else if (icon) {
    child = <Icon type={icon} />;
  } else {
    child = <span className={b('avatar', 'string')}>{children}</span>;
  }

  return (
    <span className={classes} {...rest}>
      {child}
    </span>
  );
};

Avatar.propTypes = {
  size: PropTypes.oneOf(['large', 'default', 'small']),
  src: PropTypes.string,
  srcSet: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  children: PropTypes.node,
};

Avatar.defaultProps = {
  size: 'default',
  src: null,
  srcSet: null,
  icon: null,
  className: null,
  alt: null,
  children: null,
};

export default Avatar;
