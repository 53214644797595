export const RolesListSerializer = data => {
  const serialized = [];
  data.forEach(el => {
    serialized.push({
      displayName: el.display_name,
      permissions: el.permissions,
      roleId: el.role_id,
    });
  });
  return serialized;
};

export default RolesListSerializer;
