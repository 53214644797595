import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import DashboardHeader from 'components/DashboardHeader';
import Paper from 'components/Paper';
import OrdersList from 'components/OrdersList';
import './Orders.scss';

const Orders = ({ appState }) => {
  const { status: ordersType } = useParams();
  const { t } = useTranslation();

  return (
    <div className="orders__container">
      <DashboardHeader
        title={t('orders')}
        navItems={[
          { pathname: '/orders/all', title: t('allOrders') },
          { pathname: '/orders/completed', title: t('completed') },
          { pathname: '/orders/refunded', title: t('refunded') },
          { pathname: '/orders/opened', title: t('opened') },
          { pathname: '/orders/voided', title: t('voided') },
        ]}
        appState={appState}
      />
      <Paper mobileFull>
        <OrdersList ordersType={ordersType} appState={appState} />
      </Paper>
    </div>
  );
};
Orders.propTypes = {
  appState: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default Orders;
