import React from 'react';
import { useSelector } from 'react-redux';
import { AiFillThunderbolt } from 'react-icons/ai';
import { BsFillLockFill } from 'react-icons/bs';
import { FaBirthdayCake, FaRecycle, FaTimesCircle } from 'react-icons/fa';
import { IoIosSend } from 'react-icons/io';
import { MdAddCircleOutline, MdLanguage } from 'react-icons/md';
import PropTypes from 'prop-types';
import b from 'b_';
import { ReactComponent as Loading } from './loading.svg';
import { ReactComponent as Ellipsis } from './ellipsis.svg';
import { ReactComponent as ScanQrCode } from './scan-qr-code.svg';
import './Icon.scss';

const Icon = ({ className, type }) => {
  const currLang = useSelector(state => state.language.language);
  const cls = b(`icon-${currLang}`, { type });
  const classes = className ? `${cls} ${className}` : cls;

  if (type === 'admin') {
    return <BsFillLockFill className={classes} width="1em" height="1em" fill="currentColor" />;
  }
  if (type === 'generate') {
    return <AiFillThunderbolt className={classes} width="1em" height="1em" fill="currentColor" />;
  }
  if (type === 'send') {
    return <IoIosSend className={classes} width="1em" height="1em" fill="currentColor" />;
  }
  if (type === 'new') {
    return <MdAddCircleOutline className={classes} width="1em" height="1em" fill="currentColor" />;
  }
  if (type === 'loading') {
    return <Loading className={classes} width="1em" height="1em" fill="currentColor" />;
  }
  if (type === 'ellipsis') {
    return <Ellipsis className={classes} width="1em" height="1em" fill="currentColor" />;
  }
  if (type === 'scan-qr-code') {
    return <ScanQrCode className={classes} width="1em" height="1em" fill="currentColor" />;
  }
  if (type === 'birthday') {
    return <FaBirthdayCake className={classes} width="1em" height="1em" fill="currentColor" />;
  }
  if (type === 'recycle') {
    return <FaRecycle className={classes} width="1em" height="1em" fill="currentColor" />;
  }
  if (type === 'times-circle') {
    return <FaTimesCircle className={classes} width="1em" height="1em" fill="currentColor" />;
  }
  if (type === 'language') {
    return <MdLanguage className={classes} width="1em" height="1em" fill="currentColor" />;
  }

  return (
    <svg
      className={classes}
      width="1em"
      height="1em"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <use xlinkHref={`#${type}`} />
    </svg>
  );
};

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Icon.defaultProps = {
  className: undefined,
};

export default Icon;
