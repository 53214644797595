import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Badge from 'spotii-ui/Badge';
import {
  CHECKOUT_STATUS_OPEN,
  CHECKOUT_STATUS_DRAFT,
  CHECKOUT_STATUS_FAILED,
  CHECKOUT_STATUS_SUBMITTED,
  CHECKOUT_STATUS_NULL,
  CHECKOUT_STATUS_EXPIRED,
  CHECKOUT_STATUS_IN_PROCESS,
} from 'utils/constants';

const statusToText = {
  [CHECKOUT_STATUS_OPEN]: {
    text: 'active',
    type: 'default',
  },
  [CHECKOUT_STATUS_DRAFT]: {
    text: 'active',
    type: 'default',
  },
  [CHECKOUT_STATUS_NULL]: {
    text: 'active',
    type: 'default',
  },
  [CHECKOUT_STATUS_IN_PROCESS]: {
    text: 'in process',
    type: 'default',
  },
  [CHECKOUT_STATUS_EXPIRED]: {
    text: 'expired',
    type: 'fail',
  },
  [CHECKOUT_STATUS_SUBMITTED]: {
    text: 'completed',
    type: 'success',
  },
  [CHECKOUT_STATUS_FAILED]: {
    text: 'expired',
    type: 'fail',
  },
};

const CheckoutStatus = ({ status, badge }) => {
  const { t } = useTranslation();
  if (status in statusToText) {
    const { type, text } = statusToText[status];

    if (badge) {
      return <Badge type={type}>{t(text)}</Badge>;
    }
    return <span>{t(text)}</span>;
  }
  return null;
};

CheckoutStatus.propTypes = {
  badge: PropTypes.bool,
};

CheckoutStatus.defaultProps = {
  badge: false,
};

export default CheckoutStatus;
