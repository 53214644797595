import React from 'react';
import { useSelector } from 'react-redux';
import b from 'b_';
import PropTypes from 'prop-types';
import Button from 'spotii-ui/Button';

import './IntegrationOption.scss';

const IntegrationOption = ({ logo, title, description, actions }) => {
  const currLang = useSelector(state => state.language.language);
  const cls = b.with('integration-option');

  return (
    <div className={cls()}>
      <div className={cls(`logo-${currLang}`)}>{logo}</div>
      <div className={cls('content')}>
        <p className={cls('title')}>{title}</p>
        <div className={cls(`description-${currLang}`)}>{description}</div>
        {actions ? (
          <div className={cls('actions')}>
            {actions.map(({ label, ...buttonProps }) => (
              <Button key={label} {...buttonProps}>
                {label}
              </Button>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

IntegrationOption.propTypes = {
  logo: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      href: PropTypes.string,
      to: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
};

IntegrationOption.defaultProps = {
  actions: null,
};

export default IntegrationOption;
