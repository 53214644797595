import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { LogoSignWhite } from 'spotii-ui/Logo';
import SidebarNav from 'components/SidebarNav/SidebarNav';
import { closeSidebar } from 'reducers/application';


import './Sidebar.scss';
import Button from 'spotii-ui/Button';

const Sidebar = ({ className, navItems }) => {
  const dispatch = useDispatch();

  return (
    <div className={classnames('sidebar', className)}>
      <div className="sidebar__inner">
        <div className="sidebar__close">
          <Button
            className="sidebar__close-button"
            type="transparent"
            size="large"
            shape="square"
            icon="x"
            onClick={() => dispatch(closeSidebar())}
          />
        </div>
        <div className="sidebar__logo">
          <Link to="/">
            <LogoSignWhite />
          </Link>
        </div>
        <div className="sidebar__nav">
          <SidebarNav items={navItems} />
        </div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  navItems: SidebarNav.propTypes.items,
};

Sidebar.defaultProps = {
  className: undefined,
  navItems: undefined,
};

export default Sidebar;
