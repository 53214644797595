/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import './Flag.scss';
import { ReactComponent as AEFlag } from './assets/AE.svg';
import { ReactComponent as KWFlag } from './assets/KW.svg';
import { ReactComponent as OMFlag } from './assets/OM.svg';
import Saudi from './assets/SA.png';
import { ReactComponent as BHFlag } from './assets/BH.svg';
import { ReactComponent as QAFlag } from './assets/QA.svg';

// For more flag its better to use https://github.com/catamphetamine/country-flag-icons
const Flag = ({ country }) => (
  // eslint-disable-next-line import/no-dynamic-require
  <span className={b('flag', { country })}>
    {country === 'AE' && <AEFlag />}
    {country === 'SA' && (
      <img src={Saudi} style={{ width: '26px', height: 'auto' }} alt="Saudi Arabia" />
    )}
    {country === 'BH' && <BHFlag />}
    {country === 'KW' && <KWFlag />}
    {country === 'OM' && <OMFlag />}
    {country === 'QA' && <QAFlag />}
  </span>
);

Flag.propTypes = {
  country: PropTypes.string.isRequired,
};

export default Flag;
