import React from 'react';
import PropTypes from 'prop-types';
import './Form.scss';

const Form = ({ children, className, ...props }) => {
  const cls = className ? `form ${className}` : 'form';
  return (
    <form className={cls} {...props}>
      {children}
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Form.defaultProps = {
  children: null,
  className: '',
};

export default Form;
