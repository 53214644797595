/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getQuickStats } from 'api';

const statisticsInitialState = {
  isLoading: false,
  isResolved: false,
  quickStats: {
    today: {},
    sales: {},
    customers: {},
  },
};

const statistics = createSlice({
  name: 'statistics',
  initialState: statisticsInitialState,
  reducers: {
    fetchQuickStatsStart(state) {
      state.isLoading = true;
    },
    fetchQuickStatsSuccess(state, { payload }) {
      return {
        ...state,
        isLoading: false,
        isResolved: true,
        ...payload,
        quickStats: payload,
      };
    },
    fetchQuickStatsFail(state) {
      state.isLoading = false;
      state.isResolved = true;
    },
    reset() {
      return statisticsInitialState;
    },
  },
});

export default statistics.reducer;

export const {
  fetchQuickStatsStart,
  fetchQuickStatsSuccess,
  fetchQuickStatsFail,
  reset,
} = statistics.actions;

// { fromDate, toDate, search, limit, page, status }
export const fetchQuickStats = () => async (dispatch, state) => {
  if (state.isLoading) {
    return;
  }
  dispatch(fetchQuickStatsStart());
  try {
    const response = await getQuickStats();
    dispatch(fetchQuickStatsSuccess(response));
  } catch (e) {
    dispatch(fetchQuickStatsFail(e));
  }
};
