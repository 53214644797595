import PropTypes from 'prop-types';
import React from 'react';

import DashboardHeader from 'components/DashboardHeader';
import Paper from 'components/Paper';
import EmployeeList from 'components/EmployeeList';

export default function Employees({ appState }) {
  return (
    <>
      <DashboardHeader
        appState={appState}
        title="Employees"
        navItems={[
          {
            pathname: '/admin/roles',
            title: 'Roles',
          },
        ]}
      />
      <Paper mobileFull>
        <EmployeeList appState={appState} />
      </Paper>
    </>
  );
}
Employees.propTypes = {
  appState: PropTypes.objectOf(PropTypes.object).isRequired,
};
