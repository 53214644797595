

const navItems = [
  {
    permName: ['dashboard_read'],
    pathname: '/',
    title: 'dashboard',
    exact: true,
    icon: 'layers',
  },
  {
    permName: ['admin_read'],
    pathname: '/admin',
    title: 'admin',
    exact: false,
    icon: 'admin',
  },
  {
    permName: ['checkouts_read', 'checkouts_write'],
    pathname: '/checkout',
    title: 'paymentLinks',
    exact: false,
    icon: 'codesandbox',
  },
  {
    permName: ['orders_read'],
    pathname: '/orders',
    title: 'orders',
    exact: false,
    icon: 'shopping-bag',
  },
  {
    permName: ['reports_read'],
    pathname: '/reports',
    title: 'reports',
    exact: false,
    icon: 'pie-chart',
  },
  {
    permName: ['settings_read'],
    pathname: '/settings',
    title: 'settings',
    exact: false,
    icon: 'settings',
  },
];
export default navItems;
