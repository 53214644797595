/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getCheckoutList } from 'api';
import { CheckoutsListSerializer } from './serializers';

const checkoutsInitialState = {
  isLoading: false,
  isResolved: false,
  fromDate: null,
  toDate: null,
  status: null,
  source: null,
  search: null,
  pageCount: 0,
  pages: {},
  currentPage: null,
  list: [],
};

const checkouts = createSlice({
  name: 'checkouts',
  initialState: checkoutsInitialState,
  reducers: {
    fetchCheckoutsStart(state) {
      state.isLoading = true;
    },
    fetchCheckoutsSuccess(state, { payload }) {
      const { data } = payload;
      return {
        ...state,
        isLoading: false,
        isResolved: true,
        ...payload,
        list: CheckoutsListSerializer(data),
      };
    },
    fetchCheckoutsFail(state) {
      state.isLoading = false;
      state.isResolved = true;
    },
    changeDates(state, { payload }) {
      const { fromDate, toDate } = payload;
      return {
        ...state,
        fromDate,
        toDate,
      };
    },
    changeQueryFilter(state, { payload }){
      const { search, currentPage } = payload;
      return {
        ...state,
        search,
        currentPage,
      }
    },
    changeStatus(state, { payload }) {
      const { status, currentPage } = payload;
      return {
        ...state,
        status,
        currentPage,
      };
    },
    changeSource(state, { payload }) {
      const { source, currentPage } = payload;
      return {
        ...state,
        source,
        currentPage,
      };
    },
    changePage(state, { payload }) {
      state.currentPage = payload;
    },
    reset() {
      return checkoutsInitialState;
    },
  },
});

export default checkouts.reducer;

export const {
  fetchCheckoutsStart,
  fetchCheckoutsSuccess,
  fetchCheckoutsFail,
  changeQueryFilter,
  changeDates,
  changeStatus,
  changeSource,
  changePage,
  reset,
} = checkouts.actions;

export const fetchCheckouts = params => async (dispatch, state) => {
  if (state.isLoading) {
    return;
  }
  dispatch(fetchCheckoutsStart());

  try {
    const response = await getCheckoutList(params);
    const { fromDate, toDate, search } = params;
    dispatch(
      fetchCheckoutsSuccess({
        fromDate,
        toDate,
        search,
        ...response,
      }),
    );
  } catch (e) {
    dispatch(fetchCheckoutsFail(e));
  }
};

// export const fetchCheckouts = params => async (dispatch, state) => {
//   console.log('fetch Checkouts method calling');
//   // if (state.isLoading) {
//   //   return;
//   // }
//   dispatch(fetchCheckoutsStart(params));
//   try {
//     await getCheckoutList(params).then(data => {
//       // const { fromDate, toDate, search } = params;
//       // dispatch(fetchCheckoutsSuccess(data));
//       console.log('data', data);

//       const { payload } = data;
//       return {
//         ...state,
//         isLoading: false,
//         isResolved: true,
//         ...payload,
//         list: CheckoutsListSerializer(data),
//       };
//     });
//   } catch (e) {
//     dispatch(fetchCheckoutsFail(e));
//   }
// };
