/**
 * @source https://ant.design/components/message/
 */
import React from 'react';
import { Provider } from 'react-redux';
import Notification from 'rc-notification';
import Icon from '../Icon';
import store from '../../store';

import './Message.scss';

let defaultDuration = 3;
let defaultTop;
let messageInstance;
let key = 1;
let prefixCls = 'message';
let transitionName = 'move-up';
let getContainer;
let maxCount;

const getMessageInstance = callback => {
  if (messageInstance) {
    callback(messageInstance);
    return;
  }
  Notification.newInstance(
    {
      prefixCls,
      transitionName,
      style: { top: defaultTop },
      getContainer,
      maxCount,
    },
    instance => {
      if (messageInstance) {
        callback(messageInstance);
        return;
      }
      messageInstance = instance;
      callback(instance);
    },
  );
};

const iconMap = {
  info: 'info',
  success: 'check-circle',
  error: 'x-circle',
  warning: 'alert-circle',
  loading: 'loading',
};

const notice = args => {
  const duration = args.duration !== undefined ? args.duration : defaultDuration;
  const IconComponent = iconMap[args.type] ? (
    <Icon className="ml-1 mr-1" type={iconMap[args.type]} />
  ) : null;

  // eslint-disable-next-line no-plusplus
  const target = args.key || key++;
  const closePromise = new Promise(resolve => {
    const callback = () => {
      if (typeof args.onClose === 'function') {
        args.onClose();
      }
      return resolve(true);
    };
    getMessageInstance(instance => {
      instance.notice({
        key: target,
        duration,
        style: {},
        // content: args.content,
        content: (
          <Provider store={store}>
            <div
              className={`${prefixCls}-custom-content${
                args.type ? ` ${prefixCls}-${args.type}` : ''
              }`}
            >
              {args.icon || IconComponent}
              <span>{args.content}</span>
            </div>
          </Provider>
        ),
        onClose: callback,
      });
    });
  });
  const result = () => {
    if (messageInstance) {
      messageInstance.removeNotice(target);
    }
  };
  result.then = (filled, rejected) => closePromise.then(filled, rejected);
  result.promise = closePromise;
  return result;
};

const isArgsProps = content => {
  return Object.prototype.toString.call(content) === '[object Object]' && !!content.content;
};

const api = {
  open: notice,
  config(options) {
    if (options.top !== undefined) {
      defaultTop = options.top;
      messageInstance = null; // delete messageInstance for new defaultTop
    }
    if (options.duration !== undefined) {
      defaultDuration = options.duration;
    }
    if (options.prefixCls !== undefined) {
      prefixCls = options.prefixCls;
    }
    if (options.getContainer !== undefined) {
      getContainer = options.getContainer;
    }
    if (options.transitionName !== undefined) {
      transitionName = options.transitionName;
      messageInstance = null; // delete messageInstance for new transitionName
    }
    if (options.maxCount !== undefined) {
      maxCount = options.maxCount;
      messageInstance = null;
    }
  },
  destroy() {
    if (messageInstance) {
      messageInstance.destroy();
      messageInstance = null;
    }
  },
};

['success', 'info', 'warning', 'error', 'loading'].forEach(type => {
  api[type] = (content, duration, onClose) => {
    if (isArgsProps(content)) {
      return api.open({ ...content, type });
    }

    if (typeof duration === 'function') {
      // eslint-disable-next-line no-param-reassign
      onClose = duration;
      // eslint-disable-next-line no-param-reassign
      duration = undefined;
    }

    return api.open({ content, duration, type, onClose });
  };
});

api.warn = api.warning;

export default api;
