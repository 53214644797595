import Layout from './Layout';
import Container from './Container';
import ContainerInner from './ContainerInner';

Layout.Container = Container;
Layout.ContainerInner = ContainerInner;

export default Layout;
export { Container };
export { ContainerInner };
