import PropTypes from 'prop-types';
import React from 'react';
import { Switch, useRouteMatch, Redirect, Route } from 'react-router-dom';

import { AppStateType } from 'reducers/application';
import RouteBinded from 'components/RouteBinded';
import AddEmployee from './AddEmployee';
import Employees from './Employees';
import Roles from './Roles';
import RoleDetail from './RoleDetail';
import AddRole from './addRole';
import AddEmployees from './AddEmployees';
import './Admin.scss';

const Admin = ({ appState }) => {
  const { path } = useRouteMatch();
  return (
    <div className="admin__container">
      <Switch>
        <RouteBinded path={`${path}/addEmployee`} component={AddEmployee} appState={appState} />
        <RouteBinded path={`${path}/addEmployees`} component={AddEmployees} appState={appState} />
        <RouteBinded path={`${path}/employees`} component={Employees} appState={appState} />
        <RouteBinded path={`${path}/roles`} component={Roles} appState={appState} />
        <RouteBinded path={`${path}/role/:roleId`} component={RoleDetail} appState={appState} />
        <RouteBinded path={`${path}/addRole`} component={AddRole} appState={appState} />
        <Route path={`${path}`} exact render={() => <Redirect to={`${path}/employees`} />} />
      </Switch>
    </div>
  );
};
Admin.propTypes = {
  appState: PropTypes.shape(AppStateType).isRequired,
};
export default Admin;
