import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import './BlockHead.scss';

const BlockHead = ({ type, children }) => (
  <div className={b('block-head', { type })}>{children}</div>
);

BlockHead.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
};

BlockHead.defaultProps = {
  type: null,
};

export default BlockHead;
