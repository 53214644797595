import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import b from 'b_';
import Icon from '../Icon';
import './Alert.scss';

const Alert = ({ type, icon, children }) => {
  const currLang = useSelector(state => state.language.language);
  return (
    <div className={b('alert', { type, icon: !!icon })}>
      {icon ? <Icon className={`${currLang === 'ar' ? 'ml-3' : 'mr-3'}`} type={icon} /> : null}
      {children}
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.oneOf(['warning', 'error', 'success', 'info']),
  icon: PropTypes.string,
  children: PropTypes.node,
};

Alert.defaultProps = {
  type: 'info',
  icon: null,
  children: null,
};

export default Alert;
