import React from 'react';
import Icon from 'spotii-ui/Icon';
import './AppLoading.scss';

const AppLoading = () => (
  <div className="app-loading">
    <div>
      <Icon type="loading" />
    </div>
  </div>
);

export default AppLoading;
