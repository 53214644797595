import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import './QuickStat.scss';

const QuickStat = ({ title, subtitle, children }) => {
  const cls = b.with('quick-stat');
  return (
    <div className={cls()}>
      <div className={cls('header')}>
        <p className={cls('title')}>{title}</p>
        <p className={cls('subtitle')}>{subtitle}</p>
      </div>
      <div className={cls('data')}>{children}</div>
    </div>
  );
};

QuickStat.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node,
};

QuickStat.defaultProps = {
  subtitle: ' ',
  children: null,
};

export default QuickStat;
