const normProp = prop => prop && prop.trim();

const fields = [
  'email',
  'phone',
  'orderNumber',
  'orderTotal',
  'itemTitle',
  'unitPrice',
  'description',
  'quantity',
  'taxAmount',
  'shippingAmount',
  'discount',
  'firstName',
  'lastName',
];

const serializer = values => ({
  ...fields.reduce(
    (res, key) => ({
      ...res,
      [key]: normProp(values[key]),
    }),
    {},
  ),
});

export default serializer;
