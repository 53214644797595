import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { CHECKOUT_SOURCE_INSTORE, CHECKOUT_SOURCE_NON_INSTORE } from 'utils/constants';

import './sourcePicker.scss';

const statusToText = {
  ALL: {
    text: 'all',
    type: 'default',
  },
  [CHECKOUT_SOURCE_INSTORE]: {
    text: 'Instore',
    type: 'default',
  },
  [CHECKOUT_SOURCE_NON_INSTORE]: {
    text: 'Online',
    type: 'default',
  },
};

const SourcePicker = ({ onSourceChange, selectedSource }) => {
  const { t } = useTranslation();
  const selectOptions = [
    { value: 'ALL', label: t(statusToText.ALL.text) },
    { value: 'INSTORE', label: t(statusToText.INSTORE.text) },
    { value: 'NON_INSTORE', label: t(statusToText.NON_INSTORE.text) },
  ];

  return (
    <span className="sourcePickerContainer">
      <Select
        options={selectOptions}
        value={selectedSource || 'ALL'}
        placeholder={
          selectedSource ? t(statusToText[selectedSource].text) : t(statusToText['ALL'].text)
        }
        onChange={onSourceChange}
        controlShouldRenderValue
      />
    </span>
  );
};
SourcePicker.propTypes = {
  onSourceChange: PropTypes.func.isRequired,
  selectedSource: PropTypes.string,
};
SourcePicker.defaultProps = {
  selectedSource: '',
};
export default SourcePicker;
