import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { TranslatedStringType } from 'utils/types';
import Icon from 'spotii-ui/Icon';
import { useTranslation } from 'react-i18next';

const NavItem = ({ className, pathname, title, icon, exact }) => {
  const { t } = useTranslation();
  return (
    <NavLink to={pathname} exact={exact} className={className}>
      {icon ? <Icon type={icon} /> : null}
      <span>{t(title)}</span>
    </NavLink>
  );
};

NavItem.propTypes = {
  pathname: PropTypes.string.isRequired,
  title: TranslatedStringType.isRequired,
  icon: PropTypes.string,
  exact: PropTypes.bool,
  className: PropTypes.string,
};

NavItem.defaultProps = {
  exact: false,
  className: undefined,
  icon: undefined,
};

export default NavItem;
