import b from 'b_';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Icon from 'spotii-ui/Icon';
import './ActionTile.scss';
import Heading from 'spotii-ui/Heading';

const ActionTile = ({ title, description, icon, to, href, onClick }) => {
  const cls = b.with('action-tile');
  const currLang = useSelector(state => state.language.language);

  const renderTile = () => {
    return (
      <>
        {icon ? (
          <div className={cls(`icon-${currLang}`)}>
            <Icon type={icon} className={cls(`icon-${currLang}`)} />
          </div>
        ) : null}
        <div className={cls('content')}>
          <Heading h4 className={cls('title')}>
            {title}
          </Heading>
          {description ? <p className={cls(`description-${currLang}`)}>{description}</p> : null}
        </div>
      </>
    );
  };

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  // Render the internal link
  if (to) {
    return (
      <Link to={to} className={cls({ actionable: true })} onClick={handleOnClick}>
        {renderTile()}
      </Link>
    );
  }

  // Remder link outside of the app
  if (href) {
    return (
      <a href={href} className={cls({ actionable: true })} onClick={handleOnClick}>
        {renderTile()}
      </a>
    );
  }

  // Render actionable button
  if (onClick) {
    return (
      <div
        className={cls({ actionable: true })}
        role="button"
        tabIndex={0}
        onClick={handleOnClick}
        onKeyDown={e => e.key === 'Enter' && handleOnClick()}
      >
        {renderTile()}
      </div>
    );
  }

  // Static render
  return <div className={cls()}>{renderTile()}</div>;
};

ActionTile.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
};

ActionTile.defaultProps = {
  description: null,
  icon: null,
  to: null,
  href: null,
  onClick: null,
};

export default ActionTile;
