import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Form, { FormRow, InputRow } from 'spotii-ui/Form';
import Button from 'spotii-ui/Button';
import authApi from 'api/auth';
import { setRecoveryFields } from 'reducers/recovery';
import config from 'utils/config';

const RecoveryForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const processFormErrors = err => {
    const { errors } = err;
    const formErrors = {};

    if (errors.code === 'user_does_not_exists') {
      formErrors.username = t('mailNotRegistered');
    }

    return formErrors;
  };
  return (
    <Formik
      initialValues={{ username: '' }}
      onSubmit={async (values, actions) => {
        try {
          // Send a request for password recovery
          await authApi.recoveryInit(values.username, {
            tenant_id: config('TENANT_ID'),
          });
          dispatch(setRecoveryFields(values));
          actions.setStatus('success');
        } catch (err) {
          actions.setErrors(processFormErrors(err));
          console.warn(err);
        }
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string()
          .email(t('somethingWrongEmail'))
          .required(t('emailForRecovery')),
      })}
    >
      {attrs => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          status,
          submitCount,
        } = attrs;

        if (status === 'success') {
          return <Redirect to="/password-recovery/verify" />;
        }

        return (
          <Form onSubmit={handleSubmit}>
            <InputRow
              label={t('registeredBusinessEmail')}
              name="username"
              type="email"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              error={submitCount && errors.username && touched.username ? errors.username : null}
            />
            <FormRow type="submit">
              <Button
                htmlType="submit"
                size="large"
                type="primary"
                loading={isSubmitting}
                disabled={isSubmitting}
                block
              >
                {t('sendRecoveryCode')}
              </Button>
            </FormRow>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RecoveryForm;
