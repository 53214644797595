import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Dropdown from 'spotii-ui/Dropdown';
import Avatar from 'spotii-ui/Avatar';
import Icon from 'spotii-ui/Icon';
import { useTranslation } from 'react-i18next';
import authApi from 'api/auth';

import './ProfileDropDown.scss';

const ProfileDropDown = ({ appState }) => {
  const { firstName, lastName, fullName, email } = useSelector(
    state => state.application.currentUser,
  );
  const { t } = useTranslation();
  const currLang = useSelector(state => state.language.language);

  const userHasRole = appState.loggedEmployee.role;
  let userHasSettingsPermission = true;
  if (userHasRole) {
    const userPermissions = appState.loggedEmployee.role.permissions;
    userHasSettingsPermission = userPermissions.includes('settings_read');
  }

  const abbr = [(firstName || '').slice(0, 1), (lastName || '').slice(0, 1)].join('').toUpperCase();

  return (
    <Dropdown alignRight={currLang !== 'ar'} alignLeft={currLang === 'ar'}>
      <Dropdown.Toggle as={Dropdown.CustomToggle}>
        <div className="profile-dropdown">
          <div className={`profile-dropdown__pic-${currLang}`}>
            <Avatar>{abbr}</Avatar>
          </div>
          <span className="profile-dropdown__name">{fullName || email}</span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="profile-dropdown__menu">
        <>
          <Dropdown.Item as={Link} to="/settings/personal">
            <Icon type="settings" />
            {t('profileSettings')}
          </Dropdown.Item>
          {(!userHasRole || (userHasRole && userHasSettingsPermission)) && (
            <>
              <Dropdown.Item as={Link} to="/settings/company">
                <Icon type="box" />
                {t('companySettings')}
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/settings/bank-accounts">
                <Icon type="credit-card" />
                {t('bankAccount')}
              </Dropdown.Item>
            </>
          )}
        </>

        <Dropdown.Item
          as="button"
          onClick={e => {
            e.preventDefault();
            authApi.revoke();
          }}
        >
          <Icon type="log-out" />
          {t('logOut')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
ProfileDropDown.propTypes = {
  appState: PropTypes.objectOf(PropTypes.object).isRequired,
};
export default ProfileDropDown;
