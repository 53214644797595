import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const ContainerInner = ({ children }) => <div className="layout__container-inner">{children}</div>;

ContainerInner.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContainerInner;
