import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { AppStateType } from 'reducers/application';

import PERMISSIONS from 'utils/permissionConstants';

const RouteBinded = ({ appState, component: C, ...rest }) => {
  if (
    appState.loggedEmployee &&
    appState.loggedEmployee.role &&
    ![
      '/signin',
      '/signup',
      '/signup/company-contacts',
      '/signup/company-details',
      '/signup/personal-details',
      '/signup/verify',
      '/signup/welcome',
      '/settings/personal',
    ].includes(rest.location.pathname)
  ) {
    const requiredPermission = PERMISSIONS.filter(perm => {
      return perm.paths ? perm.paths.indexOf(rest.path) >= 0 : false;
    });
    const userPermissions = appState.loggedEmployee.role.permissions;
    if (requiredPermission.length > 0 && userPermissions.includes(requiredPermission[0].name)) {
      return <Route {...rest} render={props => <C {...props} appState={appState} />} />;
    }

    // USER DOES NOT HAVE PERMISSION FOR REQUESTED ROUTE
    // CHECKING FOR THE CLOSEST AUTHORIZED ROUTE
    const authorizedRoute = userPermissions.filter(perm => {
      return perm.includes(rest.path.substr(1, rest.path.length));
    });
    let redirectPerm;
    if (authorizedRoute.length > 0) {
      redirectPerm = PERMISSIONS.filter(p => {
        return p.name === authorizedRoute[0];
      });
    } else {
      redirectPerm = PERMISSIONS.filter(p => {
        return p.name === userPermissions[0];
      });
    }
    // REDIRECTING ON AUTHORIZED ROUTE

    return <Redirect to={redirectPerm[0].paths[0]} />;
  }
  // NO ROLE PROVIDED
  return <Route {...rest} render={props => <C {...props} appState={appState} />} />;
};

RouteBinded.propTypes = {
  appState: PropTypes.shape(AppStateType).isRequired,
  component: PropTypes.elementType.isRequired,
};

export default RouteBinded;
