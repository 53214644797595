import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RecoveryForm from 'auth/RecoveryForm';
import Paper from 'auth/Paper';
import BlockHead from 'auth/BlockHead';
import Heading from 'spotii-ui/Heading';
import { useTranslation } from 'react-i18next';

export default function PasswordRecovery() {
  const { t } = useTranslation();
  return (
    <div className="page-transition">
      <Paper size="tiny">
        <Row>
          <Col>
            <BlockHead>
              <Heading h1>{t('passwordRecovery')}</Heading>
              <p>{t('passwordRecoveryDesc')}</p>
            </BlockHead>
            <RecoveryForm />
          </Col>
        </Row>
      </Paper>
    </div>
  );
}
