import { combineReducers } from '@reduxjs/toolkit';

import application from './application';
import registration from './registration';
import recovery from './recovery';
import orders from './orders';
import language from './language';
import checkouts from './checkouts';
import employees from './employees';
import role from './role';
import roles from './roles';
import reportSettlements from './reportSettlements';
import reportRefunds from './reportRefunds';
import reportOrders from './reportOrders';
import reportCheckouts from './reportCheckouts';
import bankAccounts from './bankAccounts';
import statistics from './statistics';
import shopifyConfig from './shopifyConfig';
import drafts from './drafts';

const rootReducer = combineReducers({
  application,
  registration,
  recovery,
  orders,
  language,
  checkouts,
  employees,
  role,
  roles,
  reportOrders,
  reportCheckouts,
  reportRefunds,
  reportSettlements,
  bankAccounts,
  statistics,
  shopifyConfig,
  drafts,
});

export default rootReducer;
