import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import Form, { FormRow, InputRow } from 'spotii-ui/Form';
import Button from 'spotii-ui/Button';
import message from 'spotii-ui/Message';
import { updateShopifyConfig } from 'api';
import * as Yup from 'yup';

import formSerializer from './form/serializer';
import errorsSerializer from './form/errors';

const ShopifyAppForm = ({ onSuccess, initialValues }) => {
  const { t } = useTranslation();

  const validation = Yup.object().shape({
    apiKey: Yup.string().required(t('apiKeyRequired')),
    password: Yup.string().required(t('passwordRequired')),
    hostname: Yup.string().required(t('websiteURLRequired')),
    sharedSecret: Yup.string().required(t('sharedSecretRequired')),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: async (values, actions) => {
      const data = formSerializer(values);
      actions.setStatus();

      try {
        await updateShopifyConfig(data);
        actions.setStatus('success');
        message.success(t('shopifyCredentialsSuccess'));
        if (onSuccess) {
          onSuccess(data);
        }
      } catch (e) {
        console.warn(e);
        const errors = errorsSerializer(e.errors);
        if (errors) {
          actions.setErrors(errors);
        } else {
          message.error(t('somethingWentWrong'))
          actions.setStatus('error');
        }
      }
    },
  });

  const { values, touched, errors, handleBlur, isSubmitting, handleChange } = formik;

  const getError = key => touched[key] && errors[key];

  return (
    <div className="shopify-app-form">
      <Form onSubmit={formik.handleSubmit}>
        <InputRow
          name="apiKey"
          label={t('apiKey')}
          type="text"
          value={values.apiKey}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getError('apiKey')}
        />
        <InputRow
          name="password"
          label={t('password')}
          type="text"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getError('password')}
        />
        <InputRow
          name="hostname"
          label={t('websiteDomain')}
          placeholder="I.e. mysupershop.com"
          type="text"
          value={values.hostname}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getError('hostname')}
        />
        <InputRow
          name="sharedSecret"
          label={t('sharedSecret')}
          type="text"
          value={values.sharedSecret}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getError('sharedSecret')}
        />
        <FormRow type="submit">
          <Button
            htmlType="submit"
            size="large"
            type="primary"
            block
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('saveSettings')}
          </Button>
        </FormRow>
      </Form>
    </div>
  );
};

ShopifyAppForm.propTypes = {
  onSuccess: PropTypes.func,
  initialValues: PropTypes.shape({
    apiKey: PropTypes.string,
    password: PropTypes.string,
    hostname: PropTypes.string,
    sharedSecret: PropTypes.string,
  }),
};

ShopifyAppForm.defaultProps = {
  onSuccess: null,
  initialValues: {
    apiKey: '',
    password: '',
    hostname: '',
    sharedSecret: '',
  },
};

export default ShopifyAppForm;
