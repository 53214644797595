import React, { useEffect } from 'react';
import { Switch, useLocation, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import RoutePrivate from 'components/RoutePrivate';
import RouteUnauthenticated from 'components/RouteUnauthenticated';
import RouteBinded from 'components/RouteBinded';
import { IconSprite } from 'spotii-ui/Icon';
import { getBrowserLang } from 'utils/language-helper';
import AppLoading from 'spotii-ui/AppLoading';
import { changeLanguage } from 'reducers/language';
import {
  authStatusChange,
  closeSidebar,
  fetchMerchantFlagStart,
  fetchMerchantFlagSuccess,
} from 'reducers/application';

// Pages
import Login from 'pages/Login';
import SignUp from 'pages/SignUp';
import PasswordRecovery from 'pages/PasswordRecovery';
import Dashboard from 'pages/Dashboard';
import queryString from 'utils/queryString';
import authApi from './api/auth';
import { getMerchantFlags } from './api';

const App = () => {
  const dispatch = useDispatch();
  const appState = useSelector(state => state.application);
  const { pathname, search } = useLocation();
  const isResolved = appState.isAuthStatusResolved;

  // Scroll to top on page change
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(closeSidebar());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const lang = queryString('lang', search);
    // eslint-disable-next-line no-console
    if (lang === 'ar' || lang === 'en') {
      dispatch(changeLanguage({ lang }));
    }
  }, []);

  useEffect(() => {
    return authApi.onAuthStatusChange(async user => {
      dispatch(authStatusChange(user));
      try {
        dispatch(fetchMerchantFlagStart());
        const result = await getMerchantFlags();
        if (result && result.data && result.data.length) {
          dispatch(fetchMerchantFlagSuccess(result.data[0]));
        }
      } catch (e) {
        console.warn('Failed to fetch merchant flags.');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (pathname === '/settings/add-bank-account') {
    return <Redirect to="/settings" />;
  }

  // set up language for returning users or new users with browser lang set
  const localStorageLang = localStorage.getItem('lang');
  const browserLang = getBrowserLang();

  if (localStorageLang === 'en' || localStorageLang === 'ar') {
    dispatch(changeLanguage({ lang: localStorageLang }));
  } else if (browserLang !== 'not-supported') {
    dispatch(changeLanguage({ lang: browserLang }));
  } else {
    dispatch(changeLanguage({ lang: 'en' }));
  }

  return !isResolved && !appState.isFlagMerchantLoading ? (
    <AppLoading />
  ) : (
    <>
      <IconSprite />
      <Switch>
        <RouteUnauthenticated path="/signin" exact component={Login} appState={appState} />
        <RouteBinded path="/signup" component={SignUp} appState={appState} />
        <RouteBinded path="/password-recovery" component={PasswordRecovery} appState={appState} />
        {/* Inner dashboard routes */}
        <RoutePrivate path="/" component={Dashboard} appState={appState} />
      </Switch>
    </>
  );
};

export default App;
