import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import DashboardHeader from 'components/DashboardHeader';
import Paper from 'components/Paper';
import RoleList from 'components/RoleList';
import Button from 'spotii-ui/Button';
import Icon from 'spotii-ui/Icon';

export default function Roles({ appState }) {
  return (
    <>
      <DashboardHeader
        title="Roles"
        appState={appState}
        navItems={[
          {
            pathname: '/admin/employees',
            title: 'Employees',
          },
        ]}
      />
      <Paper mobileFull>
        <Row>
          <Col md={12}>
            <Button
              type="link"
              to="/admin/addRole"
              style={{ float: 'right', marginBottom: '15px' }}
            >
              Add role <Icon type="new" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <RoleList appState={appState} />
          </Col>
        </Row>
      </Paper>
    </>
  );
}
Roles.propTypes = {
  appState: PropTypes.objectOf(PropTypes.object).isRequired,
};
