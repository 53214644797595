import React, { useState } from 'react';
import PropTypes from 'prop-types';
import hash from 'utils/hash';
import b from 'b_';
import Icon from '../Icon';
import './Selectbox.scss';

const Selectbox = ({ placeholder, children, loading, onChange, ...props }) => {
  const [currentValue, setCurrentValue] = useState(placeholder);
  // eslint-disable-next-line react/destructuring-assignment
  const [idProp] = useState(`field_${props.name || 'select'}_${hash()}`);
  // eslint-disable-next-line react/destructuring-assignment
  const [isEmpty, setIsEmpty] = useState(!props.value);
  const cls = b.with('selectbox');

  const handleChange = e => {
    let newValue = e.target.value;
    const selectedOption = React.Children.toArray(children).filter(
      child => child.props.value === newValue,
    );

    if (selectedOption.length) {
      newValue = selectedOption[0].props.children;
    }

    setIsEmpty(false);
    setCurrentValue(newValue);

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <label className={cls({ empty: isEmpty })} htmlFor={idProp}>
      <span className={cls('toggle')}>
        {currentValue}
        <span className={cls('toggle-arrow')}>
          <Icon type={loading ? 'loading' : 'chevron-down'} />
        </span>
      </span>
      <select onChange={handleChange} id={idProp} {...props}>
        {children}
      </select>
    </label>
  );
};

Selectbox.propTypes = {
  children: PropTypes.node.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
};

Selectbox.defaultProps = {
  name: null,
  value: '',
  loading: false,
  onChange: null,
};

export default Selectbox;
