import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { TranslatedStringType } from 'utils/types';
import DashboardHeaderNav from 'components/DashboardHeaderNav';
import ProfileDropDown from 'components/ProfileDropDown';
import './DashboardHeader.scss';

const DashboardHeader = ({ title, navItems, appState }) => {
  const currLang = useSelector(state => state.language.language);
  return (
    <div className="dashboard-header">
      <h1 className="dashboard-header__title">{title}</h1>
      {navItems ? (
        <DashboardHeaderNav
          className={currLang === 'ar' ? 'dashboard-header__nav-ar' : 'dashboard-header__nav'}
          items={navItems}
        />
      ) : null}
      <div className="dashboard-header__user">
        <ProfileDropDown appState={appState} />
      </div>
    </div>
  );
};

DashboardHeader.propTypes = {
  title: TranslatedStringType.isRequired,
  navItems: DashboardHeaderNav.propTypes.items,
  appState: PropTypes.objectOf(PropTypes.object).isRequired,
};

DashboardHeader.defaultProps = {
  navItems: null,
};

export default DashboardHeader;
