import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import NavItem from 'components/NavItem';
import './DashboardHeaderNav.scss';

const DashboardHeaderNav = ({ className, items }) => {
  return (
    <nav className={classnames('dashboard-header-nav', className)}>
      <ul>
        {items.map(item => (
          <li key={item.pathname}>
            <NavItem className="dashboard-header-nav__link" {...item} />
          </li>
        ))}
      </ul>
    </nav>
  );
};

DashboardHeaderNav.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(NavItem.propTypes)),
};

DashboardHeaderNav.defaultProps = {
  className: undefined,
  items: [],
};

export default DashboardHeaderNav;
