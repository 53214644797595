/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getEmployeesList } from 'api';
import { EmployeesListSerializer } from './serializers';

const employeesInitialState = {
  isLoading: false,
  isResolved: false,
  pageCount: 0,
  pages: {},
  currentPage: null,
  list: [],
};

const employees = createSlice({
  name: 'employees',
  initialState: employeesInitialState,
  reducers: {
    fetchEmployeesStart(state) {
      state.isLoading = true;
    },
    fetchEmployeesSuccess(state, { payload }) {
      const dataArray = Object.keys(payload.data).map(i => payload.data[i]);
      return {
        ...state,
        isLoading: false,
        isResolved: true,
        ...payload,
        list: EmployeesListSerializer(dataArray),
      };
    },
    fetchEmployeesFail(state) {
      state.isLoading = false;
      state.isResolved = true;
    },
    changePage(state, { payload }) {
      state.currentPage = payload;
    },
    reset() {
      return employeesInitialState;
    },
  },
});

export default employees.reducer;

export const {
  fetchEmployeesStart,
  fetchEmployeesSuccess,
  fetchEmployeesFail,
  changeDates,
  changeStatus,
  changePage,
  reset,
} = employees.actions;

export const fetchEmployees = params => async (dispatch, state) => {
  if (state.isLoading) {
    return;
  }
  dispatch(fetchEmployeesStart());

  try {
    const response = await getEmployeesList(params);
    dispatch(
      fetchEmployeesSuccess({
        ...response,
      }),
    );
  } catch (e) {
    dispatch(fetchEmployeesFail(e));
  }
};
