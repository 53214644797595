import moment from 'moment';
import React from 'react';
import Flag from 'spotii-ui/Flag';

export const ORDER_STATUS_OPENED = 'OPENED';
export const ORDER_STATUS_COMPLETED = 'COMPLETED';
export const ORDER_STATUS_REFUNDED = 'REFUNDED';
export const ORDER_STATUS_PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED';
export const ORDER_STATUS_VOIDED = 'VOIDED';

export const CHECKOUT_STATUS_OPEN = 'OPEN';
export const CHECKOUT_STATUS_DRAFT = 'DRAFT';
export const CHECKOUT_STATUS_EXPIRED = 'EXPIRED';
export const CHECKOUT_STATUS_SUBMITTED = 'SUBMITTED';
export const CHECKOUT_STATUS_FAILED = 'FAILED';
export const CHECKOUT_STATUS_IN_PROCESS = 'PROCESSING_PAYMENT';
export const CHECKOUT_STATUS_NULL = 'null';

export const PLAN_SLUGS = {
  BI_WEEKLY: 'bi-weekly',
  ZERO_DOWN_BI_WEEKLY: 'zero-downpayment-bi-weekly',
  MONTHLY: 'monthly',
  PAY_NOW: 'pay-now',
  MONTHLY_10_SPLITS: 'monthly-10-splits',
};

export const PLAN_RATE_SLUGS = {
  INSTALLMENTS: 'installments',
  PAY_NOW: 'pay_now',
};

export const DRAFT_STATUS_REJECTED = 'REJECTED';
export const DRAFT_STATUS_COMPLETED = 'COMPLETED';
export const DRAFT_STATUS_PENDING_MERCHANT = 'PENDING_MERCHANT';
export const DRAFT_STATUS_PENDING_PAYMENT = 'PENDING_PAYMENT';
export const DRAFT_STATUS_CANCELLED = 'CANCELLED';

export const CHECKOUT_SOURCE_NON_INSTORE = 'NON_INSTORE';
export const CHECKOUT_SOURCE_INSTORE = 'INSTORE';
export const CHECKOUT_SOURCE_PL = 'payment_link';

export const COUNTRY_SELECT_OPTIONS = [
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'BH', label: 'Bahrain' },
  // { value: 'KW', label: 'Kuwait' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'QA', label: 'Qatar' },
];

export const NO_IBAN_COUNTRIES = ['OM'];

export const RESOURCES = [
  {
    name: 'Admin',
    path: '/admin',
    actions: [{ key: 'Access', value: 'read' }],
  },
  {
    name: 'Settings',
    path: '/settings',
    actions: [{ key: 'Access', value: 'read' }],
  },
  {
    name: 'Checkouts',
    path: '/checkout',
    actions: [
      { key: 'Consult', value: 'read' },
      { key: 'Consult all', value: 'readall' },
      { key: 'Create', value: 'write' },
    ],
  },
  {
    name: 'Dashboard',
    path: '/',
    actions: [{ key: 'Access', value: 'read' }],
  },
  {
    name: 'Orders',
    path: '/orders',
    actions: [
      { key: 'Consult', value: 'read' },
      { key: 'Refund', value: 'write' },
    ],
  },
  {
    name: 'Reports',
    path: '/reports',
    actions: [{ key: 'Access', value: 'read' }],
  },
];

// Columns render options
export const EMPLOYEE_COLUMNS = [
  {
    title: 'First name',
    dataIndex: 'first_name',
    key: 'first_name',
    render: value => <span>{value}</span>,
  },
  {
    title: 'Last name',
    dataIndex: 'last_name',
    key: 'last_name',
    render: value => <span>{value}</span>,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: value => <span>{value}</span>,
  },
  {
    title: 'Added at',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: value => (
      <div className="checkouts-list__date" title={moment(value).format('YYYY-MM-DD HH:mm:ss')}>
        {moment(value).format('YYYY-MM-DD')}
      </div>
    ),
  },
];

export const IM_DISPLAY_NAME = 'InsuranceMarket.ae™';

export const AVAILABLE_ITEMS = [
  { value: 5425, label: 'IPhone 15 Pro Max' },
  { value: 4299, label: 'IPhone 15 Pro' },
  { value: 3799, label: 'IPhone 15 Plus' },
  { value: 3399, label: 'IPhone 15' },
  { value: 3900, label: 'IPhone 14 Pro Max' },
  { value: 4140, label: 'IPhone 14 Pro' },
  { value: 2899, label: 'IPhone 14 Plus' },
  { value: 2950, label: 'IPhone 14' },
  { value: 4949, label: 'Samsung Galaxy S23 Ultra' },
  { value: 3899, label: 'Samsung Galaxy S23+' },
  { value: 3199, label: 'Samsung Galaxy S23' },
  { value: 3197, label: 'Samsung Galaxy S22 Ultra' },
  { value: 2789, label: 'Samsung Galaxy S22+' },
  { value: 2099, label: 'Samsung Galaxy S22' },
  { value: 1970, label: 'Samsung Galaxy S21 Ultra' },
  { value: 1549, label: 'Samsung Galaxy S21+' },
  { value: 1380, label: 'Samsung Galaxy S21' },
];

export const FRESH_WORK_API_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const FRESH_WORK_API_ENDPOINTS = {
  SALES_ACCOUNTS: '/sales_accounts',
  CONTACTS: '/contacts',
};

export const FRESH_WORK_USER_ONBOARDING_STATUS = {
  LEAD: 'Lead',
  INACTIVE: 'In Active',
  ACTIVE: 'Active',
};

export const FRESH_WORK_USER_TYPES = {
  MERCHANT: 'Merchant',
};

export const DIALING_CODES = [
  {
    "name": {
      "en": "Qatar",
      "ar": "قطر"
    },
    "flag": "🇶🇦",
    "cc": "QA",
    "dc": "+974"
  },
  {
    "name": {
      "en": "Kuwait",
      "ar": "الكويت"
    },
    "flag": "🇰🇼",
    "cc": "KW",
    "dc": "+965"
  },
  {
    "name": {
      "en": "United Arab Emirates",
      "ar": "دولة الإمارات العربية المتحدة"
    },
    "flag": "🇦🇪",
    "cc": "AE",
    "dc": "+971"
  },
  {
    "name": {
      "en": "Saudi Arabia",
      "ar": "السعودية"
    },
    "flag": "🇸🇦",
    "cc": "SA",
    "dc": "+966"
  },
  {
    "name": {
      "en": "Bahrain",
      "ar": "‏البحرين"
    },
    "flag": "🇧🇭",
    "cc": "BH",
    "dc": "+973"
  },
  {
    "name": {
      "en": "Oman",
      "ar": "عمان"
    },
    "flag": "🇴🇲",
    "cc": "OM",
    "dc": "+968"
  },
  {
    "name": {
      "en": "Puerto Rico",
      "ar": "بويرتوريكو"
    },
    "flag": "🇵🇷",
    "cc": "PR",
    "dc": "+1"
  },
  {
    "name": {
      "en": "United States",
      "ar": "الولايات المتحدة"
    },
    "flag": "🇺🇸",
    "cc": "US",
    "dc": "+1"
  },
  {
    "name": {
      "en": "Dominican Republic",
      "ar": "جمهورية الدومينيكان"
    },
    "flag": "🇩🇴",
    "cc": "DO",
    "dc": "+1"
  },
  {
    "name": {
      "en": "Canada",
      "ar": "كندا"
    },
    "flag": "🇨🇦",
    "cc": "CA",
    "dc": "+1"
  },
  {
    "name": {
      "en": "Bahamas",
      "ar": "باهاماس"
    },
    "flag": "🇧🇸",
    "cc": "BS",
    "dc": "+1242"
  },
  {
    "name": {
      "en": "Barbados",
      "ar": "باربادوس"
    },
    "flag": "🇧🇧",
    "cc": "BB",
    "dc": "+1246"
  },
  {
    "name": {
      "en": "Anguilla",
      "ar": "أنغويلا"
    },
    "flag": "🇦🇮",
    "cc": "AI",
    "dc": "+1264"
  },
  {
    "name": {
      "en": "Antigua and Barbuda",
      "ar": "أنتيغوا وباربودا"
    },
    "flag": "🇦🇬",
    "cc": "AG",
    "dc": "+1268"
  },
  {
    "name": {
      "en": "British Virgin Islands",
      "ar": "جزر العذراء"
    },
    "flag": "🇻🇬",
    "cc": "VG",
    "dc": "+1284"
  },
  {
    "name": {
      "en": "United States Virgin Islands",
      "ar": "جزر العذراء الامريكية"
    },
    "flag": "🇻🇮",
    "cc": "VI",
    "dc": "+1340"
  },
  {
    "name": {
      "en": "Cayman Islands",
      "ar": "جزر كايمان"
    },
    "flag": "🇰🇾",
    "cc": "KY",
    "dc": "+1345"
  },
  {
    "name": {
      "en": "Bermuda",
      "ar": "برمودا"
    },
    "flag": "🇧🇲",
    "cc": "BM",
    "dc": "+1441"
  },
  {
    "name": {
      "en": "Grenada",
      "ar": "غرينادا"
    },
    "flag": "🇬🇩",
    "cc": "GD",
    "dc": "+1473"
  },
  {
    "name": {
      "en": "Turks and Caicos Islands",
      "ar": "جزر توركس وكايكوس"
    },
    "flag": "🇹🇨",
    "cc": "TC",
    "dc": "+1649"
  },
  {
    "name": {
      "en": "Montserrat",
      "ar": "مونتسرات"
    },
    "flag": "🇲🇸",
    "cc": "MS",
    "dc": "+1664"
  },
  {
    "name": {
      "en": "Northern Mariana Islands",
      "ar": "جزر ماريانا الشمالية"
    },
    "flag": "🇲🇵",
    "cc": "MP",
    "dc": "+1670"
  },
  {
    "name": {
      "en": "Guam",
      "ar": "غوام"
    },
    "flag": "🇬🇺",
    "cc": "GU",
    "dc": "+1671"
  },
  {
    "name": {
      "en": "American Samoa",
      "ar": "ساموا الأمريكية"
    },
    "flag": "🇦🇸",
    "cc": "AS",
    "dc": "+1684"
  },
  {
    "name": {
      "en": "Sint Maarten",
      "ar": "سينت مارتن"
    },
    "flag": "🇸🇽",
    "cc": "SX",
    "dc": "+1721"
  },
  {
    "name": {
      "en": "Saint Lucia",
      "ar": "سانت لوسيا"
    },
    "flag": "🇱🇨",
    "cc": "LC",
    "dc": "+1758"
  },
  {
    "name": {
      "en": "Dominica",
      "ar": "دومينيكا"
    },
    "flag": "🇩🇲",
    "cc": "DM",
    "dc": "+1767"
  },
  {
    "name": {
      "en": "Saint Vincent and the Grenadines",
      "ar": "سانت فينسنت والغرينادين"
    },
    "flag": "🇻🇨",
    "cc": "VC",
    "dc": "+1784"
  },
  {
    "name": {
      "en": "Trinidad and Tobago",
      "ar": "ترينيداد وتوباغو"
    },
    "flag": "🇹🇹",
    "cc": "TT",
    "dc": "+1868"
  },
  {
    "name": {
      "en": "Saint Kitts and Nevis",
      "ar": "سانت كيتس ونيفيس"
    },
    "flag": "🇰🇳",
    "cc": "KN",
    "dc": "+1869"
  },
  {
    "name": {
      "en": "Jamaica",
      "ar": "جامايكا"
    },
    "flag": "🇯🇲",
    "cc": "JM",
    "dc": "+1876"
  },
  {
    "name": {
      "en": "Western Sahara",
      "ar": "الصحراء الغربية"
    },
    "flag": "🇪🇭",
    "cc": "EH",
    "dc": "+2"
  },
  {
    "name": {
      "en": "Saint Helena, Ascension and Tristan da Cunha",
      "ar": "سانت هيلينا وأسينشين وتريستان دا كونا"
    },
    "flag": "🇸🇭",
    "cc": "SH",
    "dc": "+2"
  },
  {
    "name": {
      "en": "Egypt",
      "ar": "مصر"
    },
    "flag": "🇪🇬",
    "cc": "EG",
    "dc": "+20"
  },
  {
    "name": {
      "en": "South Sudan",
      "ar": "جنوب السودان"
    },
    "flag": "🇸🇸",
    "cc": "SS",
    "dc": "+211"
  },
  {
    "name": {
      "en": "Morocco",
      "ar": "المغرب"
    },
    "flag": "🇲🇦",
    "cc": "MA",
    "dc": "+212"
  },
  {
    "name": {
      "en": "Algeria",
      "ar": "الجزائر"
    },
    "flag": "🇩🇿",
    "cc": "DZ",
    "dc": "+213"
  },
  {
    "name": {
      "en": "Tunisia",
      "ar": "تونس"
    },
    "flag": "🇹🇳",
    "cc": "TN",
    "dc": "+216"
  },
  {
    "name": {
      "en": "Libya",
      "ar": "‏ليبيا"
    },
    "flag": "🇱🇾",
    "cc": "LY",
    "dc": "+218"
  },
  {
    "name": {
      "en": "Gambia",
      "ar": "غامبيا"
    },
    "flag": "🇬🇲",
    "cc": "GM",
    "dc": "+220"
  },
  {
    "name": {
      "en": "Senegal",
      "ar": "السنغال"
    },
    "flag": "🇸🇳",
    "cc": "SN",
    "dc": "+221"
  },
  {
    "name": {
      "en": "Mauritania",
      "ar": "موريتانيا"
    },
    "flag": "🇲🇷",
    "cc": "MR",
    "dc": "+222"
  },
  {
    "name": {
      "en": "Mali",
      "ar": "مالي"
    },
    "flag": "🇲🇱",
    "cc": "ML",
    "dc": "+223"
  },
  {
    "name": {
      "en": "Guinea",
      "ar": "غينيا"
    },
    "flag": "🇬🇳",
    "cc": "GN",
    "dc": "+224"
  },
  {
    "name": {
      "en": "Ivory Coast",
      "ar": "ساحل العاج"
    },
    "flag": "🇨🇮",
    "cc": "CI",
    "dc": "+225"
  },
  {
    "name": {
      "en": "Burkina Faso",
      "ar": "بوركينا فاسو"
    },
    "flag": "🇧🇫",
    "cc": "BF",
    "dc": "+226"
  },
  {
    "name": {
      "en": "Niger",
      "ar": "النيجر"
    },
    "flag": "🇳🇪",
    "cc": "NE",
    "dc": "+227"
  },
  {
    "name": {
      "en": "Togo",
      "ar": "توغو"
    },
    "flag": "🇹🇬",
    "cc": "TG",
    "dc": "+228"
  },
  {
    "name": {
      "en": "Benin",
      "ar": "بنين"
    },
    "flag": "🇧🇯",
    "cc": "BJ",
    "dc": "+229"
  },
  {
    "name": {
      "en": "Mauritius",
      "ar": "موريشيوس"
    },
    "flag": "🇲🇺",
    "cc": "MU",
    "dc": "+230"
  },
  {
    "name": {
      "en": "Liberia",
      "ar": "ليبيريا"
    },
    "flag": "🇱🇷",
    "cc": "LR",
    "dc": "+231"
  },
  {
    "name": {
      "en": "Sierra Leone",
      "ar": "سيراليون"
    },
    "flag": "🇸🇱",
    "cc": "SL",
    "dc": "+232"
  },
  {
    "name": {
      "en": "Ghana",
      "ar": "غانا"
    },
    "flag": "🇬🇭",
    "cc": "GH",
    "dc": "+233"
  },
  {
    "name": {
      "en": "Nigeria",
      "ar": "نيجيريا"
    },
    "flag": "🇳🇬",
    "cc": "NG",
    "dc": "+234"
  },
  {
    "name": {
      "en": "Chad",
      "ar": "تشاد"
    },
    "flag": "🇹🇩",
    "cc": "TD",
    "dc": "+235"
  },
  {
    "name": {
      "en": "Central African Republic",
      "ar": "جمهورية أفريقيا الوسطى"
    },
    "flag": "🇨🇫",
    "cc": "CF",
    "dc": "+236"
  },
  {
    "name": {
      "en": "Cameroon",
      "ar": "الكاميرون"
    },
    "flag": "🇨🇲",
    "cc": "CM",
    "dc": "+237"
  },
  {
    "name": {
      "en": "Cape Verde",
      "ar": "كابو فيردي"
    },
    "flag": "🇨🇻",
    "cc": "CV",
    "dc": "+238"
  },
  {
    "name": {
      "en": "São Tomé and Príncipe",
      "ar": "ساو تومي وبرينسيب"
    },
    "flag": "🇸🇹",
    "cc": "ST",
    "dc": "+239"
  },
  {
    "name": {
      "en": "Equatorial Guinea",
      "ar": "غينيا الاستوائية"
    },
    "flag": "🇬🇶",
    "cc": "GQ",
    "dc": "+240"
  },
  {
    "name": {
      "en": "Gabon",
      "ar": "الغابون"
    },
    "flag": "🇬🇦",
    "cc": "GA",
    "dc": "+241"
  },
  {
    "name": {
      "en": "Republic of the Congo",
      "ar": "جمهورية الكونفو"
    },
    "flag": "🇨🇬",
    "cc": "CG",
    "dc": "+242"
  },
  {
    "name": {
      "en": "DR Congo",
      "ar": "الكونغو"
    },
    "flag": "🇨🇩",
    "cc": "CD",
    "dc": "+243"
  },
  {
    "name": {
      "en": "Angola",
      "ar": "جمهورية أنغولا"
    },
    "flag": "🇦🇴",
    "cc": "AO",
    "dc": "+244"
  },
  {
    "name": {
      "en": "Guinea-Bissau",
      "ar": "غينيا بيساو"
    },
    "flag": "🇬🇼",
    "cc": "GW",
    "dc": "+245"
  },
  {
    "name": {
      "en": "British Indian Ocean Territory",
      "ar": "إقليم المحيط الهندي البريطاني"
    },
    "flag": "🇮🇴",
    "cc": "IO",
    "dc": "+246"
  },
  {
    "name": {
      "en": "Seychelles",
      "ar": "سيشل"
    },
    "flag": "🇸🇨",
    "cc": "SC",
    "dc": "+248"
  },
  {
    "name": {
      "en": "Sudan",
      "ar": "السودان"
    },
    "flag": "🇸🇩",
    "cc": "SD",
    "dc": "+249"
  },
  {
    "name": {
      "en": "Rwanda",
      "ar": "رواندا"
    },
    "flag": "🇷🇼",
    "cc": "RW",
    "dc": "+250"
  },
  {
    "name": {
      "en": "Ethiopia",
      "ar": "إثيوبيا"
    },
    "flag": "🇪🇹",
    "cc": "ET",
    "dc": "+251"
  },
  {
    "name": {
      "en": "Somalia",
      "ar": "الصومال"
    },
    "flag": "🇸🇴",
    "cc": "SO",
    "dc": "+252"
  },
  {
    "name": {
      "en": "Djibouti",
      "ar": "جيبوتي"
    },
    "flag": "🇩🇯",
    "cc": "DJ",
    "dc": "+253"
  },
  {
    "name": {
      "en": "Kenya",
      "ar": "كينيا"
    },
    "flag": "🇰🇪",
    "cc": "KE",
    "dc": "+254"
  },
  {
    "name": {
      "en": "Tanzania",
      "ar": "تنزانيا"
    },
    "flag": "🇹🇿",
    "cc": "TZ",
    "dc": "+255"
  },
  {
    "name": {
      "en": "Uganda",
      "ar": "أوغندا"
    },
    "flag": "🇺🇬",
    "cc": "UG",
    "dc": "+256"
  },
  {
    "name": {
      "en": "Burundi",
      "ar": "بوروندي"
    },
    "flag": "🇧🇮",
    "cc": "BI",
    "dc": "+257"
  },
  {
    "name": {
      "en": "Mozambique",
      "ar": "موزمبيق"
    },
    "flag": "🇲🇿",
    "cc": "MZ",
    "dc": "+258"
  },
  {
    "name": {
      "en": "Zambia",
      "ar": "زامبيا"
    },
    "flag": "🇿🇲",
    "cc": "ZM",
    "dc": "+260"
  },
  {
    "name": {
      "en": "Madagascar",
      "ar": "مدغشقر"
    },
    "flag": "🇲🇬",
    "cc": "MG",
    "dc": "+261"
  },
  {
    "name": {
      "en": "French Southern and Antarctic Lands",
      "ar": "أراض فرنسية جنوبية وأنتارتيكية"
    },
    "flag": "🇹🇫",
    "cc": "TF",
    "dc": "+262"
  },
  {
    "name": {
      "en": "Mayotte",
      "ar": "مايوت"
    },
    "flag": "🇾🇹",
    "cc": "YT",
    "dc": "+262"
  },
  {
    "name": {
      "en": "Réunion",
      "ar": "لا ريونيون"
    },
    "flag": "🇷🇪",
    "cc": "RE",
    "dc": "+262"
  },
  {
    "name": {
      "en": "Zimbabwe",
      "ar": "زيمبابوي"
    },
    "flag": "🇿🇼",
    "cc": "ZW",
    "dc": "+263"
  },
  {
    "name": {
      "en": "Namibia",
      "ar": "ناميبيا"
    },
    "flag": "🇳🇦",
    "cc": "NA",
    "dc": "+264"
  },
  {
    "name": {
      "en": "Malawi",
      "ar": "مالاوي"
    },
    "flag": "🇲🇼",
    "cc": "MW",
    "dc": "+265"
  },
  {
    "name": {
      "en": "Lesotho",
      "ar": "ليسوتو"
    },
    "flag": "🇱🇸",
    "cc": "LS",
    "dc": "+266"
  },
  {
    "name": {
      "en": "Botswana",
      "ar": "بوتسوانا"
    },
    "flag": "🇧🇼",
    "cc": "BW",
    "dc": "+267"
  },
  {
    "name": {
      "en": "Eswatini",
      "ar": "إسواتيني"
    },
    "flag": "🇸🇿",
    "cc": "SZ",
    "dc": "+268"
  },
  {
    "name": {
      "en": "United States Minor Outlying Islands",
      "ar": "جزر الولايات المتحدة الصغيرة النائية"
    },
    "flag": "🇺🇲",
    "cc": "UM",
    "dc": "+268"
  },
  {
    "name": {
      "en": "Comoros",
      "ar": "جزر القمر"
    },
    "flag": "🇰🇲",
    "cc": "KM",
    "dc": "+269"
  },
  {
    "name": {
      "en": "South Africa",
      "ar": "جنوب أفريقيا"
    },
    "flag": "🇿🇦",
    "cc": "ZA",
    "dc": "+27"
  },
  {
    "name": {
      "en": "Eritrea",
      "ar": "إريتريا"
    },
    "flag": "🇪🇷",
    "cc": "ER",
    "dc": "+291"
  },
  {
    "name": {
      "en": "Aruba",
      "ar": "أروبا"
    },
    "flag": "🇦🇼",
    "cc": "AW",
    "dc": "+297"
  },
  {
    "name": {
      "en": "Faroe Islands",
      "ar": "جزر فارو"
    },
    "flag": "🇫🇴",
    "cc": "FO",
    "dc": "+298"
  },
  {
    "name": {
      "en": "Greenland",
      "ar": "جرينلاند"
    },
    "flag": "🇬🇱",
    "cc": "GL",
    "dc": "+299"
  },
  {
    "name": {
      "en": "Vatican City",
      "ar": "مدينة الفاتيكان"
    },
    "flag": "🇻🇦",
    "cc": "VA",
    "dc": "+3"
  },
  {
    "name": {
      "en": "Greece",
      "ar": "اليونان"
    },
    "flag": "🇬🇷",
    "cc": "GR",
    "dc": "+30"
  },
  {
    "name": {
      "en": "Netherlands",
      "ar": "هولندا"
    },
    "flag": "🇳🇱",
    "cc": "NL",
    "dc": "+31"
  },
  {
    "name": {
      "en": "Belgium",
      "ar": "بلجيكا"
    },
    "flag": "🇧🇪",
    "cc": "BE",
    "dc": "+32"
  },
  {
    "name": {
      "en": "France",
      "ar": "فرنسا"
    },
    "flag": "🇫🇷",
    "cc": "FR",
    "dc": "+33"
  },
  {
    "name": {
      "en": "Spain",
      "ar": "إسبانيا"
    },
    "flag": "🇪🇸",
    "cc": "ES",
    "dc": "+34"
  },
  {
    "name": {
      "en": "Gibraltar",
      "ar": "جبل طارق"
    },
    "flag": "🇬🇮",
    "cc": "GI",
    "dc": "+350"
  },
  {
    "name": {
      "en": "Portugal",
      "ar": "البرتغال"
    },
    "flag": "🇵🇹",
    "cc": "PT",
    "dc": "+351"
  },
  {
    "name": {
      "en": "Luxembourg",
      "ar": "لوكسمبورغ"
    },
    "flag": "🇱🇺",
    "cc": "LU",
    "dc": "+352"
  },
  {
    "name": {
      "en": "Ireland",
      "ar": "أيرلندا"
    },
    "flag": "🇮🇪",
    "cc": "IE",
    "dc": "+353"
  },
  {
    "name": {
      "en": "Iceland",
      "ar": "آيسلندا"
    },
    "flag": "🇮🇸",
    "cc": "IS",
    "dc": "+354"
  },
  {
    "name": {
      "en": "Albania",
      "ar": "ألبانيا"
    },
    "flag": "🇦🇱",
    "cc": "AL",
    "dc": "+355"
  },
  {
    "name": {
      "en": "Malta",
      "ar": "مالطا"
    },
    "flag": "🇲🇹",
    "cc": "MT",
    "dc": "+356"
  },
  {
    "name": {
      "en": "Cyprus",
      "ar": "قبرص"
    },
    "flag": "🇨🇾",
    "cc": "CY",
    "dc": "+357"
  },
  {
    "name": {
      "en": "Finland",
      "ar": "فنلندا"
    },
    "flag": "🇫🇮",
    "cc": "FI",
    "dc": "+358"
  },
  {
    "name": {
      "en": "Åland Islands",
      "ar": "جزر أولاند"
    },
    "flag": "🇦🇽",
    "cc": "AX",
    "dc": "+35818"
  },
  {
    "name": {
      "en": "Bulgaria",
      "ar": "بلغاريا"
    },
    "flag": "🇧🇬",
    "cc": "BG",
    "dc": "+359"
  },
  {
    "name": {
      "en": "Hungary",
      "ar": "المجر"
    },
    "flag": "🇭🇺",
    "cc": "HU",
    "dc": "+36"
  },
  {
    "name": {
      "en": "Lithuania",
      "ar": "ليتوانيا"
    },
    "flag": "🇱🇹",
    "cc": "LT",
    "dc": "+370"
  },
  {
    "name": {
      "en": "Latvia",
      "ar": "لاتفيا"
    },
    "flag": "🇱🇻",
    "cc": "LV",
    "dc": "+371"
  },
  {
    "name": {
      "en": "Estonia",
      "ar": "إستونيا"
    },
    "flag": "🇪🇪",
    "cc": "EE",
    "dc": "+372"
  },
  {
    "name": {
      "en": "Moldova",
      "ar": "مولدوڤا"
    },
    "flag": "🇲🇩",
    "cc": "MD",
    "dc": "+373"
  },
  {
    "name": {
      "en": "Armenia",
      "ar": "أرمينيا"
    },
    "flag": "🇦🇲",
    "cc": "AM",
    "dc": "+374"
  },
  {
    "name": {
      "en": "Belarus",
      "ar": "بيلاروسيا"
    },
    "flag": "🇧🇾",
    "cc": "BY",
    "dc": "+375"
  },
  {
    "name": {
      "en": "Andorra",
      "ar": "أندورا"
    },
    "flag": "🇦🇩",
    "cc": "AD",
    "dc": "+376"
  },
  {
    "name": {
      "en": "Monaco",
      "ar": "موناكو"
    },
    "flag": "🇲🇨",
    "cc": "MC",
    "dc": "+377"
  },
  {
    "name": {
      "en": "San Marino",
      "ar": "سان مارينو"
    },
    "flag": "🇸🇲",
    "cc": "SM",
    "dc": "+378"
  },
  {
    "name": {
      "en": "Ukraine",
      "ar": "أوكرانيا"
    },
    "flag": "🇺🇦",
    "cc": "UA",
    "dc": "+380"
  },
  {
    "name": {
      "en": "Serbia",
      "ar": "صيربيا"
    },
    "flag": "🇷🇸",
    "cc": "RS",
    "dc": "+381"
  },
  {
    "name": {
      "en": "Montenegro",
      "ar": "الجبل الاسود"
    },
    "flag": "🇲🇪",
    "cc": "ME",
    "dc": "+382"
  },
  {
    "name": {
      "en": "Kosovo",
      "ar": "كوسوفو"
    },
    "flag": "🇽🇰",
    "cc": "XK",
    "dc": "+383"
  },
  {
    "name": {
      "en": "Croatia",
      "ar": "كرواتيا"
    },
    "flag": "🇭🇷",
    "cc": "HR",
    "dc": "+385"
  },
  {
    "name": {
      "en": "Slovenia",
      "ar": "سلوفينيا"
    },
    "flag": "🇸🇮",
    "cc": "SI",
    "dc": "+386"
  },
  {
    "name": {
      "en": "Bosnia and Herzegovina",
      "ar": "البوسنة والهرسك"
    },
    "flag": "🇧🇦",
    "cc": "BA",
    "dc": "+387"
  },
  {
    "name": {
      "en": "North Macedonia",
      "ar": "شمال مقدونيا"
    },
    "flag": "🇲🇰",
    "cc": "MK",
    "dc": "+389"
  },
  {
    "name": {
      "en": "Italy",
      "ar": "إيطاليا"
    },
    "flag": "🇮🇹",
    "cc": "IT",
    "dc": "+39"
  },
  {
    "name": {
      "en": "Romania",
      "ar": "رومانيا"
    },
    "flag": "🇷🇴",
    "cc": "RO",
    "dc": "+40"
  },
  {
    "name": {
      "en": "Switzerland",
      "ar": "سويسرا"
    },
    "flag": "🇨🇭",
    "cc": "CH",
    "dc": "+41"
  },
  {
    "name": {
      "en": "Czechia",
      "ar": "التشيك"
    },
    "flag": "🇨🇿",
    "cc": "CZ",
    "dc": "+420"
  },
  {
    "name": {
      "en": "Slovakia",
      "ar": "سلوفاكيا"
    },
    "flag": "🇸🇰",
    "cc": "SK",
    "dc": "+421"
  },
  {
    "name": {
      "en": "Liechtenstein",
      "ar": "ليختنشتاين"
    },
    "flag": "🇱🇮",
    "cc": "LI",
    "dc": "+423"
  },
  {
    "name": {
      "en": "Austria",
      "ar": "النمسا"
    },
    "flag": "🇦🇹",
    "cc": "AT",
    "dc": "+43"
  },
  {
    "name": {
      "en": "United Kingdom",
      "ar": "المملكة المتحدة"
    },
    "flag": "🇬🇧",
    "cc": "GB",
    "dc": "+44"
  },
  {
    "name": {
      "en": "Jersey",
      "ar": "جيرزي"
    },
    "flag": "🇯🇪",
    "cc": "JE",
    "dc": "+44"
  },
  {
    "name": {
      "en": "Isle of Man",
      "ar": "جزيرة مان"
    },
    "flag": "🇮🇲",
    "cc": "IM",
    "dc": "+44"
  },
  {
    "name": {
      "en": "Guernsey",
      "ar": "غيرنزي"
    },
    "flag": "🇬🇬",
    "cc": "GG",
    "dc": "+44"
  },
  {
    "name": {
      "en": "Denmark",
      "ar": "الدنمارك"
    },
    "flag": "🇩🇰",
    "cc": "DK",
    "dc": "+45"
  },
  {
    "name": {
      "en": "Sweden",
      "ar": "السويد"
    },
    "flag": "🇸🇪",
    "cc": "SE",
    "dc": "+46"
  },
  {
    "name": {
      "en": "Bouvet Island",
      "ar": "جزر بوفيه"
    },
    "flag": "🇧🇻",
    "cc": "BV",
    "dc": "+47"
  },
  {
    "name": {
      "en": "Norway",
      "ar": "النرويج"
    },
    "flag": "🇳🇴",
    "cc": "NO",
    "dc": "+47"
  },
  {
    "name": {
      "en": "Svalbard and Jan Mayen",
      "ar": "سفالبارد ويان ماين"
    },
    "flag": "🇸🇯",
    "cc": "SJ",
    "dc": "+4779"
  },
  {
    "name": {
      "en": "Poland",
      "ar": "بولندا"
    },
    "flag": "🇵🇱",
    "cc": "PL",
    "dc": "+48"
  },
  {
    "name": {
      "en": "Germany",
      "ar": "ألمانيا"
    },
    "flag": "🇩🇪",
    "cc": "DE",
    "dc": "+49"
  },
  {
    "name": {
      "en": "South Georgia",
      "ar": "جورجيا الجنوبية"
    },
    "flag": "🇬🇸",
    "cc": "GS",
    "dc": "+500"
  },
  {
    "name": {
      "en": "Falkland Islands",
      "ar": "جزر فوكلاند"
    },
    "flag": "🇫🇰",
    "cc": "FK",
    "dc": "+500"
  },
  {
    "name": {
      "en": "Belize",
      "ar": "بليز"
    },
    "flag": "🇧🇿",
    "cc": "BZ",
    "dc": "+501"
  },
  {
    "name": {
      "en": "Guatemala",
      "ar": "غواتيمالا"
    },
    "flag": "🇬🇹",
    "cc": "GT",
    "dc": "+502"
  },
  {
    "name": {
      "en": "El Salvador",
      "ar": "السلفادور"
    },
    "flag": "🇸🇻",
    "cc": "SV",
    "dc": "+503"
  },
  {
    "name": {
      "en": "Honduras",
      "ar": "هندوراس"
    },
    "flag": "🇭🇳",
    "cc": "HN",
    "dc": "+504"
  },
  {
    "name": {
      "en": "Nicaragua",
      "ar": "نيكاراغوا"
    },
    "flag": "🇳🇮",
    "cc": "NI",
    "dc": "+505"
  },
  {
    "name": {
      "en": "Costa Rica",
      "ar": "كوستاريكا"
    },
    "flag": "🇨🇷",
    "cc": "CR",
    "dc": "+506"
  },
  {
    "name": {
      "en": "Panama",
      "ar": "بنما"
    },
    "flag": "🇵🇦",
    "cc": "PA",
    "dc": "+507"
  },
  {
    "name": {
      "en": "Saint Pierre and Miquelon",
      "ar": "سان بيير وميكلون"
    },
    "flag": "🇵🇲",
    "cc": "PM",
    "dc": "+508"
  },
  {
    "name": {
      "en": "Haiti",
      "ar": "هايتي"
    },
    "flag": "🇭🇹",
    "cc": "HT",
    "dc": "+509"
  },
  {
    "name": {
      "en": "Peru",
      "ar": "بيرو"
    },
    "flag": "🇵🇪",
    "cc": "PE",
    "dc": "+51"
  },
  {
    "name": {
      "en": "Mexico",
      "ar": "المسكيك"
    },
    "flag": "🇲🇽",
    "cc": "MX",
    "dc": "+52"
  },
  {
    "name": {
      "en": "Cuba",
      "ar": "كوبا"
    },
    "flag": "🇨🇺",
    "cc": "CU",
    "dc": "+53"
  },
  {
    "name": {
      "en": "Argentina",
      "ar": "الأرجنتين"
    },
    "flag": "🇦🇷",
    "cc": "AR",
    "dc": "+54"
  },
  {
    "name": {
      "en": "Brazil",
      "ar": "البرازيل"
    },
    "flag": "🇧🇷",
    "cc": "BR",
    "dc": "+55"
  },
  {
    "name": {
      "en": "Chile",
      "ar": "تشيلي"
    },
    "flag": "🇨🇱",
    "cc": "CL",
    "dc": "+56"
  },
  {
    "name": {
      "en": "Colombia",
      "ar": "كولومبيا"
    },
    "flag": "🇨🇴",
    "cc": "CO",
    "dc": "+57"
  },
  {
    "name": {
      "en": "Venezuela",
      "ar": "فنزويلا"
    },
    "flag": "🇻🇪",
    "cc": "VE",
    "dc": "+58"
  },
  {
    "name": {
      "en": "Saint Martin",
      "ar": "سانت مارتن"
    },
    "flag": "🇲🇫",
    "cc": "MF",
    "dc": "+590"
  },
  {
    "name": {
      "en": "Saint Barthélemy",
      "ar": "سان بارتليمي"
    },
    "flag": "🇧🇱",
    "cc": "BL",
    "dc": "+590"
  },
  {
    "name": {
      "en": "Guadeloupe",
      "ar": "غوادلوب"
    },
    "flag": "🇬🇵",
    "cc": "GP",
    "dc": "+590"
  },
  {
    "name": {
      "en": "Bolivia",
      "ar": "بوليفيا"
    },
    "flag": "🇧🇴",
    "cc": "BO",
    "dc": "+591"
  },
  {
    "name": {
      "en": "Guyana",
      "ar": "غيانا"
    },
    "flag": "🇬🇾",
    "cc": "GY",
    "dc": "+592"
  },
  {
    "name": {
      "en": "Ecuador",
      "ar": "الإكوادور"
    },
    "flag": "🇪🇨",
    "cc": "EC",
    "dc": "+593"
  },
  {
    "name": {
      "en": "French Guiana",
      "ar": "غويانا"
    },
    "flag": "🇬🇫",
    "cc": "GF",
    "dc": "+594"
  },
  {
    "name": {
      "en": "Paraguay",
      "ar": "باراغواي"
    },
    "flag": "🇵🇾",
    "cc": "PY",
    "dc": "+595"
  },
  {
    "name": {
      "en": "Martinique",
      "ar": "مارتينيك"
    },
    "flag": "🇲🇶",
    "cc": "MQ",
    "dc": "+596"
  },
  {
    "name": {
      "en": "Suriname",
      "ar": "سورينام"
    },
    "flag": "🇸🇷",
    "cc": "SR",
    "dc": "+597"
  },
  {
    "name": {
      "en": "Uruguay",
      "ar": "الأوروغواي"
    },
    "flag": "🇺🇾",
    "cc": "UY",
    "dc": "+598"
  },
  {
    "name": {
      "en": "Caribbean Netherlands",
      "ar": "الجزر الكاريبية الهولندية"
    },
    "flag": "🇧🇶",
    "cc": "BQ",
    "dc": "+599"
  },
  {
    "name": {
      "en": "Curaçao",
      "ar": "كوراساو"
    },
    "flag": "🇨🇼",
    "cc": "CW",
    "dc": "+599"
  },
  {
    "name": {
      "en": "Malaysia",
      "ar": "ماليزيا"
    },
    "flag": "🇲🇾",
    "cc": "MY",
    "dc": "+60"
  },
  {
    "name": {
      "en": "Christmas Island",
      "ar": "جزيرة كريسماس"
    },
    "flag": "🇨🇽",
    "cc": "CX",
    "dc": "+61"
  },
  {
    "name": {
      "en": "Australia",
      "ar": "أستراليا"
    },
    "flag": "🇦🇺",
    "cc": "AU",
    "dc": "+61"
  },
  {
    "name": {
      "en": "Cocos (Keeling) Islands",
      "ar": "جزر كوكوس"
    },
    "flag": "🇨🇨",
    "cc": "CC",
    "dc": "+61"
  },
  {
    "name": {
      "en": "Indonesia",
      "ar": "إندونيسيا"
    },
    "flag": "🇮🇩",
    "cc": "ID",
    "dc": "+62"
  },
  {
    "name": {
      "en": "Philippines",
      "ar": "الفلبين"
    },
    "flag": "🇵🇭",
    "cc": "PH",
    "dc": "+63"
  },
  {
    "name": {
      "en": "New Zealand",
      "ar": "نيوزيلندا"
    },
    "flag": "🇳🇿",
    "cc": "NZ",
    "dc": "+64"
  },
  {
    "name": {
      "en": "Pitcairn Islands",
      "ar": "جزر بيتكيرن"
    },
    "flag": "🇵🇳",
    "cc": "PN",
    "dc": "+64"
  },
  {
    "name": {
      "en": "Singapore",
      "ar": "سنغافورة"
    },
    "flag": "🇸🇬",
    "cc": "SG",
    "dc": "+65"
  },
  {
    "name": {
      "en": "Thailand",
      "ar": "تايلند"
    },
    "flag": "🇹🇭",
    "cc": "TH",
    "dc": "+66"
  },
  {
    "name": {
      "en": "Timor-Leste",
      "ar": "تيمور الشرقية"
    },
    "flag": "🇹🇱",
    "cc": "TL",
    "dc": "+670"
  },
  {
    "name": {
      "en": "Norfolk Island",
      "ar": "جزيرة نورفولك"
    },
    "flag": "🇳🇫",
    "cc": "NF",
    "dc": "+672"
  },
  {
    "name": {
      "en": "Brunei",
      "ar": "بروناي"
    },
    "flag": "🇧🇳",
    "cc": "BN",
    "dc": "+673"
  },
  {
    "name": {
      "en": "Nauru",
      "ar": "ناورو"
    },
    "flag": "🇳🇷",
    "cc": "NR",
    "dc": "+674"
  },
  {
    "name": {
      "en": "Papua New Guinea",
      "ar": "بابوا غينيا الجديدة"
    },
    "flag": "🇵🇬",
    "cc": "PG",
    "dc": "+675"
  },
  {
    "name": {
      "en": "Tonga",
      "ar": "تونغا"
    },
    "flag": "🇹🇴",
    "cc": "TO",
    "dc": "+676"
  },
  {
    "name": {
      "en": "Solomon Islands",
      "ar": "جزر سليمان"
    },
    "flag": "🇸🇧",
    "cc": "SB",
    "dc": "+677"
  },
  {
    "name": {
      "en": "Vanuatu",
      "ar": "فانواتو"
    },
    "flag": "🇻🇺",
    "cc": "VU",
    "dc": "+678"
  },
  {
    "name": {
      "en": "Fiji",
      "ar": "فيجي"
    },
    "flag": "🇫🇯",
    "cc": "FJ",
    "dc": "+679"
  },
  {
    "name": {
      "en": "Palau",
      "ar": "بالاو"
    },
    "flag": "🇵🇼",
    "cc": "PW",
    "dc": "+680"
  },
  {
    "name": {
      "en": "Wallis and Futuna",
      "ar": "واليس وفوتونا"
    },
    "flag": "🇼🇫",
    "cc": "WF",
    "dc": "+681"
  },
  {
    "name": {
      "en": "Cook Islands",
      "ar": "جزر كوك"
    },
    "flag": "🇨🇰",
    "cc": "CK",
    "dc": "+682"
  },
  {
    "name": {
      "en": "Niue",
      "ar": "نييوي"
    },
    "flag": "🇳🇺",
    "cc": "NU",
    "dc": "+683"
  },
  {
    "name": {
      "en": "Samoa",
      "ar": "ساموا"
    },
    "flag": "🇼🇸",
    "cc": "WS",
    "dc": "+685"
  },
  {
    "name": {
      "en": "Kiribati",
      "ar": "كيريباتي"
    },
    "flag": "🇰🇮",
    "cc": "KI",
    "dc": "+686"
  },
  {
    "name": {
      "en": "New Caledonia",
      "ar": "كاليدونيا الجديدة"
    },
    "flag": "🇳🇨",
    "cc": "NC",
    "dc": "+687"
  },
  {
    "name": {
      "en": "Tuvalu",
      "ar": "توفالو"
    },
    "flag": "🇹🇻",
    "cc": "TV",
    "dc": "+688"
  },
  {
    "name": {
      "en": "French Polynesia",
      "ar": "بولينزيا الفرنسية"
    },
    "flag": "🇵🇫",
    "cc": "PF",
    "dc": "+689"
  },
  {
    "name": {
      "en": "Tokelau",
      "ar": "توكيلاو"
    },
    "flag": "🇹🇰",
    "cc": "TK",
    "dc": "+690"
  },
  {
    "name": {
      "en": "Micronesia",
      "ar": "ميكرونيسيا"
    },
    "flag": "🇫🇲",
    "cc": "FM",
    "dc": "+691"
  },
  {
    "name": {
      "en": "Marshall Islands",
      "ar": "جزر مارشال"
    },
    "flag": "🇲🇭",
    "cc": "MH",
    "dc": "+692"
  },
  {
    "name": {
      "en": "Russia",
      "ar": "روسيا"
    },
    "flag": "🇷🇺",
    "cc": "RU",
    "dc": "+7"
  },
  {
    "name": {
      "en": "Kazakhstan",
      "ar": "كازاخستان"
    },
    "flag": "🇰🇿",
    "cc": "KZ",
    "dc": "+7"
  },
  {
    "name": {
      "en": "Japan",
      "ar": "اليابان"
    },
    "flag": "🇯🇵",
    "cc": "JP",
    "dc": "+81"
  },
  {
    "name": {
      "en": "South Korea",
      "ar": "كوريا الجنوبية"
    },
    "flag": "🇰🇷",
    "cc": "KR",
    "dc": "+82"
  },
  {
    "name": {
      "en": "Vietnam",
      "ar": "فيتنام"
    },
    "flag": "🇻🇳",
    "cc": "VN",
    "dc": "+84"
  },
  {
    "name": {
      "en": "North Korea",
      "ar": "كوريا الشمالية"
    },
    "flag": "🇰🇵",
    "cc": "KP",
    "dc": "+850"
  },
  {
    "name": {
      "en": "Hong Kong",
      "ar": "هونغ كونغ"
    },
    "flag": "🇭🇰",
    "cc": "HK",
    "dc": "+852"
  },
  {
    "name": {
      "en": "Macau",
      "ar": "ماكاو"
    },
    "flag": "🇲🇴",
    "cc": "MO",
    "dc": "+853"
  },
  {
    "name": {
      "en": "Cambodia",
      "ar": "كمبوديا"
    },
    "flag": "🇰🇭",
    "cc": "KH",
    "dc": "+855"
  },
  {
    "name": {
      "en": "Laos",
      "ar": "لاوس"
    },
    "flag": "🇱🇦",
    "cc": "LA",
    "dc": "+856"
  },
  {
    "name": {
      "en": "China",
      "ar": "الصين"
    },
    "flag": "🇨🇳",
    "cc": "CN",
    "dc": "+86"
  },
  {
    "name": {
      "en": "Bangladesh",
      "ar": "بنغلاديش"
    },
    "flag": "🇧🇩",
    "cc": "BD",
    "dc": "+880"
  },
  {
    "name": {
      "en": "Taiwan",
      "ar": "تايوان"
    },
    "flag": "🇹🇼",
    "cc": "TW",
    "dc": "+886"
  },
  {
    "name": {
      "en": "Turkey",
      "ar": "تركيا"
    },
    "flag": "🇹🇷",
    "cc": "TR",
    "dc": "+90"
  },
  {
    "name": {
      "en": "India",
      "ar": "الهند"
    },
    "flag": "🇮🇳",
    "cc": "IN",
    "dc": "+91"
  },
  {
    "name": {
      "en": "Pakistan",
      "ar": "باكستان"
    },
    "flag": "🇵🇰",
    "cc": "PK",
    "dc": "+92"
  },
  {
    "name": {
      "en": "Afghanistan",
      "ar": "أفغانستان"
    },
    "flag": "🇦🇫",
    "cc": "AF",
    "dc": "+93"
  },
  {
    "name": {
      "en": "Sri Lanka",
      "ar": "سريلانكا"
    },
    "flag": "🇱🇰",
    "cc": "LK",
    "dc": "+94"
  },
  {
    "name": {
      "en": "Myanmar",
      "ar": "ميانمار"
    },
    "flag": "🇲🇲",
    "cc": "MM",
    "dc": "+95"
  },
  {
    "name": {
      "en": "Maldives",
      "ar": "المالديف"
    },
    "flag": "🇲🇻",
    "cc": "MV",
    "dc": "+960"
  },
  {
    "name": {
      "en": "Lebanon",
      "ar": "لبنان"
    },
    "flag": "🇱🇧",
    "cc": "LB",
    "dc": "+961"
  },
  {
    "name": {
      "en": "Jordan",
      "ar": "الأردن"
    },
    "flag": "🇯🇴",
    "cc": "JO",
    "dc": "+962"
  },
  {
    "name": {
      "en": "Syria",
      "ar": "سوريا"
    },
    "flag": "🇸🇾",
    "cc": "SY",
    "dc": "+963"
  },
  {
    "name": {
      "en": "Iraq",
      "ar": "العراق"
    },
    "flag": "🇮🇶",
    "cc": "IQ",
    "dc": "+964"
  },
  {
    "name": {
      "en": "Yemen",
      "ar": "اليمن"
    },
    "flag": "🇾🇪",
    "cc": "YE",
    "dc": "+967"
  },
  {
    "name": {
      "en": "Palestine",
      "ar": "فلسطين"
    },
    "flag": "🇵🇸",
    "cc": "PS",
    "dc": "+970"
  },
  {
    "name": {
      "en": "Israel",
      "ar": "إسرائيل"
    },
    "flag": "🇮🇱",
    "cc": "IL",
    "dc": "+972"
  },
  {
    "name": {
      "en": "Bhutan",
      "ar": "بوتان"
    },
    "flag": "🇧🇹",
    "cc": "BT",
    "dc": "+975"
  },
  {
    "name": {
      "en": "Mongolia",
      "ar": "منغوليا"
    },
    "flag": "🇲🇳",
    "cc": "MN",
    "dc": "+976"
  },
  {
    "name": {
      "en": "Nepal",
      "ar": "نيبال"
    },
    "flag": "🇳🇵",
    "cc": "NP",
    "dc": "+977"
  },
  {
    "name": {
      "en": "Iran",
      "ar": "إيران"
    },
    "flag": "🇮🇷",
    "cc": "IR",
    "dc": "+98"
  },
  {
    "name": {
      "en": "Tajikistan",
      "ar": "طاجيكستان"
    },
    "flag": "🇹🇯",
    "cc": "TJ",
    "dc": "+992"
  },
  {
    "name": {
      "en": "Turkmenistan",
      "ar": "تركمانستان"
    },
    "flag": "🇹🇲",
    "cc": "TM",
    "dc": "+993"
  },
  {
    "name": {
      "en": "Azerbaijan",
      "ar": "أذربيجان"
    },
    "flag": "🇦🇿",
    "cc": "AZ",
    "dc": "+994"
  },
  {
    "name": {
      "en": "Georgia",
      "ar": "جورجيا"
    },
    "flag": "🇬🇪",
    "cc": "GE",
    "dc": "+995"
  },
  {
    "name": {
      "en": "Kyrgyzstan",
      "ar": "قيرغيزستان"
    },
    "flag": "🇰🇬",
    "cc": "KG",
    "dc": "+996"
  },
  {
    "name": {
      "en": "Uzbekistan",
      "ar": "أوزباكستان"
    },
    "flag": "🇺🇿",
    "cc": "UZ",
    "dc": "+998"
  }
]

export function getCountryByLanguage(lang) {
  const countryObj = {};
  DIALING_CODES.forEach(country => {
    if (lang === 'ar') {
      countryObj[country.cc.toLowerCase()] = country.name.ar;
    } else {
      countryObj[country.cc.toLowerCase()] = country.name.en;
    }
  });
  return countryObj;
}
