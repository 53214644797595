export const EmployeesListSerializer = data => {
  const serialized = [];
  data.forEach(el => {
    const output = {
      api_employee_id: el.api_employee_id,
      employee_id: el.employee_id,
      first_name: el.info.user.first_name,
      last_name: el.info.user.last_name,
      email: el.info.user.email,
      is_active: el.info.user.is_active,
    };
    if (el.role) {
      output.role = el.role.display_name;
      output.permissions = el.role.permissions.map(perm => perm.permission);
    }
    serialized.push(output);
  });
  return serialized;
};

export default EmployeesListSerializer;
