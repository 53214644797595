/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dropdown from 'spotii-ui/Dropdown';
import Button from 'spotii-ui/Button';
import message from 'spotii-ui/Message';
import {
  patchDraftPlan,
  confirmQrOrder,
  generateUniqueCheckoutReference,
  sendDraftCheckoutOTP,
} from 'api';
import {
  DRAFT_STATUS_CANCELLED,
  DRAFT_STATUS_COMPLETED,
  DRAFT_STATUS_PENDING_MERCHANT
} from 'utils/constants';
import CustomReferenceModal from './CustomReferenceModal';
import OTPModal from './OTPModal';
import {
  fetchOrder
} from "../../reducers/orders";
import {
  printReceipt
} from "../../utils/printReceipt";

const DraftActions = ({ draft, reload, appState, currLang }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { merchantId } = useSelector(state => state.application.currentUser.merchant);
  const [showCustomReferenceModal, setShowCustomReferenceModal] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleReceiptPrinting = (order) => () => {
    dispatch(fetchOrder({
        orderId: order.order ,
        callback: printReceipt
      })
    );
  }

  const handleGenerateDisplayRef = async () => {
    const res = await generateUniqueCheckoutReference(merchantId);
    return res.data.unique_ref.toUpperCase();
  };

  const handleDeclineDraft = async () => {
    setIsLoading(true);
    try {
      await patchDraftPlan(draft.draft_id, { status: DRAFT_STATUS_CANCELLED });
      setIsLoading(false);
      message.success(t('orderDeclined'));
      if (reload) {
        reload();
      }
    } catch (e) {
      console.warn(e);
      message.error(t('somethingWentWrong'));
      setIsLoading(false);
    }
  };

  const sendDraftOTP = async () => {
    try {
      setShowOTPModal(true)
      await sendDraftCheckoutOTP(draft.draft_id)
    } catch (err) {
      console.log({ err });
      message.error(t('errorSendOTP'));
    }
  }

  const resendDraftOTP = async () => {
    try {
      await sendDraftCheckoutOTP(draft.draft_id)
    } catch (err) {
      console.log({ err });
      message.error(t('errorSendOTP'));
    }
  }

  const approveDraftByOTP = async (otp) => {
    setIsLoading(true);
    try {
      await confirmQrOrder(
        draft.checkout,
        draft.plan,
        draft.payment_method_id,
        draft.consumer,
        draft.draft_id,
        undefined,
        undefined,
        otp,
      );

      // await patchDraftPlan(draft.draft_id, { status: DRAFT_STATUS_COMPLETED });
      setIsLoading(false);
      message.success(t('requestAmtApproved'));
      if (reload) {
        reload();
      }
    } catch (e) {
      if (e.errors && e.errors.code === 'payment_request_otp_mismatch') {
        message.error(t('otpMismatch'));
      } else if (e.errors && e.errors.code === 'payment_request_otp_expired') {
        message.error(t('otpExpired'));
      } else if (e.errors && e.errors.code === 'checkout_already_exists') {
        message.error(t('paymentRequestDuplicateRefErr'));
      } else if (e.errors && e.errors.code === 'payment_request_expired') {
        message.error(t('requestExpiredOrCancelled'));
        reload();
      } else if (e.errors.code === 'order_approval') {
        message.error(
          <span>
            {`${t('paymentRequestOrderApprovalException')} `}
            <a href="mailto:">hello@spendwisor.app</a>
          </span>,
        );
        reload();
      } else {
        message.error(t('somethingWentWrong'));
      }

      setIsLoading(false);
      throw e
    }
  };

  const approveDraft = async (customReference = 'NONE') => {
    setIsLoading(true);
    const uniqueRef = await handleGenerateDisplayRef();
    const checkoutData = {
      reference: uniqueRef,
      display_reference: uniqueRef,
      description: `Order #${draft.draft_id} Description:${draft.description}`,
      total: draft.amount,
      currency: draft.currency,
      confirm_callback_url: '',
      reject_callback_url: '',
      order: {
        employee_id: appState.loggedEmployee.employee_id,
        tax_amount: null,
        shipping_amount: null,
        discount: null,
        customer: {
          // keep email empty to avoid redundant email notification to customer
          email: '',
        },
        billing_address: {},
        shipping_address: {},
      },
    };
    let res = null;
    try {
      res = await confirmQrOrder(
        draft.checkout,
        draft.plan,
        draft.payment_method_id,
        draft.consumer,
        draft.draft_id,
        customReference,
      );

      // await patchDraftPlan(draft.draft_id, { status: DRAFT_STATUS_COMPLETED });
      setIsLoading(false);
      message.success(t('requestAmtApproved'));
      if (reload) {
        reload();
      }
    } catch (e) {
      if (e.errors && e.errors.code === 'checkout_already_exists') {
        message.error(t('paymentRequestDuplicateRefErr'));
      } else if (e.errors && e.errors.code === 'payment_request_expired') {
        message.error(t('requestExpiredOrCancelled'));
        reload();
      } else if (e.errors.code === 'order_approval') {
        message.error(
          <span>
            {`${t('paymentRequestOrderApprovalException')} `}
            <a href="mailto:">hello@spendwisor.app</a>
          </span>,
        );
        reload();
      } else {
        message.error(t('somethingWentWrong'));
      }

      setIsLoading(false);
    }
  };

  const handleApproveDraft = () => {
    if (appState.isInstoreCustomReferenceEnabled || appState.isInstoreCommentsEnabled) {
      setShowCustomReferenceModal(true);
    } else if (appState.isStaticQrOrderOtpVerificationEnabled) {
      sendDraftOTP();
    } else {
      approveDraft();
    }
  };

  return (
    <>
      <Dropdown alignLeft={currLang === 'ar'} alignRight={currLang !== 'ar'}>
        <Dropdown.Toggle as={Dropdown.CustomToggle}>
          <Button
            type="transparent"
            shape="square"
            size="small"
            icon={isLoading ? 'loading' : 'ellipsis'}
            disabled={isLoading}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {draft.status === DRAFT_STATUS_PENDING_MERCHANT &&
            <Dropdown.Item
              style={{ display: 'flex' }}
              eventKey="1"
              as="button"
              onClick={handleDeclineDraft}
            >
              {t('decline')}
            </Dropdown.Item>
          }
          {draft.status === DRAFT_STATUS_PENDING_MERCHANT &&
            <Dropdown.Item
              style={{display: 'flex'}}
              eventKey="2"
              as="button"
              onClick={handleApproveDraft}
            >
              {t('approve')}
            </Dropdown.Item>}
          {
            draft.status === DRAFT_STATUS_COMPLETED &&
            <Dropdown.Item
              style={{ display: 'flex' }}
              eventKey="2"
              as="button"
              onClick={handleReceiptPrinting(draft)}
            >
              {t('printReceipt')}
            </Dropdown.Item>
          }
             </Dropdown.Menu>
        </Dropdown>
      <CustomReferenceModal
        show={showCustomReferenceModal}
        setShowCustomReferenceModal={setShowCustomReferenceModal}
        approveDraft={approveDraft}
      />
      <OTPModal
        show={showOTPModal}
        setShowOTPModal={setShowOTPModal}
        approveDraft={approveDraftByOTP}
        resendOTP={resendDraftOTP}
      />
    </>
  );
};

DraftActions.propTypes = {
  order: PropTypes.shape({
    status: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  reload: PropTypes.func,
};

DraftActions.defaultProps = {
  reload: null,
};

export default DraftActions;
