/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Switch, Redirect, Route, useRouteMatch } from 'react-router-dom';
import DashboardHeader from 'components/DashboardHeader';
import RouteBinded from 'components/RouteBinded';
import { AppStateType } from 'reducers/application';

import './checkout.scss';

// Sub-routes
import Checkouts from './Checkouts';
import CreateOrder from './AddNewOrderForm';
import OrderRequests from './OrderRequests';

const Checkout = ({ appState }) => {
  const { t } = useTranslation();
  const { isInstore } = appState;

  const currLang = useSelector(state => state.language.language);

  const { path } = useRouteMatch();

  const getNavItems = () => {
    const items = [
      { pathname: '/checkout/checkouts', title: `${t('viewAll')}` },
      {
        className: `dashboard-header-nav__link navItemReversed-${currLang}`,
        pathname: '/checkout/createOrder',
        title: `${t('createNewLink')}`,
        icon: 'new',
      },
    ];

    if (isInstore) {
      items.splice(1, 0, { pathname: '/checkout/orderRequests', title: `${t('paymentRequests')}` });
    }

    return items;
  };

  return (
    <div className="checkout__container">
      <DashboardHeader title={t('paymentLinks')} navItems={getNavItems()} appState={appState} />
      <Switch>
        <RouteBinded path={`${path}/checkouts/:page?`} component={Checkouts} appState={appState} />
        <RouteBinded
          path={`${path}/orderRequests/:page?`}
          component={OrderRequests}
          appState={appState}
        />
        <RouteBinded path={`${path}/createOrder`} component={CreateOrder} appState={appState} />
        <Route path={`${path}`} exact render={() => <Redirect to={`${path}/checkouts`} />} />
      </Switch>
    </div>
  );
};

Checkout.propTypes = {
  appState: PropTypes.shape(AppStateType).isRequired,
};

export default Checkout;
