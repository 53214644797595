import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Switch, Redirect, Route, useRouteMatch } from 'react-router-dom';
import RouteBinded from 'components/RouteBinded';
import DashboardHeader from 'components/DashboardHeader';
import { AppStateType } from 'reducers/application';

import './Settings.scss';

// Sub-routes
import Company from './Company';
import Integrations from './Integrations';
import BankAccount from './BankAccount';
import AddBankAccount from './AddBankAccount';
import Profile from './Profile';
import Shopify from './Shopify';

const Settings = ({ appState }) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const userHasRole = appState.loggedEmployee.role;
  let userHasSettingsPerm = true;
  if (userHasRole) {
    const userPermissions = appState.loggedEmployee.role.permissions;
    userHasSettingsPerm = userPermissions.includes('settings_read');
  }
  const navItems = [{ pathname: '/settings/personal', title: t('personal') }];
  if (userHasSettingsPerm) {
    navItems.push(
      ...[
        { pathname: '/settings/company', title: t('company') },
        { pathname: '/settings/bank-accounts', title: t('bankAccounts') },
        { pathname: '/settings/integrations', title: t('integrations') },
      ],
    );
  }

  return (
    <div className="settings__container">
      <DashboardHeader title={t('settings')} navItems={navItems} appState={appState} />
      <Switch>
        <RouteBinded path={`${path}/company`} exact component={Company} appState={appState} />
        <RouteBinded
          path={`${path}/integrations`}
          exact
          component={Integrations}
          appState={appState}
        />
        <RouteBinded
          path={`${path}/integrations/shopify`}
          exact
          component={Shopify}
          appState={appState}
        />
        <RouteBinded path={`${path}/personal`} exact component={Profile} appState={appState} />
        <RouteBinded
          path={`${path}/bank-accounts`}
          exact
          component={BankAccount}
          appState={appState}
        />
        {/* <RouteBinded
          path={`${path}/add-bank-account`}
          exact
          component={AddBankAccount}
          appState={appState}
        /> */}
        <Route path={`${path}`} exact render={() => <Redirect to={`${path}/company`} />} />
      </Switch>
    </div>
  );
};

Settings.propTypes = {
  appState: PropTypes.shape(AppStateType).isRequired,
};

export default Settings;
