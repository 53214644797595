import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import b from 'b_';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Paper from 'components/Paper';
import Icon from 'spotii-ui/Icon';
import DashboardHeader from 'components/DashboardHeader';
import CreateRoleForm from 'components/CreateRoleForm';
import { propTypes } from 'cleave.js/react';

const AddRole = ({ appState }) => {
  const cls = b.with('add-bank-account');
  const [success, setSuccess] = useState(false);
  const currLang = useSelector(state => state.language.language);
  const { t } = useTranslation();

  if (success) {
    return <Redirect to="/admin/roles" push />;
  }

  return (
    <>
      <DashboardHeader title="Create a role" appState={appState} />
      <Paper>
        <Row className={cls()}>
          <Col md="7">
            <p style={{ marginBottom: '35px' }}>
              <Link to="/admin/roles">
                {currLang === 'ar' ? t('back') : ''}
                <Icon type="chevron-left" />
                {currLang !== 'ar' ? t('back') : ''}
              </Link>
            </p>
            <CreateRoleForm
              appState={appState}
              onSuccess={() => {
                setSuccess(true);
              }}
            />
          </Col>
        </Row>
      </Paper>
    </>
  );
};
AddRole.propTypes = {
  appState: PropTypes.objectOf(PropTypes.object).isRequired,
};
export default AddRole;
