import snakeToCamel from 'utils/snakeToCamel';

/* {
    "bank_account_id": "",
    "bank": "",
    "account_number": "",
    "iban": "",
    "swift": "",
    "country": null,
    "is_default": null
} */
export const bankAccountSerializer = payload => {
  const fields = [
    'bank_account_id',
    'bank',
    'account_number',
    'account_title',
    'iban',
    'swift',
    'country',
    'is_default',
    'approved_at',
  ];
  return fields.reduce(
    (result, key) => ({
      ...result,
      [snakeToCamel(key)]: payload[key],
    }),
    {},
  );
};

export const bankAccountsListSerializer = payload => {
  if (Array.isArray(payload)) {
    return payload.map(item => bankAccountSerializer(item));
  }

  console.warn('Payload for bank accounts should be a list.');
  return [];
};

export default bankAccountsListSerializer;
