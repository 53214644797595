import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { checkUniqueCheckoutReference } from 'api';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import Heading from 'spotii-ui/Heading';
import Button from 'spotii-ui/Button';
import Form, { InputRow } from 'spotii-ui/Form';

const EnterCustomReference = ({ show, setShowCustomReferenceModal, approveDraft }) => {
  const { t } = useTranslation();
  const currLang = useSelector(state => state.language.language);
  const { merchantId } = useSelector(state => state.application.currentUser.merchant);
  const { isInstoreCommentsEnabled } = useSelector(state => state.application);

  const formik = useFormik({
    initialValues: {
      reference: '',
    },
    validationSchema: Yup.object().shape({
      reference: isInstoreCommentsEnabled
        ? Yup.string().matches(/^[A-Za-z0-9_]*$/, {
            message: t('enterValidComment'),
            excludeEmptyString: true,
          })
        : Yup.string()
            .matches(/^\S*$/, { message: t('orderRefCannotHaveSpaces'), excludeEmptyString: true })
            .required(t('orderReferenceIsRequired')),
    }),
    onSubmit: async ({ reference }, actions) => {
      try {
        const response = await checkUniqueCheckoutReference(
          reference.replace(/ /g, '_'),
          merchantId,
        );
        if (response.data.exists === 'true') {
          actions.setErrors({ reference: t('paymentReferenceAlreadyExists') });
        } else {
          // eslint-disable-next-line no-use-before-define
          handleSuccess(reference);
        }
      } catch (e) {
        console.error('error', e);
      }
    },
  });

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    submitCount,
    submitForm,
  } = formik;

  const handleClose = () => {
    setShowCustomReferenceModal(false);
    formik.resetForm();
  };

  const handleSuccess = reference => {
    approveDraft(reference.replace(/ /g, '_'));
    setShowCustomReferenceModal(false);
    formik.resetForm();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body>
        <Heading h3>
          <p style={{ textAlign: `${currLang === 'ar' ? 'right' : 'left'}` }}>
            {isInstoreCommentsEnabled ? t('enterOrderComments') : t('enterOrderReference')}
          </p>
        </Heading>
        <p
          style={{ textAlign: `${currLang === 'ar' ? 'right' : 'left'}` }}
          className="color-black40 mb-40"
        >
          {isInstoreCommentsEnabled ? t('enterOrderCommentsDesc') : t('enterOrderReferenceDesc')}
        </p>

        <Form onSubmit={handleSubmit}>
          <InputRow
            label={isInstoreCommentsEnabled ? t('comments') : t('orderReference')}
            name="reference"
            type="text"
            value={values.reference}
            onChange={handleChange}
            onBlur={handleBlur}
            error={submitCount && errors.reference && touched.reference ? errors.reference : null}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="primary" loading={isSubmitting} disabled={isSubmitting} onClick={submitForm}>
          {t('submit')}
        </Button>
        <Button type="default" onClick={handleClose} disabled={isSubmitting}>
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

EnterCustomReference.propTypes = {
  setShowCustomReferenceModal: PropTypes.bool.isRequired,
  show: PropTypes.func.isRequired,
  approveDraft: PropTypes.func,
};

EnterCustomReference.defaultProps = {
  approveDraft: null,
};

export default EnterCustomReference;
