/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import i18next from '../i18n';

const languageInitialState = {
  isLanguageSet: false,
  language: 'en',
};

const language = createSlice({
  name: 'language',
  initialState: languageInitialState,
  reducers: {
    changeLanguage(state, { payload }) {
      const { lang } = payload;
      i18next.changeLanguage(lang);
      document.getElementsByTagName('html')[0].setAttribute('lang', lang);
      document.getElementsByTagName('body')[0].style.direction = lang === 'ar' ? 'rtl' : 'ltr';
      localStorage.setItem('lang', lang);
      return {
        ...state,
        language: lang,
        isLanguageSet: true,
      };
    },
  },
});

export default language.reducer;

export const { changeLanguage } = language.actions;
