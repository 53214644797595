/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React from 'react';

import './PermSwitch.scss';

function PermSwitch({ uniqueKey, handleToggle, activated }) {
  return (
    <span key={`${uniqueKey}_switch`}>
      <input
        className="react-switch-checkbox"
        id={uniqueKey}
        name={uniqueKey}
        type="checkbox"
        checked={activated}
        onChange={e => {
          handleToggle(uniqueKey, e.target.checked);
        }}
      />
      <label className="react-switch-label" htmlFor={uniqueKey}>
        <span className="react-switch-button" />
      </label>
    </span>
  );
}
PermSwitch.propTypes = {
  activated: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  uniqueKey: PropTypes.string.isRequired,
};
export default PermSwitch;
