import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import PermSwitch from 'components/PermSwitch';

const ActionRow = ({ uniqueKey, action, handleToggle, activated }) => {
  return (
    <Row key={uniqueKey} className="actionRow">
      <Col md={{ span: 10, offset: 1 }} className="actionCol">
        {action.key}
        <PermSwitch uniqueKey={uniqueKey} activated={activated} handleToggle={handleToggle} />
      </Col>
    </Row>
  );
};
ActionRow.propTypes = {
  action: PropTypes.objectOf(PropTypes.string).isRequired,
  activated: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  uniqueKey: PropTypes.string.isRequired,
};

const RolePermissions = ({ list, getPermissionsState, grantedPermissions }) => {
  let permissionsArray = list.map(el => {
    return el.actions.map(action => {
      return `${el.name.toLowerCase()}_${action.value}`;
    });
  });
  permissionsArray = [].concat([], ...permissionsArray);
  // eslint-disable-next-line no-param-reassign
  const permissionsObject = permissionsArray.reduce((a, b) => ((a[b] = false), a), {});
  if (grantedPermissions) {
    grantedPermissions.forEach(perm => {
      permissionsObject[perm] = true;
    });
  }
  const [permissions, setPermissions] = React.useState(permissionsObject);

  const handleToggle = (key, val) => {
    setPermissions(previousState => ({
      ...previousState,
      [key]: val,
    }));
  };

  React.useEffect(() => {
    getPermissionsState(permissions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);
  return (
    <>
      {list.map(el => {
        const permRows = el.actions.map(action => {
          const uniqueKey = `${el.name.toLowerCase()}_${action.value}`;
          const activated = permissions[uniqueKey];
          return (
            <ActionRow
              key={`${uniqueKey}_row`}
              uniqueKey={uniqueKey}
              action={action}
              handleToggle={handleToggle}
              activated={activated}
            />
          );
        });
        return (
          <span key={el.name}>
            <Row className="resourceRow">
              <Col md={12}>{el.name}</Col>
            </Row>
            {permRows}
          </span>
        );
      })}
    </>
  );
};
RolePermissions.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  getPermissionsState: PropTypes.func,
  grantedPermissions: PropTypes.arrayOf(PropTypes.string),
};
RolePermissions.defaultProps = {
  getPermissionsState: null,
  grantedPermissions: null,
};
export default RolePermissions;
