/* eslint-disable react/display-name */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TableView from 'components/TableView/TableView';
import Pagination from 'components/Pagination';
import { fetchRoles, reset, changePage } from 'reducers/roles';

import 'bootstrap/dist/css/bootstrap.min.css';

// Columns render options
const columns = [
  {
    title: 'Role name',
    key: 'displayName',
    render: value => (
      <Link key={value.roleId} to={`/admin/role/${value.roleId}`}>
        <span>{value.displayName}</span>
      </Link>
    ),
  },
];
//
// Component
//
const RoleList = ({ appState }) => {
  const dispatch = useDispatch();
  const { isLoading, isResolved, list, pageCount, currentPage } = useSelector(state => state.roles);

  // Add Actions column to the table view
  const columnsExtended = columns.slice();
  const page = Math.max(currentPage, 1) - 1;
  const fetchParams = {
    limit: 10,
    merchantId: appState.currentUser.merchant.merchantId,
    page: currentPage,
  };
  const onPageChange = p => dispatch(changePage(p.selected + 1));

  // Reset reducer on page view
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(reset()), []);

  useEffect(() => {
    dispatch(fetchRoles(fetchParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);
  return (
    <div>
      <TableView columns={columnsExtended} data={list} loading={isLoading} rowKey="roleId" />
      {isResolved && list.length ? (
        <div>
          <Pagination current={page} count={pageCount} onChange={onPageChange} />
        </div>
      ) : null}
    </div>
  );
};
RoleList.propTypes = {
  appState: PropTypes.objectOf(PropTypes.object).isRequired,
};
export default RoleList;
