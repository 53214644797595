import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { AppStateType } from 'reducers/application';

const RoutePrivate = ({ appState, component: C, ...rest }) => {
  const { isAuthenticated } = appState;
  const { pathname, search } = useLocation();

  if (isAuthenticated) {
    return <Route {...rest} render={props => <C {...props} appState={appState} />} />;
  }

  return <Redirect to={`/signin?continue=${pathname}${search}`} />;
};

RoutePrivate.propTypes = {
  appState: PropTypes.shape(AppStateType).isRequired,
  component: PropTypes.elementType.isRequired,
};

export default RoutePrivate;
