import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './LabelValue.scss';

const LabelValue = ({ label, children }) => (
  <div className={b('label-value')}>
    <div className={b('label-value', 'label')}>{label}</div>
    <div className={b('label-value', 'value')}>{children}</div>
  </div>
);

LabelValue.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default LabelValue;
