import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Heading from 'spotii-ui/Heading';
import Paper from 'auth/Paper';
import BlockHead from 'auth/BlockHead';
import OtpForm, { OtpControl } from 'spotii-ui/OtpForm';
import { verifyPasswordRecovery } from 'reducers/recovery';
import { useTranslation, Trans } from 'react-i18next';
import authApi from 'api/auth';

const Verify = () => {
  const dispatch = useDispatch();
  const { username, isVerified, isPopulated } = useSelector(state => state.recovery);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    let tm;
    if (error) {
      tm = setTimeout(() => setError(false), 820);
    }
    return () => clearTimeout(tm);
  }, [error]);

  const onSubmit = value => {
    setLoading(true);
    authApi.recoveryConfirm(value).then(
      () => {
        setLoading(false);
        dispatch(verifyPasswordRecovery());
      },
      err => {
        console.warn(err);
        setLoading(false);
        setError(true);
      },
    );
  };

  if (isVerified) {
    return <Redirect to="/password-recovery/new-password" />;
  }

  if (!isPopulated) {
    return <Redirect to="/signin" />;
  }

  return (
    <div className="page-transition">
      <Paper size="tiny" className={error ? 'shake' : null}>
        <BlockHead>
          <Heading h1>{t('weJustTextedYou')}</Heading>
          <p>
            <Trans i18nKey="otpSentSubTitle">
              {t('pleaseEnterVerificationCodeSentTo')} <span className="nobr">{username}</span>
            </Trans>
          </p>
        </BlockHead>
        <OtpForm onSubmit={onSubmit} loading={loading} error={error} />
      </Paper>
      <OtpControl toChangePhone="/password-recovery" />
    </div>
  );
};

export default Verify;
