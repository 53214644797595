import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Badge from 'spotii-ui/Badge';
import {
  ORDER_STATUS_OPENED,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_PARTIALLY_REFUNDED,
  ORDER_STATUS_REFUNDED,
  ORDER_STATUS_VOIDED,
} from 'utils/constants';

const statusToText = {
  [ORDER_STATUS_OPENED]: {
    text: 'opened',
    type: 'default',
  },
  [ORDER_STATUS_COMPLETED]: {
    text: 'complete',
    type: 'success',
  },
  [ORDER_STATUS_PARTIALLY_REFUNDED]: {
    text: 'complete',
    type: 'success',
  },
  [ORDER_STATUS_REFUNDED]: {
    text: 'refunded',
    type: 'fail',
  },
  [ORDER_STATUS_VOIDED]: {
    text: 'voided',
    type: 'default',
  },
};

const OrderStatus = ({ status, badge }) => {
  const { t } = useTranslation();
  if (status in statusToText) {
    const { type, text } = statusToText[status];

    if (badge) {
      return <Badge type={type}>{t(text)}</Badge>;
    }
    return <span>{t(text)}</span>;
  }
  return null;
};

OrderStatus.propTypes = {
  status: PropTypes.oneOf([
    ORDER_STATUS_OPENED,
    ORDER_STATUS_COMPLETED,
    ORDER_STATUS_PARTIALLY_REFUNDED,
    ORDER_STATUS_REFUNDED,
    ORDER_STATUS_VOIDED,
  ]).isRequired,
  badge: PropTypes.bool,
};

OrderStatus.defaultProps = {
  badge: false,
};

export default OrderStatus;
