import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import b from 'b_';
import Paper from 'components/Paper';
import './HeroStat.scss';

const HeroStat = ({ title, data, description, className }) => {
  const currLang = useSelector(state => state.language.language);
  const cls = b.with('hero-stat');
  const classes = className ? `${cls()} ${className}` : cls();

  return (
    <Paper className={classes}>
      <div className={cls(`pic-${currLang}`)} />
      <div className={cls('content')}>
        <p className={cls('title')}>{title}</p>
        <p className={cls('data')}>{data}</p>
        <p className={cls('description')}>{description}</p>
      </div>
    </Paper>
  );
};

HeroStat.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.node.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
};
HeroStat.defaultProps = {
  className: null,
};

export default HeroStat;
