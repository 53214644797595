import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from 'auth/Layout';
import TopBar from 'auth/TopBar';
import LoginForm from 'auth/Login';
import Paper from 'auth/Paper';
import BlockHead from 'auth/BlockHead';
import Heading from 'spotii-ui/Heading';
import { useTranslation } from 'react-i18next';

import './Login.scss';

export default function Login() {
  const { t } = useTranslation();

  return (
    <Layout>
      <TopBar authOption="register" />
      <Layout.Container>
        <Paper size="medium" picture>
          <Row>
            <Col>
              <BlockHead>
                <Heading h1>{t('merchantSignInHeading')}</Heading>
                <p>{t('merchantSignInSubHeading')}</p>
              </BlockHead>
              <LoginForm />
            </Col>
            <Col className="login__picture d-none d-sm-block" />
          </Row>
        </Paper>
      </Layout.Container>
    </Layout>
  );
}
