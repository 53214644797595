import React, { useState, useEffect } from 'react';
import Button from 'spotii-ui/Button';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ResendButtonWithTimer = ({ resendOTP, isLoading, secs }) => {
    const { t } = useTranslation();
    const [timer, setTimer] = useState(secs);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        let interval;
        if (isButtonDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer > 1) {
                        return prevTimer - 1;
                    } else {
                        clearInterval(interval);
                        setIsButtonDisabled(false);
                        return 1;
                    }
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isButtonDisabled]);

    const handleResend = () => {
        setTimer(secs);
        setIsButtonDisabled(true);
        resendOTP()
    };

    return (
        <>
            <Button style={{ width: '45%' }} type="default" ghost={true} onClick={handleResend} disabled={isLoading || isButtonDisabled}>
                {isButtonDisabled ? t('resendIn', { timer }) : t('resend')}
            </Button>
        </>
    );
};

ResendButtonWithTimer.propTypes = {
    resendOTP: PropTypes.func,
    isLoading: PropTypes.bool,
    secs: PropTypes.number,
};

ResendButtonWithTimer.defaultProps = {
    isLoading: false,
    secs: 20,
}

export default ResendButtonWithTimer;
