import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Switch, Redirect, Route, useRouteMatch } from 'react-router-dom';
import RouteBinded from 'components/RouteBinded';
import DashboardHeader from 'components/DashboardHeader';
import { AppStateType } from 'reducers/application';

// Sub-routes
import Orders from './Orders';
import Refunds from './Refunds';
import Settlements from './Settlements';

// Common Styles
import './Reports.scss';

const Reports = ({ appState }) => {
  const { path } = useRouteMatch();
  const { t } = useTranslation();

  return (
    <div className="reports__container">
      <DashboardHeader
        title={t('reports')}
        navItems={[
          { pathname: '/reports/orders', title: t('orders') },
          { pathname: '/reports/refunds', title: t('refunds') },
          { pathname: '/reports/settlements', title: t('settlements') },
        ]}
        appState={appState}
      />
      <Switch>
        <RouteBinded path={`${path}/orders/:page?`} component={Orders} appState={appState} />
        <RouteBinded path={`${path}/refunds/:page?`} component={Refunds} appState={appState} />
        <RouteBinded
          path={`${path}/settlements/:page?`}
          component={Settlements}
          appState={appState}
        />
        <Route path={`${path}`} exact render={() => <Redirect to={`${path}/orders`} />} />
      </Switch>
    </div>
  );
};

Reports.propTypes = {
  appState: PropTypes.shape(AppStateType).isRequired,
};

export default Reports;
