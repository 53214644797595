import React from 'react';
import PropTypes from 'prop-types';
import BSDropdown from 'react-bootstrap/Dropdown';
import Icon from '../Icon';

const Dropdown = BSDropdown;

// eslint-disable-next-line react/display-name
Dropdown.CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  <div
    className="dropdown__toggle"
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

// eslint-disable-next-line react/display-name
Dropdown.RegularToggle = React.forwardRef(({ children, onClick, isLoading }, ref) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  <div
    className="dropdown__regular-toggle"
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="dropdown__regular-toggle-arrow">
      <Icon type={isLoading ? 'loading' : 'chevron-down'} />
    </span>
  </div>
));

// eslint-disable-next-line react/display-name
// Dropdown.CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
//   return React.cloneElement(children, {
//     ref,
//     onClick: e => {
//       e.preventDefault();
//       onClick(e);
//     },
//     children,
//   });
// });

Dropdown.CustomToggle.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Dropdown;
