/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import b from 'b_';
import moment from 'moment';
import Button from 'spotii-ui/Button';
import Currency from 'spotii-ui/Currency';
import message from 'spotii-ui/Message';
import Paper from 'components/Paper';
import { DateRangePicker } from 'components/DatePicker';
import TableView from 'components/TableView/TableView';
import Pagination from 'components/Pagination';
import { fetchReport, changeDates, changePage, reset } from 'reducers/reportSettlements';
import { downloadSettlementsReport } from 'api';

const LINES_PER_PAGE = 10;

//
// Components
//
const Settlements = () => {
  const cls = b.with('reports');

  const dispatch = useDispatch();
  const {
    isLoading,
    isResolved,
    list,
    fromDate: stateFromDate,
    toDate: stateToDate,
    pageCount,
    currentPage,
  } = useSelector(state => state.reportSettlements);
  const currLang = useSelector(state => state.language.language);
  const { t } = useTranslation();

  const columns = [
    {
      title: <div className={`column-title-${currLang}`}>{t('date')}</div>,
      dataIndex: 'settlementDate',
      key: 'settlementDate',
      render: value => (
        <div className={`line-height column-render-${currLang}`}>
          {moment.parseZone(value).format('YYYY-MM-DD')}
        </div>
      ),
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('settlementId')}</div>,
      dataIndex: 'settlementId',
      key: 'settlementId',
      render: value => <div className={`line-height column-render-${currLang}`}>{value}</div>,
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('noOfOrd')}</div>,
      dataIndex: 'countOfOrders',
      key: 'countOfOrders',
      render: value => <div className={`line-height column-render-${currLang}`}>{value}</div>,
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('totalAmount')}</div>,
      dataIndex: 'totalPurchasePrice',
      key: 'totalPurchasePrice',
      render: (value, row) => (
        <div className={`line-height column-render-${currLang}`}>
          <Currency currency={row.currency} amount={value} />
        </div>
      ),
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('totalRefunds')}</div>,
      dataIndex: 'totalAmountRefunded',
      key: 'totalAmountRefunded',
      render: (value, row) => (
        <div className={`line-height column-render-${currLang}`}>
          <Currency currency={row.currency} amount={value} />
        </div>
      ),
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('netSettlement')}</div>,
      dataIndex: 'netSettlementAmount',
      key: 'netSettlementAmount',
      render: (value, row) => (
        <div className={`line-height column-render-${currLang}`}>
          <Currency currency={row.currency} amount={value} />
        </div>
      ),
    },
  ];

  // dates should be a MomentJS instance
  const initialStartDate = stateFromDate ? moment(stateFromDate) : null;
  const initialEndDate = stateToDate ? moment(stateToDate) : null;

  const [dateFocus, setDateFocus] = useState(null);
  const [fromDate, setFromDate] = useState(initialStartDate);
  const [toDate, setToDate] = useState(initialEndDate);

  const page = Math.max(currentPage, 1) - 1;
  const fetchParams = {
    fromDate: stateFromDate,
    toDate: stateToDate,
    limit: LINES_PER_PAGE,
    page: currentPage,
  };

  const onPageChange = p => dispatch(changePage(p.selected + 1));
  const handleDownload = async event => {
    const { target } = event;
    event.preventDefault();
    target.disabled = true;

    try {
      const fromReportDate = fromDate && fromDate.format('YYYY-MM-DD');
      const toReportDate = toDate && toDate.format('YYYY-MM-DD');
      await downloadSettlementsReport(fromReportDate, toReportDate);
      target.disabled = false;
    } catch (e) {
      console.warn(e);
      message.error('Oops! Something went wrong.');
      target.disabled = false;
    }
  };

  // Reset reducer on page view
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(reset()), []);

  // Fetch orders on first render and if one of the listed parameters changed.
  useEffect(() => {
    dispatch(fetchReport(fetchParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateFromDate, stateToDate, currentPage]);

  return (
    <Paper mobileFull className={cls({ type: 'settlements' })}>
      <div className={cls('filters')}>
        <div className={cls('filters-datepicker')}>
          <DateRangePicker
            // Allow to select only one date
            t={t}
            currLang={currLang}
            minimumNights={0}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel
            startDateId="startDate"
            endDateId="endDate"
            startDate={fromDate}
            endDate={toDate}
            onDatesChange={({ startDate, endDate }) => {
              setFromDate(startDate);
              setToDate(endDate);
            }}
            focusedInput={dateFocus}
            onFocusChange={focusedInput => {
              setDateFocus(focusedInput);
            }}
            customArrowIcon="–"
            // Allow to select any date
            isOutsideRange={day => day.isAfter(Date.now())}
            onClose={({ startDate, endDate }) => {
              dispatch(
                changeDates({
                  fromDate: startDate ? startDate.format('YYYY-MM-DD') : null,
                  toDate: endDate ? endDate.format('YYYY-MM-DD') : null,
                }),
              );
            }}
          />
        </div>
        <div className={cls('filters-download')}>
          <Button type="link" icon="share" onClick={handleDownload}>
            {t('downloadAsXlsx')}
          </Button>
        </div>
      </div>
      <TableView columns={columns} data={list} loading={isLoading} rowKey="settlementId" />
      {isResolved && list.length ? (
        <div className={cls('pagination')}>
          <Pagination current={page} count={pageCount} onChange={onPageChange} />
        </div>
      ) : null}
    </Paper>
  );
};

export default Settlements;
