import React from 'react';
import PropTypes from 'prop-types';

const formatters = {
  comas: x =>
    parseFloat(x)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  plain: x => parseFloat(x).toFixed(2),
  short: x => {
    const amount = Number(formatters.plain(x));
    let result = amount;
    let sufix = '';

    if (amount > 1e4) {
      result = Math.floor(amount / 1e3);
      sufix = 'k';
    }
    if (amount > 1e7) {
      result = Math.floor(amount / 1e6);
      sufix = 'M';
    }
    return `${formatters.comas(result)}${sufix}`;
  },
};

const Currency = ({ clsName, currency, amount, format }) => (
  <span className={`nobr currency ${clsName}`}>
    <span className="currency__sign">{(currency || '').toUpperCase()}</span>
    {'\u00A0'}
    <span className="currency__amount">{formatters[format](amount || 0)}</span>
  </span>
);

Currency.propTypes = {
  clsName: PropTypes.string,
  currency: PropTypes.string.isRequired,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  format: PropTypes.oneOf(['comas', 'plain', 'short']),
};

Currency.defaultProps = {
  clsName: '',
  format: 'comas',
};

export default Currency;
