import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Badge from 'spotii-ui/Badge';
import {
  DRAFT_STATUS_REJECTED,
  DRAFT_STATUS_COMPLETED,
  DRAFT_STATUS_PENDING_MERCHANT,
  DRAFT_STATUS_CANCELLED,
  DRAFT_STATUS_PENDING_PAYMENT,
} from 'utils/constants';

const DraftStatus = ({ status, badge }) => {
  const { t } = useTranslation();

  const statusToText = {
    [DRAFT_STATUS_CANCELLED]: {
      text: t('cancelled'),
      type: 'fail',
    },
    [DRAFT_STATUS_COMPLETED]: {
      text: t('complete'),
      type: 'success',
    },
    [DRAFT_STATUS_PENDING_MERCHANT]: {
      text: t('pending'),
      type: 'default',
    },
    [DRAFT_STATUS_REJECTED]: {
      text: t('rejected'),
      type: 'fail',
    },
    [DRAFT_STATUS_PENDING_PAYMENT]: {
      text: t('pendingPayment'),
      type: 'default',
    },
  };

  if (status in statusToText) {
    const { type, text } = statusToText[status];

    if (badge) {
      // return <Badge type={type}>{t(text)}</Badge>;
      return <Badge type={type}>{text}</Badge>;
    }
    // return <span>{t(text)}</span>;
    return <span>{text}</span>;
  }
  return null;
};

DraftStatus.propTypes = {
  status: PropTypes.oneOf([
    DRAFT_STATUS_REJECTED,
    DRAFT_STATUS_COMPLETED,
    DRAFT_STATUS_PENDING_MERCHANT,
    DRAFT_STATUS_CANCELLED,
  ]).isRequired,
  badge: PropTypes.bool,
};

DraftStatus.defaultProps = {
  badge: false,
};

export default DraftStatus;
